const messages = {
  en: {
    hello: 'See how your website looks on different devices',
    typeUrl: 'Type URL',
    devices: 'Devices'
  },
  ru: {
    hello: 'Посмотрите как выглядит сайт на разных устройствах',
    typeUrl: 'Введите URL',
    devices: 'Устройства'
  }
}

export { messages }
