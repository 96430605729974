<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.NAV.navigation
    +e.logo(v-if="logo")
      site-logo(:isLink="false")

    +e.UL.ul
      //-+e.LI.li._updates(v-if="$route.name.startsWith('home')")
        +e.A.a(:href="$store.hrefPrefix + '/updates/'")
          +e.text {{ $t('updates') }}
          +e.label {{ $store.date }}

      +e.LI.li._home(v-if="!$route.name.startsWith('home')", :class="$route.name.startsWith('frames') ? 'current' : ''")
        +e.A.a(@click="setUrlHref", :href="$store.hrefPrefix + '/url/'")
          //-home-icon
          +e.text {{ $t('home') }}

      +e.LI.li._stats(:class="$route.name.startsWith('stats-browsers') ? 'current-dropdown' : ''")
        transition(name="fade")
          el-dropdown(placement="bottom")
            +e.SPAN.a
              +e.text {{ $t('statsBrowsers') }}
              i.el-icon-caret-bottom.el-icon--right
            el-dropdown-menu(slot="dropdown", class="navigation-dropdown")
              transition-group(name="list", tag="div")
                el-dropdown-item(
                v-for="item, index in statsRegions",
                :key="`navigation-stats${index}`",
                :class="(item.path && $route.name.startsWith('stats-browsers_') && $route.name.endsWith(item.path)) || (!item.path && $route.name.startsWith('stats-browsers') && $route.path.endsWith('/browser-rating/')) ? 'current' : ''")
                  a(:href="$store.hrefPrefix + '/browser-rating/' + (item.path ? `${item.path}/` : '')") {{ item[$route.meta.lang || 'en'] }}
                  //-{{ $t('stats' + item.substring(0,1).toUpperCase() + item.substring(1)) }}
      //-+e.LI.li._stats(:class="$route.name.startsWith('stats-browsers') ? 'current' : ''")
        +e.A.a(:href="$store.hrefPrefix + '/browser-rating/'")
          +e.text {{ $t('statsBrowsers') }}
            //-+e.label new

      +e.LI.li._devices(:class="$route.name.startsWith('devices') ? 'current' : ''")
        +e.A.a(:href="$store.hrefPrefix + '/iphone-displays/'")
          //-iphones-icon
          +e.text {{ $t('devices') }}

      +e.LI.li._compare(:class="$route.name.startsWith('compare') ? 'current' : ''")
        +e.A.a(@click="setCompareHref", :href="$store.hrefPrefix + '/compare/'")
          //-compare-icon
          +e.text {{ $t('compare') }}

      +e.LI.li._compare-urls(:class="$route.name.startsWith('urls-compare') ? 'current' : ''")
        +e.A.a(:href="$store.hrefPrefix + '/compare-urls/'")
          //-compare-icon
          +e.text {{ $t('compareUrls') }}

    +e.UL.ul._lang(v-if="lang")
      +e.LI.li(:class="{ current: currentLang === 'ru' }")
        +e.BUTTON.a(type="button", @click="changeLang('ru')") RU
      +e.LI.li._delimiter |
      +e.LI.li(:class="{ current: currentLang === 'en' }")
        +e.BUTTON.a(type="button", @click="changeLang('en')") EN
</template>

<script>
  import SiteLogo from '@/components/Vlogo'
  import homeIcon from './assets/home.svg'
  import compareIcon from './assets/compare.svg'
  import iphonesIcon from './assets/iphones.svg'

  export default {
    props: {
      logo: {
        type: Boolean,
        default: false
      },
      lang: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        statsRegions: [
          {
            path: '',
            en: 'Worldwide',
            ru: 'Весь мир'
          },
          {
            path: 'russian-federation',
            en: 'Russia',
            ru: 'Россия'
          },
          {
            path: 'ukraine',
            en: 'Ukraine',
            ru: 'Украина'
          },
          {
            path: 'belarus',
            en: 'Belarus',
            ru: 'Беларусь'
          },
          {
            path: 'united-states-of-america',
            en: 'United States of America',
            ru: 'США'
          },
          {
            path: 'india',
            en: 'India',
            ru: 'Индия'
          },
          {
            path: 'kazakhstan',
            en: 'Kazakhstan',
            ru: 'Казахстан'
          },
          {
            path: 'germany',
            en: 'Germany',
            ru: 'Германия'
          },
          {
            path: 'china',
            en: 'China',
            ru: 'Китай'
          },
          {
            path: 'africa',
            en: 'Africa',
            ru: 'Африка'
          },
          {
            path: 'asia',
            en: 'Asia',
            ru: 'Азия'
          },
          {
            path: 'europe',
            en: 'Europe',
            ru: 'Европа'
          },
          {
            path: 'north-america',
            en: 'North America',
            ru: 'Северная Америка'
          },
          {
            path: 'oceania',
            en: 'Oceania',
            ru: 'Океания'
          },
          {
            path: 'south-america',
            en: 'South America',
            ru: 'Южная Америка'
          }
        ],
        statsPages: [
          'browsers',
          'resolutions'
        ],
        currentLang: this.$store.currentLang
      }
    },

    components: {
      SiteLogo,
      homeIcon,
      compareIcon,
      iphonesIcon
    },

    methods: {
      changeLang(lang) {
        this.$store.currentLang = lang
        document.cookie = `lang=${lang};path=/`
        window.location.reload()
      },

      setUrlHref(event) {
        event.preventDefault();
        this.$store.dispatch('setTestHref', this.$store.state.testHref[0]).catch(e => console.log(e))
      },

      setCompareHref(event) {
        event.preventDefault();
        this.$store.dispatch('setTestHref', {
          value: this.$store.state.testHref[0],
          page: 'compare'
        }).catch(e => console.log(e))
      }
    }
  }
</script>

<style lang="scss">
  .navigation {
    display: flex; //inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    &__logo{
      display: flex;
      justify-content: center;
      svg{
        width: 86px !important;
        height: 67px !important;
      }
      @media (max-width: 768px - 1) {
        min-width: 100%;
        margin-bottom: 1.5rem;
      }
    }

    &__ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -7px;
      @include list-unstyled();
      //white-space: nowrap;
      @media (max-width: 768px - 1) {
        min-width: 100%;
        justify-content: center;
        &_lang{
          position: absolute;
          top: 35px;
          right: 20px;
        }
      }

      @media (max-width: 576px - 1) {
        margin-left: 0;
      }
      //@media (min-width: 576px) {
      //  &_lang {
      //    margin-left: rem-calc(30);
      //  }
      //}
      &_lang {
        padding-right: 3px;
        margin-top: -1px;
        li{
          margin-left: 4px !important;
          margin-right: 4px !important;
        }
      }
    }

    &__li {
      display: inline-block;
      margin: 0 7px 10px;
      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      &_lang {
        margin-right: rem-calc(35);
      }

      &.current{
        .navigation__a {
          color: $color-primary;
          pointer-events: none;
        }
      }
      &.current-dropdown{
        .navigation__a {
          color: $color-primary;
        }
        .el-icon-caret-bottom {
          &::before {
            background-image: url(./assets/arr-current.png);
          }
        }
      }

      &_updates{
        .navigation__label {
          color: $color-primary;
          margin-left: rem-calc(3);
        }
      }

      /*.el-dropdown{
        top: -1px;
      }*/
    }

    &__a {
      display: flex;
      align-items: center;
      position: relative;
      color: #3f4444;
      font-size: rem-calc(11);
      font-weight: 400;
      text-transform: uppercase;
      outline: none !important;
      cursor: pointer;
      white-space: nowrap;
      &:focus,
      &:hover{
        .navigation__text {
          text-decoration: underline;
        }
      }
      svg{
        margin-top: -1px;
        fill: currentColor;
        + .navigation__text{
          margin-left: 6px;
        }
      }
      .el-icon-caret-bottom{
        &::before{
          content: '';
          display: block;
          width: 7px;
          height: 5px;
          background-image: url(./assets/arr.png);
        }
      }
    }

    &__label {
      display: inline-block;
      padding: rem-calc(0 5);
      border-radius: 5px;
      font-size: rem-calc(10);
      transform: translateY(-50%);
      color: $alert-color;
    }
  }

  .navigation-dropdown{
    .el-dropdown-menu__item{
      padding: 0;
      &.current{
        pointer-events: none;
        a {
          color: $color-primary;
        }
      }
      a{
        color: #3f4444;
        display: block;
        padding: 0 2.5rem 0 10px;
        &:hover,
        &:focus{
          color: $color-primary;
        }
      }
    }
  }
</style>
