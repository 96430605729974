export const COUNT_INCREMENT = 'COUNT_INCREMENT';
export const COUNT_DECREMENT = 'COUNT_DECREMENT';
export const SET_HAS_EXTENSION = 'SET_HAS_EXTENSION';
export const SET_TEST_HREF = 'SET_TEST_HREF';
export const SET_TEST_HREF_X_FRAME_OPTIONS = 'SET_TEST_HREF_X_FRAME_OPTIONS';
export const SET_TEST_CONTENT_SECURITY_POLICY = 'SET_TEST_CONTENT_SECURITY_POLICY';
export const SET_TEST_HREF_FETCHING = 'SET_TEST_HREF_FETCHING';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_ZOOM = 'SET_ZOOM';
export const CHANGE_ORIENTATION = 'CHANGE_ORIENTATION';
export const TOGGLE_SCROLLING = 'TOGGLE_SCROLLING';
export const SET_ORIENTATION = 'SET_ORIENTATION';
export const SET_CURRENT_OS = 'SET_CURRENT_OS';
export const DISABLE_CURRENT_OS = 'DISABLE_CURRENT_OS';
export const SET_CURRENT_DEVICE_TYPE = 'SET_CURRENT_DEVICE_TYPE';
export const SET_PANEL_MODE = 'SET_PANEL_MODE';
export const SET_URLS_HISTORY = 'SET_URLS_HISTORY';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const CLEAR_URLS_HISTORY = 'CLEAR_URLS_HISTORY';
