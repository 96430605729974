const getters = {
  //getCount        : state => state.count,
  getCurrentFrames: (state) => {
    const os = state.currentOS
    const deviceType = state.currentDeviceType

    let result = os === 'all' ? state.myframes : state.myframes.filter(frame => {
      return os.includes(frame.os) || frame.os === 'all'
    })

    if (deviceType !== 'all') {
      result = result.filter(frame => {
        return frame.deviceType === deviceType || frame.deviceType === 'all'
      })
    }

    if (deviceType === 'all') {
      result = result.filter(frame => (frame.deviceType !== 'desktop' && frame.deviceType !== 'laptop'))
    }

    return result
  },
  getGlobalOrientation: state => state.orientation
}

export default getters
