<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  .my-frames
    .my-frames__container
      .my-frames__frames-wrap
        +b.stats
          +e.header
            +e.top {{ $t('updatedTitle') }} {{ ((updatedDate.getDate()) < 10 ? '0' : '') + updatedDate.getDate() }}.{{ ((updatedDate.getMonth() + 1) < 10 ? '0' : '') + (updatedDate.getMonth() + 1) }}.{{ updatedDate.getFullYear() }}

            +e.title
              h1(v-if="$route.name.startsWith('stats-browsers')") {{ $t('statsBrowsersTitle') }}
              h1(v-else-if="$route.name.startsWith('stats-resolutions')") {{ $t('statsResolutionsTitle') }}

            +e.location(v-if="region")
              //-+e.location-title {{ $t('locationTitle') }}
              +e.location-items
                transition(name="fade")
                  el-dropdown(placement="bottom-end")
                    +e.SPAN.location-a
                      +e.location-text {{ $t('regions.' + region) }}
                      i.el-icon-caret-bottom.el-icon--right
                    el-dropdown-menu(slot="dropdown", class="region-dropdown")
                      transition-group(name="list", tag="div")
                        el-dropdown-item(v-for="item, index in Object.keys(regions).filter(function(regionItem) { return regions[regionItem] })", :key="`region${index}`", :class="region === item ? 'current' : ''")
                          a(:href="$store.hrefPrefix + '/browser-rating/' + (item === 'worldwide' ? '' : `${item}/`)", rel="nofollow") {{ $t('regions.' + item) }}

          +e.content
            template(v-if="regionNotFound")
              +e.not-found {{ $t('regionNotFound') }}

            template(v-else-if="region")
              +e.table
                +e.table-inner
                  +e.table-title {{ $t('regions.' + region) }}, {{ $t('tableTitle') }} {{ ((updatedDate.getMonth() + 1) < 10 ? '0' : '') + (updatedDate.getMonth() + 1) }}.{{ updatedDate.getFullYear() }}

                  +e.table-items(v-if="datasetsPopulated")
                    +e.table-item(v-for="item, index in popular", :key="index", @click="toggleLine")
                      +e.table-item-left
                        +e.table-item-color(:style="'border-color: ' + colors[currentStats][item[0]]", :class="index === popular.length - 1 ? 'other' : ''")
                        +e.table-item-name {{ item[0].indexOf(' (') !== -1 ? item[0].substring(0, item[0].indexOf(' (')) : item[0] }}
                      +e.table-item-value {{ item[1] }}%

                  +e.table-bottom(v-if="datasetToggled")
                    +e.BUTTON.table-toggle-all(type="button", @click="toggleLines") {{ $t('toggleAll') }}

              +e.wrapper
                +e.CANVAS.canvas(width="450", height="450")

              +e.bottom
                +e.provided {{ $t('statsProvided') }} #[a(href='http://gs.statcounter.com/', target='_blank') Statcounter]
</template>

<script>
  import Chart from 'chart.js/dist/Chart.bundle.min.js';

  export default {
    data() {
      return {
        currentStats: 'stats-browsers',
        updatedDate: new Date(),
        region: '',
        regionNotFound: false,
        regions: {
          'worldwide': true,
          'russian-federation': true,
          'ukraine': true,
          'belarus': true,
          'united-states-of-america': true,
          'india': true,
          'kazakhstan': true,
          'germany': true,
          'china': true,
          'africa': true,
          'asia': true,
          'europe': true,
          'north-america': true,
          'oceania': true,
          'south-america': true
        },

        datasetsPopulated: false,
        datasetToggled: false,
        labels: [],
        datasets: [],
        popular: [],
        colors: {
          'stats-browsers': {
            'Android': 'rgb(255, 102, 0)',
            'Chrome': 'rgb(0, 192, 0)',
            'Edge': 'rgb(122, 175, 255)',
            'Firefox': 'rgb(228, 110, 14)',
            'IE': 'rgb(0, 82, 216)',
            'KaiOS': 'rgb(154, 6, 227)',
            'mCent': 'rgb(32, 32, 32)',
            'Opera': 'rgb(255, 0, 0)',
            'QQ Browser': 'rgb(32, 32, 32)',
            'Safari': 'rgb(128, 128, 128)',
            'Samsung Internet': 'rgb(48, 74, 140)',
            'Sogou Explorer': 'rgb(238, 102, 0)',
            'UC Browser': 'rgb(255, 212, 64)',
            'Yandex Browser': 'rgb(121, 97, 216)'
          },
          'stats-resolutions': {
            '1920x1080': 'rgb(0, 192, 0)'
          }
        }
      }
    },

    mounted() {
      const defaultRegion = Object.keys(this.regions)[0];
      this.region = this.$route.meta.region || defaultRegion;
      if (!this.regions[this.region]) {
        this.region = defaultRegion;
        this.regionNotFound = true;
        return;
      }

      const langPage = this.$route.name.indexOf('_');
      this.currentStats = this.$route.name.substring(0, langPage !== -1 ? langPage : this.$route.name.length);
      let currentStatsData;
      try {
        currentStatsData = require(`./data/${this.currentStats}-${this.region}.json`);
      } catch(e) {
        this.region = defaultRegion;
        this.regionNotFound = true;
        throw e;
      }

      const dateObj = require('./data/stats-date.json');
      if (!dateObj || !dateObj.date) {
        throw new Error('Updated date could not be read');
        return;
      }
      this.updatedDate = new Date(dateObj.date);

      //Count popularity
      const lastMonth = currentStatsData[currentStatsData.length - 1];
      let otherPercents = 0;
      Object.keys(lastMonth).slice(1).forEach(item => {
        const percents = parseFloat(lastMonth[item]);
        if (percents >= 0.65) {
          this.popular.push([item.substring(1, item.length - 1), percents]);
        } else {
          otherPercents += percents;
        }
      });

      this.popular.sort((a, b) => {
        return b[1] - a[1];
      });

      //Add datasets objects
      this.popular.forEach(item => {
        this.datasets.push({
          label: item[0],
          data: [],
          borderColor: this.colors[this.currentStats][item[0]],
          backgroundColor: 'rgb(0,0,0,0)'
        });
      });
      this.datasets.push({
        label: this.$t('otherStatsItems'),
        data: [],
        borderColor: 'rgba(32, 32, 32, .65)',
        backgroundColor: 'rgb(0,0,0,0)',
        borderDash: [3, 3]
      });
      this.popular.push([this.$t('otherStatsItems'), otherPercents.toFixed(2)]);

      //Populate datasets data arrays
      currentStatsData.forEach((month, monthIndex) => {
        this.labels.push(month['"Date"']);
        Object.keys(month).slice(1).forEach(item => {
          const itemName = item.substring(1, item.length - 1);
          const index = this.popular.findIndex(popularItem => {
            return itemName === popularItem[0];
          });
          if (index === -1) {
            const prevData = parseFloat(this.datasets[this.datasets.length - 1].data[monthIndex] || 0);
            this.datasets[this.datasets.length - 1].data[monthIndex] = (prevData + parseFloat(month[item])).toFixed(2);
          } else {
            if (!this.colors[this.currentStats][itemName]) {
              console.error(`"${itemName}" color not found`);
            }
            this.datasets[index].data.push(month[item]);
          }
        });
      });
      this.datasetsPopulated = true;

      setTimeout(() => {
        //Chart element init
        const ctx = document.querySelector('.stats__canvas').getContext('2d');
        this.charts = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.labels,
            datasets: this.datasets
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              duration: 0
            },
            legend: {
              labels: {
                boxWidth: 13,
                padding: 20
              }
            },
            scales: {
              yAxes: [{
                ticks: {
                  callback(value) {
                    return `${value}%`;
                  },
                  fontSize: 11
                }
              }],
              xAxes: [{
                ticks: {
                  callback: value => {
                    if (!this.$route.name.startsWith('stats')) return;
                    const year = value.substring(0, value.indexOf('-'));
                    const monthNum = parseInt(value.substring(value.indexOf('-') + 1));
                    const monthName = this.$t && this.$t('months')[monthNum - 1];
                    return `${monthName} ${year}`;
                  }
                }
              }]
            },
            tooltips: {
              callbacks: {
                label(tooltipItem, data) {
                  return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel}%`;
                }
              }
            }
          }
        });
      });
    },

    methods: {
      toggleLine(event) {
        const item = event.target.closest('.stats__table-item');
        const parent = item.parentElement;
        const index = Array.from(parent.children).indexOf(item);
        const isInactive = item.classList.contains('inactive');

        if (isInactive) {
          item.classList.remove('inactive');
          this.charts.data.datasets[index].hidden = false;
          const isToggled = this.charts.data.datasets.some(dataset => {
            if (dataset.hidden) {
              this.datasetToggled = true;
              return true;
            }
          });
          if (!isToggled) {
            this.datasetToggled = false;
          }
        } else {
          this.datasetToggled = true;
          item.classList.add('inactive');
          this.charts.data.datasets[index].hidden = true;
        }

        this.charts.update();
      },

      toggleLines() {
        this.charts.data.datasets.forEach(dataset => {
          dataset.hidden = false;
        });
        Array.from(document.querySelectorAll('.stats__table-item'), item => {
          item.classList.remove('inactive');
        });
        this.datasetToggled = false;
        this.charts.update();
      }
    }
  }
</script>

<style lang="scss">
  .stats{
    min-width: 100%;
    &__top{
      min-width: 100%;
      color: #666;
      font-size: 11px;
    }
    &__header{
      display: flex;
      align-items: center;
      //justify-content: space-between;
      flex-wrap: wrap;
      h1{
        margin-top: 0;
      }
    }
    &__location{
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      &-title{
        font-size: 16px;
      }
      &-items{
        margin-left: 25px;
        .el-dropdown{
          .el-icon-caret-bottom{
            position: relative;
            top: -1px;
            &::before{
              content: '';
              display: block;
              width: 7px;
              height: 5px;
              background-image: url(./assets/arr.png);
            }
          }
        }
      }
      /*&-item{
        margin-left: 10px;
      }
      &-link{
        color: #3f4444;
        border-bottom: 1px dashed currentColor;
        transition: color 70ms ease-in-out, border-color 70ms ease-in-out;
        &.current{
          //color: $color-primary;
          pointer-events: none;
          cursor: default;
          border-bottom-color: transparent;
        }
      }*/
      &-a{
        cursor: pointer;
        color: #3f4444;
        outline: none !important;
      }
      &-text{
        font-size: 16px;
        border-bottom: 1px dashed currentColor;
        transition: border-color .25s ease-in-out;
        &:hover{
          border-bottom-color: transparent;
        }
      }
    }

    &__not-found{
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      font-weight: bold;
      font-size: 16px;
      min-width: 100%;
    }

    &__content{
      border-top: 1px solid #eee;
      min-width: 100%;
      padding: 1rem 0;
      display: flex;
      flex-wrap: wrap;
    }
    &__wrapper{
      max-width: 1603px - 380;
      flex: 1 0 auto;
    }
    &__table{
      width: 380px;
      padding: 42px 30px 0 0;
      &-inner{
        background: #eff5f5;
        padding: 10px;
      }
      &-title{
        padding: 10px 15px 12px;
        font-size: 15px;
        font-weight: bold;
      }
      &-item{
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 7px 15px;
        font-size: 13px;
        cursor: pointer;
        transition: opacity .25s ease-in-out;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(#666, .1);
        }
        &:hover,
        &:focus{
          opacity: .7;
        }
        &.inactive{
          &::after{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 10px;
            width: calc(100% - 20px);
            opacity: .3;
            //margin-top: -1px;
            border-top: 2px solid currentColor;
          }
        }
        &-left{
          display: flex;
          align-items: center;
        }
        &-color{
          height: 15px;
          min-width: 15px;
          max-width: 15px;
          border: 3px solid rgba(32, 32, 32, .65);
          margin-right: 10px;
          &.other{
            border-style: dotted;
          }
        }
        &-value{
          color: rgba(38,38,38,.67);
        }
      }
      &-bottom{
        margin-top: 1rem;
        padding: 0 10px 10px;
      }
      &-toggle-all{
        border-bottom: 1px dashed currentColor;
        transition: border .25s ease-in-out;
        padding: 2px 0;
        &:hover,
        &:focus{
          outline: none !important;
          border-bottom-color: transparent;
        }
      }
    }

    &__bottom{
      min-width: 100%;
      display: flex;
      margin-top: 20px;
    }
    &__provided{
      color: #666;
      font-size: 11px;
      margin-left: auto;
      a{
        color: #3f4444;
        &:hover,
        &:focus{
          color: $color-primary;
        }
      }
    }
  }

  .region-dropdown{
    overflow: auto !important;
    max-height: 90vh;
    //transform: translateX(-50%);
    .el-dropdown-menu__item{
      padding: 0;
      font-size: 15px;
      line-height: normal;
      &.current{
        pointer-events: none;
        a {
          color: $color-primary;
        }
      }
      a{
        display: block;
        padding: 10px;
        white-space: nowrap;
        color: #3f4444;
        &:hover,
        &:focus{
          color: $color-primary;
        }
      }
    }
  }
</style>
