let cookieLang;
document.cookie.split('; ').some(cookie => {
  const cookieArray = cookie.split('=');
  const cookieKey = cookieArray[0];
  if (cookieKey === 'lang' && cookieArray[1] !== '') {
    cookieLang = cookieArray[1];
    return true;
  }
});
const lang = cookieLang || window.navigator.language.substring(0, 2)

const state = {
  //count: 0,
  hasExtension: false,
  testHref: [''],
  testHrefXFrameOptions: [null],
  testContentSecurityPolicy: [null],
  testHrefFetching: [false],
  history: [],
  errors: [],
  zoom: 0.6,
  scrolling: 'yes',
  currentOS: ['iOS'],
  currentDeviceType: 'phone',
  orientation: 'portrait',
  customHeight: 3000,
  customHeightToggled: false,
  compareUploaded: false,
  panelMode: 1,
  panelModes: [
    'float',
    'hidden',
    'fixed'
  ],
  framesGroups: [
    'phone',
    'tablet',
    'desktop'
  ],
  framesMap: [
    //Phone
    {
      id: 'iphone5',
      width: 320,
      deviceType: 'phone'
    },
    {
      id: 'galaxyS20',
      width: 360,
      deviceType: 'phone'
    },
    {
      id: 'iphone6',
      width: 375,
      deviceType: 'phone'
    },
    {
      id: 'galaxyS20+',
      width: 384,
      deviceType: 'phone'
    },
    {
      id: 'iphone12',
      width: 390,
      deviceType: 'phone'
    },
    {
      id: 'galaxyNote20',
      width: 412,
      deviceType: 'phone'
    },
    {
      id: 'iphoneXr',
      width: 414,
      deviceType: 'phone'
    },

    //Tablet
    {
      id: 'ipadMini',
      width: 768,
      deviceType: 'tablet'
    },
    {
      id: 'ipad',
      width: 810,
      deviceType: 'tablet'
    },
    {
      id: 'ipadPro',
      width: 1024,
      deviceType: 'tablet'
    },

    //Notebook
    {
      id: 'macbook12',
      width: 1280,
      deviceType: 'desktop'
    },
    {
      id: 'macbookAir11',
      width: 1366,
      deviceType: 'desktop'
    },
    {
      id: 'macbookAir13',
      width: 1440,
      deviceType: 'desktop'
    },
    {
      id: 'laptop1536',
      width: 1536,
      deviceType: 'desktop'
    },

    //Desktop
    {
      id: 'desktop20',
      width: 1600,
      deviceType: 'desktop'
    },
    {
      id: 'desktop23',
      width: 1920,
      deviceType: 'desktop'
    },
    {
      id: 'desktop27',
      width: 2560,
      deviceType: 'desktop'
    }
  ],
  myframes: [
    {
      id: 'custom',
      title: lang === 'ru' ? 'Свой размер' : 'Custom size',
      active: true,
      os: 'all',
      size: {
        width: 414,
        height: 896
      },
      deviceType: 'all',
      show: true,
      popular: false,
      newIphoneTop: false,
      new: false,
      custom: true
    },

    //Phones
    //iOS
    {
      id: 'iphone4',
      title: 'iPhone 4',
      active: true,
      os: 'iOS',
      year: '2010',
      size: {
        width: 320,
        height: 480
      },
      deviceType: 'phone',
      show: false,
      popular: false
    },
    {
      id: 'iphone5',
      title: 'iPhone SE, 5',
      active: true,
      os: 'iOS',
      year: '2016, 2012',
      size: {
        width: 320,
        height: 568
      },
      deviceType: 'phone',
      show: true,
      popular: true
    },
    {
      id: 'iphone6',
      title: 'iPhone SE 2, 8, 7, 6',
      active: true,
      os: 'iOS',
      year: '2020, 2017, 2016, 2014',
      size: {
        width: 375,
        height: 667
      },
      deviceType: 'phone',
      show: true,
      popular: true,
      iphone6Top: true
    },
    {
      id: 'iphone6+',
      title: 'iPhone 8+, 7+, 6+',
      active: true,
      os: 'iOS',
      year: '2017, 2016, 2014',
      size: {
        width: 414,
        height: 736
      },
      deviceType: 'phone',
      show: true,
      popular: true,
      iphone6Top: true
    },
    {
      id: 'iphoneX',
      title: 'iPhone 11 Pro, Xs, X',
      active: true,
      os: 'iOS',
      year: '2019, 2018 ,2017',
      size: {
        width: 375,
        height: 812
      },
      deviceType: 'phone',
      show: true,
      popular: true,
      newIphoneTop: true
    },
    {
      id: 'iphoneXr',
      title: 'iPhone 11, Xr',
      active: true,
      os: 'iOS',
      year: '2019, 2018',
      size: {
        width: 414,
        height: 896
      },
      deviceType: 'phone',
      show: true,
      popular: false,
      newIphoneTop: true,
      new: false
    },
    {
      id: 'iphone12Mini',
      title: 'iPhone 12 Mini',
      active: true,
      os: 'iOS',
      year: '2020',
      size: {
        width: 360,
        height: 780
      },
      deviceType: 'phone',
      show: true,
      popular: false,
      newIphoneTop: true,
      new: true
    },
    {
      id: 'iphone12',
      title: 'iPhone 12, 12 Pro',
      active: true,
      os: 'iOS',
      year: '2020',
      size: {
        width: 390,
        height: 844
      },
      deviceType: 'phone',
      show: true,
      popular: true,
      newIphoneTop: true,
      new: true
    },
    {
      id: 'iphone12ProMax',
      title: 'iPhone 12 Pro Max',
      active: true,
      os: 'iOS',
      year: '2020',
      size: {
        width: 428,
        height: 926
      },
      deviceType: 'phone',
      show: true,
      popular: false,
      newIphoneTop: true,
      new: true
    },

    //Android
    {
      id: 'galaxyS20',
      title: 'Galaxy S20',
      active: true,
      os: 'Android',
      year: '2020',
      size: {
        width: 360,
        height: 800
      },
      deviceType: 'phone',
      show: true,
      popular: true
    },
    {
      id: 'galaxyS20+',
      title: 'Galaxy S20+',
      active: true,
      os: 'Android',
      year: '2020',
      size: {
        width: 384,
        height: 854
      },
      deviceType: 'phone',
      show: true,
      popular: true
    },
    {
      id: 'galaxyNote20',
      title: 'Galaxy Note20, Galaxy S10 Lite, Galaxy A71',
      active: true,
      os: 'Android',
      year: '2020, 2020, 2020, 2020',
      size: {
        width: 412,
        height: 915
      },
      deviceType: 'phone',
      show: true,
      popular: true
    },

    //Tablets
    //iOS
    {
      id: 'ipadMini',
      title: 'iPad Mini 7.9″',
      active: true,
      os: 'iOS',
      year: '2019',
      size: {
        width: 768,
        height: 1024
      },
      deviceType: 'tablet',
      show: true,
      popular: false
    },
    {
      id: 'ipad',
      title: 'iPad 10.2″',
      active: true,
      os: 'iOS',
      year: '2020',
      size: {
        width: 810,
        height: 1080
      },
      deviceType: 'tablet',
      show: true,
      new: true,
      popular: false
    },
    {
      id: 'ipadAir',
      title: 'iPad Air 10.9″',
      active: true,
      os: 'iOS',
      year: '2020',
      size: {
        width: 820,
        height: 1180
      },
      deviceType: 'tablet',
      show: true,
      new: true,
      popular: false
    },
    {
      id: 'ipadProS',
      title: 'iPad Pro 11″',
      active: true,
      os: 'iOS',
      year: '2020',
      size: {
        width: 834,
        height: 1194
      },
      deviceType: 'tablet',
      show: true,
      new: true,
      popular: false
    },
    {
      id: 'ipadPro',
      title: 'iPad Pro',
      active: true,
      os: 'iOS',
      year: '2020',
      size: {
        width: 1024,
        height: 1366
      },
      deviceType: 'tablet',
      show: true,
      popular: true
    },

    //Android
    {
      id: 'galaxyTabS7',
      title: 'Galaxy Tab S7, Galaxy Tab A 8.0',
      active: true,
      os: 'Android',
      year: '2020, 2019',
      size: {
        width: 800,
        height: 1280
      },
      deviceType: 'tablet',
      show: true,
      popular: true
    },
    {
      id: 'galaxyTabS7Plus',
      title: 'Galaxy Tab S7 Plus',
      active: true,
      os: 'Android',
      year: '2020',
      size: {
        width: 876,
        height: 1400
      },
      deviceType: 'tablet',
      show: true,
      popular: true
    },

    //Desktop
    {
      id: 'macbook12',
      title: 'Laptop, MacBook 12, Pro 13',
      active: true,
      os: 'Windows',
      size: {
        width: 1280,
        height: 800
      },
      deviceType: 'desktop',
      show: true,
      popular: true
    },
    {
      id: 'laptop1024',
      title: 'Laptop',
      active: true,
      os: 'Windows',
      size: {
        width: 1024,
        height: 768
      },
      deviceType: 'desktop',
      show: true,
      popular: false
    },
    {
      id: 'macbookAir11',
      title: 'Laptop, MacBook Air 11',
      active: true,
      os: 'Windows',
      size: {
        width: [1366, 1360],
        height: 768
      },
      deviceType: 'desktop',
      show: true,
      popular: true
    },
    {
      id: 'macbookAir13',
      title: 'Laptop, MacBook Air 13, Pro 15',
      active: true,
      os: 'Windows',
      size: {
        width: 1440,
        height: 900
      },
      deviceType: 'desktop',
      show: true,
      popular: true
    },
    {
      id: 'laptop1536',
      title: 'Laptop 1536',
      active: true,
      os: 'Windows',
      size: {
        width: 1536,
        height: 864
      },
      deviceType: 'desktop',
      show: true,
      popular: true
    },
    {
      id: 'desktop23',
      title: 'Desktop 23", iMac 21.5"',
      active: true,
      os: 'Windows',
      size: {
        width: 1920,
        height: [1080, 1020]
      },
      currentHeight: 0,
      deviceType: 'desktop',
      show: true,
      popular: true
    },
    {
      id: 'desktop20',
      title: 'Desktop 20"',
      active: true,
      os: 'Windows',
      size: {
        width: [1600, 1680],
        height: [900, 1050]
      },
      currentWidth: 0,
      currentHeight: 0,
      deviceType: 'desktop',
      show: true,
      popular: true
    },
    {
      id: 'desktop19',
      title: 'Desktop 19"',
      active: true,
      os: 'Windows',
      size: {
        width: 1280,
        height: [1024, 800, 720]
      },
      deviceType: 'desktop',
      show: true,
      popular: false
    },
    {
      id: 'desktop27',
      title: 'Desktop 27", iMac 27"',
      active: true,
      os: 'Windows',
      size: {
        width: 2560,
        height: [1440, 1600, 1080]
      },
      deviceType: 'desktop',
      show: true,
      popular: false
    }
  ]
}

export default state
