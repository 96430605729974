<template lang="pug">
  frames
</template>
<script>
  import Frames from '../../components/VFrames'

  export default {
    components: {
      Frames
    }
  }
</script>
<style lang="scss" scoped>

</style>
