import config from '../config/project.config'

import Vue from 'vue'

import VueI18n from 'vue-i18n'
import { messages } from './translation'

Vue.use(VueI18n)

let cookieLang;
document.cookie.split('; ').some(cookie => {
  const cookieArray = cookie.split('=');
  const cookieKey = cookieArray[0];
  if (cookieKey === 'lang' && cookieArray[1] !== '') {
    cookieLang = cookieArray[1];
    return true;
  }
});
const lang = cookieLang || window.navigator.language.substring(0, 2)
document.cookie = `lang=${lang};path=/`
const i18n = new VueI18n({
  locale: lang || config.locales[0], // set locale
  messages // set locale messages
})

export default i18n;
