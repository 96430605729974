<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.my-frame(
  v-if="$route.name.startsWith('urls-compare') || $route.name.startsWith('frames') || ($route.name.startsWith('compare') && custom)",
  v-bind:class="'my-frame_' + deviceType + ' my-frame_' + orientation + ' panel-is-' + panelModes[panelMode] + (newIphoneTop ? ' my-frame_iphone-new' : '') + (custom ? ' my-frame_custom' : '')",
  v-bind:style="!onlyHead && ({width: (frameWrapWidth + 2) * zoom + 'px', minWidth: (323 * zoom) + 'px', height: (frameWrapHeight + 2) * zoom + 42 + 'px'})",
  :ref="custom ? 'customFrame' : null")
    +e.BUTTON.remove(v-if="$route.name.startsWith('urls-compare') && !onlyHead && num > 0", @click="removeTestHref", :title="$t('removeTestHref')")
      i.el-icon-close

    +e.head(v-bind:class="{'my-frame__head_sm': (zoom < 0.4)}")
      href-input(v-if="$route.name.startsWith('urls-compare') && !onlyHead", :num="num", :frameInput="true")

      +e.title(v-if="!$route.name.startsWith('urls-compare') || onlyHead")
        +e.rotate(v-if="$route.name.startsWith('frames') && deviceType !== 'desktop' && deviceType !== 'laptop'")
          button(@click.prevent="changeOrientation", :title="$t('rotate')")
            rotate-icon.icon-rotate
            rotate-icon2.icon-rotate2
        +e.name(v-if="$route.name.startsWith('frames')", v-bind:title="title") {{ title }}
        +e.custom-name(v-if="$route.name.startsWith('compare') || $route.name.startsWith('urls-compare')")
          el-select(v-model="selectedCustomDevice", popper-class="custom-device", @change="customDeviceSelectWidth")
            el-option-group(v-for="group, groupIndex in $store.state.framesGroups", :key="group", :label="$t(group)")
              | {{ void (currentFrames = $store.state.framesMap.filter(function(frame) { return group === frame.deviceType })) }}
              el-option(v-for="value, index in currentFrames", :key="index", :label="value.id", :value="value.id", filterable="")
                | {{ void (currentFrame = $store.state.myframes.find(function(frame) { return value.id === frame.id })) }}
                span.name-title {{ currentFrame.title }}
                span.name-size {{ Array.isArray(currentFrame.size.width) ? currentFrame.size.width[0] : currentFrame.size.width }} &times; {{ Array.isArray(currentFrame.size.height) ? currentFrame.size.height[0] : currentFrame.size.height }}

        +e.sizes(v-if="!$route.name.startsWith('urls-compare') || onlyHead")
          template(v-if="Array.isArray(size.width)")
            el-select(v-model="selectedWidth")
              el-option(v-for="value, index in size.width", :key="index", :label="value", :value="index")
          template(v-else-if="!custom")
            span {{ currentWidth }}
          +e.custom-input-wrapper._width(v-else)
            +e.LABEL.label(for="customWidth") {{ $t('customWidthLabel') }}
            +e.INPUT.custom-input(type="text", v-model="currentWidth", id="customWidth", @input="customWidthInput", @blur="customWidthBlur", maxlength="4")
          +e.SPAN.times!= '&nbsp;x&nbsp;'
          template(v-if="Array.isArray(size.height) && !$store.state.customHeightToggled")
            el-select(v-model="selectedHeight")
              el-option(v-for="value, index in size.height", :key="index", :label="value", :value="index")
          template(v-else-if="!custom")
            span(:or="orientation", :class="$store.state.customHeightToggled ? 'custom' : ''", :title="$store.state.customHeightToggled ? $t('customHeight') : null") {{ currentHeight }}
          +e.custom-input-wrapper._height(v-else)
            +e.LABEL.label(for="customHeight") {{ $t('customHeightLabel') }}
            +e.INPUT.custom-input(type="text", v-model="currentHeight", id="customHeight", @input="customHeightInput", @blur="customHeightBlur", maxlength="4")

    +e.frame-overflow(v-if="!onlyHead", v-loading.lock="isLoading", :class="isLoading ? 'loading' : ''")
      +e.frame-wrap(v-bind:style="{transform: 'scale(' + zoom + ')', width: frameWrapWidth + 'px', height: frameWrapHeight + 'px'}")
        +e.frame-panel_top(v-if="(deviceType === 'phone' || deviceType === 'tablet') && os === 'iOS'", :class="'is-' + panelModes[panelMode]")
          //-iphone-nav(v-if="orientation === 'portrait'")
          +e.bar._iphone-new(v-if="newIphoneTop || (iphone6Top && orientation === 'landscape')")
            +e.bar._iphone-new-top-top(v-if="newIphoneTop && orientation === 'portrait'")
              +e.icon._iphone-new-left
                iphone-x-top-left
              +e.icon._iphone-new-right
                iphone-x-top-right
            +e.bar._iphone-new-top-bottom(v-if="orientation === 'landscape'")
              +e.icon._arrow-left
                arrow-icon-x
              +e.icon._arrow-right
                arrow-right-icon-x
              +e.icon._history
                history-icon-x
              address-bar(:href="testHref", :deviceType="deviceType")
              +e.icon._share
                share-icon-x
              +e.icon._add
                add-icon
              +e.icon._tabs
                tabs-icon-x

          +e.bar._top(v-if="!newIphoneTop && orientation === 'portrait'")
            +e.icons._left
              iphone-top-left
            +e.icons._center
              iphone-top-center
            +e.icons._right
              iphone-top-right

          address-bar(v-if="(!newIphoneTop && !iphone6Top) || (iphone6Top && orientation === 'portrait')", :href="testHref", :deviceType="deviceType")
        //-+e.frame-panel_top(v-if="deviceType === 'tablet' && os === 'iOS'", :class="'is-' + panelModes[panelMode]")
          ipad-nav(v-if="orientation === 'portrait'")
          address-bar(:href="testHref", :deviceType="deviceType")

        +e.frame-panel_bottom(v-if="((!newIphoneTop && !iphone6Top) || ((newIphoneTop || iphone6Top) && orientation === 'portrait')) && (deviceType === 'phone' || deviceType === 'tablet') && os === 'iOS'", :class="'is-' + panelModes[panelMode]")
          address-bar(v-if="(newIphoneTop && orientation === 'portrait')", :href="testHref", :deviceType="deviceType")

          +e.bar._iphone-new-bottom-top
            //- iphone-bottom
            +e.icon._arrow-left
              arrow-icon-x
            +e.icon._arrow-right
              arrow-right-icon-x
            +e.icon._share
              share-icon-x
            +e.icon._history
              history-icon-x
            +e.icon._tabs
              tabs-icon-x

          +e.bar._iphone-new-bottom-bottom(v-if="newIphoneTop && orientation === 'portrait'")

        recursive-dummy(v-if="recursiveOpen")
        template(v-else)
          dummy(v-if="sameOriginBlocked")
          template(v-else)
            +e.IFRAME.iframe(
            :src='testHrefTimed + randomGet(testHrefTimed)',
            :width='frameWidth',
            :height='frameHeight',
            :class="deviceType",
            :scrolling="scrolling",
            sandbox="allow-scripts allow-forms allow-same-origin",
            :data-num="num",
            :data-index="index")

            +e.resizer._horizontal(v-if="!$route.name.startsWith('urls-compare') && !$route.name.startsWith('compare') && custom", @mousedown="startResizeFrameWidth")
            +e.resizer._vertical(v-if="!$route.name.startsWith('urls-compare') && custom", @mousedown="startResizeFrameHeight")
            +e.resizer._corner(v-if="!$route.name.startsWith('urls-compare') && !$route.name.startsWith('compare') && custom", @mousedown="startResizeFrameCorner")

    el-dialog(title='Settings', :visible.sync='isSettingsOpened', :before-close='handleClose')
      el-select(v-model="panelMode")
        el-option(v-for="(item, index) in panelModes", value="index", :key="index", :label="item", :value="index")
      span.dialog-footer(slot='footer')
        //- el-button(@click='isSettingsOpened = false') Cancel
        el-button(type='primary', @click='isSettingsOpened = false') {{ $t('confirm') }}
</template>

<script>
  import { mapState } from 'vuex'
  //import axios from 'axios'
  import { getScrollWidth, clickToDownload } from '../../lib/js/helpers'
  // import IphoneNav from './assets/svg/bar.svg'
  // import IpadNav from './assets/svg/bar-ipad.svg'
  import IphoneTopLeft from './assets/svg/iphone-top-left.svg'
  import IphoneTopCenter from './assets/svg/iphone-top-center.svg'
  import IphoneTopRight from './assets/svg/iphone-top-right.svg'
  import IphoneXTopLeft from './assets/svg/iphone-x-top-left.svg'
  import IphoneXTopRight from './assets/svg/iphone-x-top-right.svg'
  // import IphoneBottom from './assets/svg/iphone-bottom.svg'
  // import ArrowIcon from './assets/svg/arrow.svg'
  // import HistoryIcon from './assets/svg/history.svg'
  // import ShareIcon from './assets/svg/share.svg'
  // import TabsIcon from './assets/svg/tabs.svg'
  import ArrowIconX from './assets/svg/arrow-x.svg'
  import ArrowRightIconX from './assets/svg/arrow-right-x.svg'
  import HistoryIconX from './assets/svg/history-x.svg'
  import ShareIconX from './assets/svg/share-x.svg'
  import TabsIconX from './assets/svg/tabs-x.svg'
  import AddIcon from './assets/svg/add.svg'
  import AddressBar from '../device-ui/VAddressBar'
  import RotateIcon from './assets/svg/rotate.svg'
  import RotateIcon2 from './assets/svg/rotate2.svg'
  import RecursiveDummy from './components/RecursiveDummy'
  import Dummy from './components/Dummy'
  import HrefInput from '../VHrefInput'

  const loadTimeout = 2000
  export default {
    data() {
      // const frameWidth  = this.deviceWidth
      // const frameHeight = (!this.$props.custom && this.$store.state.customHeightToggled) ? this.$store.state.customHeight : this.deviceHeight
      // const frameWrapWidth = frameWidth + (this.$props.custom ? 20 : 0)
      // const frameWrapHeight = frameHeight + (this.$props.custom ? 20 : 0)

      return {
        isLoading: true,
        testHrefTimed: '',

        frameHeight: 0,
        frameWidth: 0,
        frameWrapHeight: 0,
        frameWrapWidth: 0,
        orientation: 'portrait',
        screenShotStatus: null,
        isSettingsOpened: false,
        panelMode: 0,
        panelModes: [
          'float',
          'hidden',
          'fixed'
        ],
        currentWidth : 0, //frameWidth,
        currentHeight: 0, //frameHeight,
        selectedHeight: 0,
        selectedWidth: 0,

        resizerWidth: !this.$route.name.startsWith('urls-compare') ? 21 : 0,
        startX: 0,
        startY: 0,
        startWidth: 0, //frameWidth,
        startHeight: 0, //frameHeight,
        resizeWidthDown: false,
        resizeWidthHeight: false,

        minWidth: 300,
        minHeight: 300,

        scrolling: 'yes',
        selectedCustomDeviceTitle: this.$store.state.myframes[6].title,
        // (() => {
        //   return this.$route.name.startsWith('compare') ? 'no' : 'yes'
        // })()
      }
    },
    props: {
      num: {
        type: Number,
        default: 0
      },
      index: {
        type: Number,
        default: 0
      },
      title: {
        type: String
      },
      compareTitle: {
        type: String
      },
      zoom         : {
        type     : Number,
        'default': 1
      },
      testHref     : {
        type: String
      },
      size         : {
        type: Object
      },
      deviceType   : {
        type: String
      },
      iphone6Top: {
        type: Boolean
      },
      newIphoneTop: {
        type: Boolean
      },
      custom: {
        type: Boolean,
        default: false
      },
      os           : {
        type: String
      },
      onlyHead: {
        type: Boolean,
        default: false
      },

      // currentHeight: {
      //   type     : Number,
      //   'default': 0
      // },
      // currentWidth: {
      //   type     : Number,
      //   'default': 0
      // }
    },
    computed: {
      selectedCustomDevice: {
        get() {
          return this.selectedCustomDeviceTitle
        },
        set(value) {
          const currentDevice = this.$store.state.myframes.find(frame => {
            return frame.id === value;
          });
          const width = Array.isArray(currentDevice.size.width) ? currentDevice.size.width[0] : currentDevice.size.width;
          this.$store.commit('customWidth', width);
          if (this.$route.name.startsWith('urls-compare')) {
            const height = Array.isArray(currentDevice.size.height) ? currentDevice.size.height[0] : currentDevice.size.height;
            this.$store.commit('customHeight', height);
          }
          this.selectedCustomDeviceTitle = currentDevice.title;

          if (this.$store.state.compareUploaded) {
            const maxWidth =
              (document.querySelector('.my-frames__header').offsetWidth
                - 52 //left padding
                - (10 * 4) //frames margin
                - (10 * 4)) //compare frame padding
              / 2;
            const zoom = parseFloat(Math.max(Math.min(maxWidth / width, 1), 0.3).toFixed(2));
            this.$store.commit('SET_ZOOM', zoom);
          }
        }
      },
      deviceHeight() {
        if (!this.$props.custom) {
          //const portrait = this.$data.orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop';

          if (/*portrait && */this.$store.state.customHeightToggled) return this.$store.state.customHeight;
        }

        return Array.isArray(this.$props.size.height)
          ? this.$props.size.height[this.selectedHeight]
          : this.$props.size.height
      },
      deviceWidth: {
        get() {
          // if (!this.$props.custom) {
          //   const portrait = this.$data.orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop';
          //
          //   if (!portrait && this.$store.state.customHeightToggled) return this.$store.state.customHeight;
          // }

          return Array.isArray(this.$props.size.width)
            ? this.$props.size.width[this.selectedWidth]
            : this.$props.size.width
        }
      },
      ...mapState({
        globalPanelMode: state => state.panelMode,
        sameOriginBlocked(state) {
          const xFrameOptions = state.testHrefXFrameOptions[this.num] && state.testHrefXFrameOptions[this.num].toLowerCase().includes('sameorigin');
          const contentSecurityPolicy = state.testContentSecurityPolicy[this.num] && state.testContentSecurityPolicy[this.num].indexOf('frame-ancestors') !== -1;
          return (xFrameOptions || contentSecurityPolicy) && !state.hasExtension;
        },
        recursiveOpen(state) {
          if (!state.testHref[this.num]) return false
          return state.testHref[this.num].indexOf(this.$store.siteHref) === 0
        }
      }),
      ...mapState([
        'hasExtension'
      ])
    },
    components: {
      // IphoneNav,
      // IpadNav,
      IphoneTopLeft,
      IphoneTopCenter,
      IphoneTopRight,
      IphoneXTopLeft,
      IphoneXTopRight,
      // IphoneBottom,
      AddIcon,
      // ArrowIcon,
      // HistoryIcon,
      // ShareIcon,
      // TabsIcon,
      AddressBar,
      RotateIcon,
      RotateIcon2,
      RecursiveDummy,
      Dummy,
      HrefInput,

      ArrowIconX,
      ArrowRightIconX,
      HistoryIconX,
      ShareIconX,
      TabsIconX
    },
    mounted() {
      setTimeout(() => {
        this.testHrefTimed = this.testHref
        this.isLoading = false
      }, this.index * loadTimeout);

      const frameWidth  = this.deviceWidth
      const frameHeight = (!this.$props.custom && this.$store.state.customHeightToggled) ? this.$store.state.customHeight : this.deviceHeight
      const frameWrapWidth = frameWidth + (this.$props.custom && !this.$route.name.startsWith('compare') ? this.resizerWidth : 0)
      const frameWrapHeight = frameHeight + (this.$props.custom ? this.resizerWidth : 0)

      this.frameWidth = frameWidth;
      this.frameHeight = frameHeight;
      this.frameWrapWidth = frameWrapWidth;
      this.frameWrapHeight = frameWrapHeight;
      this.currentWidth = frameWidth;
      this.startWidth = frameWidth;
      this.currentHeight = frameHeight;
      this.startHeight = frameHeight;

      this.panelMode = this.globalPanelMode

      this.$store.watch(state => {
        return state.scrolling
      }, () => {
        this.$data.scrolling = this.$store.state.scrolling
      })

      // меняем ориетнацию всех устройств при изменении в левой панели
      this.$store.watch(state => {
        return state.orientation
      }, () => {
        this.$data.orientation = this.$store.state.orientation
      })

      // меняем высоту всех устройств при изменении в левой панели
      this.$store.watch(state => {
        return state.customHeightToggled
      }, () => {
        if (this.$store.state.customHeightToggled) {
          this.currentHeight = this.$store.state.customHeight;
        } else {
          this.currentHeight = this.deviceHeight;
        }
      })

      this.$store.watch(state => {
        return state.compareUploaded
      }, () => {
        this.currentWidth = this.$store.state.myframes[0].size.width;
        this.currentHeight = this.$store.state.myframes[0].size.height;

        const frameId = this.$store.state.framesMap.find(frame => {
          const frameWidth = parseInt(frame.width);
          return frameWidth === this.$store.state.myframes[0].size.width;
        });
        const currentFrame = frameId ? this.$store.state.myframes.find(frame => {
          return frameId.id === frame.id;
        }) : this.$store.state.myframes[0];

        this.selectedCustomDevice = currentFrame.id;
      })

      if (this.$route.name.startsWith('urls-compare')) {
        this.$store.watch(state => {
          return state.myframes[0].size.width
        }, state => {
          this.currentWidth = state.width
          this.frameWrapWidth = state.width
        })

        this.$store.watch(state => {
          return state.myframes[0].size.height
        }, state => {
          this.currentHeight = state.height
          this.frameWrapHeight = state.height
        })
      }

      this.$forceUpdate()

      document.addEventListener('mousemove', event => {
        this.resizeFrameWidth(event);
        this.resizeFrameHeight(event);
      });
      document.addEventListener('mouseup', event => {
        this.stopResizeFrameWidth(event);
        this.stopResizeFrameHeight(event);
      });

      setTimeout(function () {
        this.getFrameSizes(this.$data.orientation)
        this.customDeviceSelectWidth()
      }.bind(this), 10)
    },
    updated() {
      this.getFrameSizes(this.$data.orientation)
    },
    watch: {
      testHref() {
        setTimeout(() => {
          this.testHrefTimed = this.testHref
          this.isLoading = false
        }, this.index * loadTimeout);
      },

      globalPanelMode() {
        this.panelMode = this.globalPanelMode
      },
      orientation(currentOrientation) {
        this.getFrameSizes(currentOrientation)
      },

      deviceWidth(value) {
        this.currentWidth = value;
      },
      deviceHeight(value) {
        this.currentHeight = value;
      },

      $route(to, from) {
        if (to.name.startsWith('compare') || to.name.startsWith('urls-compare')) {
          this.$data.orientation = 'portrait'
          setTimeout(() => {
            this.customDeviceSelectWidth()
          })
        }
      },

      // currentWidth(value) {
      //   if (this.$route.name.startsWith('frames')) return;
      //
      //   if (this.currentWidth !== this.deviceWidth) return;
      //   if (Number.isNaN(value)) value = 0;
      //   value = Math.max(value, this.minWidth);
      //   this.$store.commit('customWidth', value);
      // },
      // currentHeight(value) {
      //   if (this.$route.name.startsWith('frames')) return;
      //
      //   if (this.currentHeight !== this.deviceHeight) return;
      //   if (Number.isNaN(value)) value = 0;
      //   value = Math.max(value, this.minHeight);
      //   this.$store.commit('customHeight', value);
      // }
    },
    methods: {
      removeTestHref() {
        const options = {
          num: this.num
        }
        this.$store.commit('SET_TEST_HREF', options)
      },

      customWidthInput(event) {
        const value = parseInt(event.target.value) || 0;
        this.currentWidth = value;
        this.$store.commit('customWidth', value);
      },
      customWidthBlur(event) {
        const value = parseInt(event.target.value) || 0;
        if (value < this.minWidth) {
          this.currentWidth = this.minWidth;
        }
        if (this.$route.name.startsWith('urls-compare')) {
          this.currentWidth = value;
          this.$store.commit('customWidth', value);
        }
      },
      customHeightInput(event) {
        const value = parseInt(event.target.value) || 0;
        this.currentHeight = value;
        this.$store.commit('customHeight', value);
      },
      customHeightBlur(event) {
        const value = parseInt(event.target.value) || 0;
        if (value < this.minHeight) {
          this.currentHeight = this.minHeight;
        }
        if (this.$route.name.startsWith('urls-compare')) {
          this.currentHeight = value;
          this.$store.commit('customHeight', this.currentHeight);
        }
      },

      startResizeFrameWidth(event) {
        document.body.classList.add('drag');
        const portrait = this.$data.orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop';

        this.resizeWidthDown = true;
        this.startX = event[portrait ? 'clientX' : 'clientY'];
        this.startWidth = parseInt(this.currentWidth || this.frameWidth);
      },
      resizeFrameWidth(event) {
        const portrait = this.$data.orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop';

        if (!this.resizeWidthDown) return;
        const newWidth = Math.max(this.startWidth + event[portrait ? 'clientX' : 'clientY'] - this.startX, this.minWidth);
        this.currentWidth = newWidth;
        this.frameWrapWidth = newWidth + (this.$props.custom && !this.$route.name.startsWith('compare') ? this.resizerWidth : 0);
        //this.$store.commit('customWidth', newWidth);
      },
      stopResizeFrameWidth() {
        this.resizeWidthDown = false;
        document.body.classList.remove('drag');
      },

      startResizeFrameHeight(event) {
        document.body.classList.add('drag');
        const portrait = this.$data.orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop';

        this.resizeHeightDown = true;
        this.startY = event[portrait ? 'clientY' : 'clientX'];
        this.startHeight = parseInt(this.currentHeight || this.frameHeight);
      },
      resizeFrameHeight(event) {
        const portrait = this.$data.orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop';

        if (!this.resizeHeightDown) return;
        const newHeight = Math.max(this.startHeight + event[portrait ? 'clientY' : 'clientX'] - this.startY, this.minHeight);
        //this.$store.commit('customHeight', newHeight);
        this.currentHeight = newHeight;
        this.frameWrapHeight = newHeight + (this.$props.custom ? this.resizerWidth : 0);
      },
      stopResizeFrameHeight() {
        this.resizeHeightDown = false;
        document.body.classList.remove('drag');
      },

      startResizeFrameCorner(event) {
        document.body.classList.add('drag');
        const portrait = this.$data.orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop';

        this.resizeWidthDown = true;
        this.resizeHeightDown = true;

        this.startX = event[portrait ? 'clientX' : 'clientY'];
        this.startWidth = this.currentWidth || this.frameWidth;
        this.startY = event[portrait ? 'clientY' : 'clientX'];
        this.startHeight = parseInt(this.currentHeight || this.frameHeight);
      },

      getFrameSizes(orientation) {
        const currentWidth = parseInt(this.currentWidth || this.deviceWidth);
        const currentHeight = parseInt(this.currentHeight || this.deviceHeight);

        this.frameWidth =
          ((orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop')
            ? currentWidth : currentHeight) //+ getCurrentScrollWidth()

        this.frameHeight =
          ((orientation === 'portrait' || this.$props.deviceType === 'desktop' || this.$props.deviceType === 'laptop')
            ? currentHeight
            : currentWidth)

        this.currentWidth = currentWidth;
        this.currentHeight = currentHeight;

        this.frameWrapWidth = this.frameWidth + (this.$props.custom && !this.$route.name.startsWith('compare') ? this.resizerWidth : 0)
        this.frameWrapHeight = this.frameHeight + (this.$props.custom ? this.resizerWidth : 0)
      },
      changeOrientation() {
        this.$data.orientation = this.$data.orientation === 'portrait' ? 'landscape' : 'portrait'
      },
      // getScreenshot() {
      //   this.startLoading()
      //
      //   const shotId = new Date().getTime() + '_' + new Date().toISOString()
      //     .replace('T', '_') // replace T with a space
      //     .replace(/:/g, '-')
      //     .replace(/\..+/, '')
      //
      //   this.loadScreenshot(shotId)
      // },
      // loadScreenshot(shotId) {
      //   let requestInterval = 500 // время через которое отправим повторный запрос, если скриншот ещё генерируется на сервере
      //
      //   axios.get(
      //     `/api/phantom/shot?url=${this.$props.testHref}&width=${this.frameWidth}&height=${this.frameHeight}&os=${this.$props.os}&title=${this.$props.title}&id=${shotId}&status=${this.$data.screenShotStatus}`)
      //     .then(response => {
      //       this.$data.screenShotStatus = response.data.status // сохраняем статус генерации
      //
      //       if (response.data.status === 'done') {
      //         // если скриншот готов - создаем невидимую ссылку и кликаем для загрузки файла
      //         // сбрасываем статус генерации и вызываем метод обработки конца загрузки
      //         clickToDownload(response.data.name, response.data.url)
      //
      //         this.$data.screenShotStatus = null
      //
      //         this.endLoading()
      //       } else if (response.data.status === 'error') {
      //         // если произошла ошибка на сервере, выводим ошибку в консоль
      //         // сбрасываем статус генерации и вызываем метод обработки конца загрузки
      //
      //         console.warn(response.data.message)
      //
      //         this.$data.screenShotStatus = null
      //
      //         this.endLoading()
      //       } else {
      //         // генерация не закончена и ошибки нет, отправляем повторный запрос
      //         setTimeout(function () {
      //           this.loadScreenshot(shotId)
      //         }.bind(this), requestInterval)
      //       }
      //     })
      //     .catch(e => {
      //       console.warn(e.message)
      //     })
      // },
      // startLoading() {
      //   // показываем индикатор генерации
      //   this.$startLoading('create shot' + this._uid)
      // },
      // endLoading() {
      //   // убираем индикатор генерации
      //   this.$endLoading('create shot' + this._uid)
      // },
      // openSettings(e) {
      //   this.isSettingsOpened = true
      // },
      handleClose(done) {
        done()
      },

      getRandomNumber() {
        return Math.floor(Math.random() * 9);
      },
      getRandomHash() {
        let randomHash = '';
        Array.from({length: 16}).forEach(() => {
          randomHash += this.getRandomNumber();
        })
        return randomHash;
      },

      randomGet(href) {
        return '';
        // if (!href) return '';
        // const siteUrl = new URL(href);
        // let urlAdd = '';
        // if (!siteUrl.search) {
        //   urlAdd += '?';
        // }
        // urlAdd += `&__iloveadaptive-hash__=${this.getRandomHash()}`;
        // return urlAdd;
      },

      customDeviceSelectWidth() {
        setTimeout(() => {
          const input = document.querySelector('.my-frame__custom-name .el-input__inner');
          if (!input) return;

          const testDiv = document.createElement('div');
          testDiv.style.fontSize = '16px';
          testDiv.style.fontWeight = '700';
          testDiv.style.position = 'absolute';
          testDiv.style.top = '-9999px';
          testDiv.textContent = input.value;
          document.body.appendChild(testDiv);
          const inputWidth = testDiv.offsetWidth;
          testDiv.remove();
          input.style.width = `${inputWidth}px`;
        });
      }
    }
  }
</script>

<style lang="scss">
  .el-select-dropdown{
    &.custom-device{
      left: 230px !important;
      width: 420px;
      .el-select-group__wrap{
        position: relative;
        + .el-select-group__wrap{
          margin-top: 10px;
        }
      }
      .el-select-group__title{
        font-size: 16px;
        display: inline-block;
        background: #fff;
        padding-right: 8px;
        font-weight: 600;
        color: rgba(38,38,38,.67);
        &::before{
          content: '';
          display: block;
          position: absolute;
          top: 16px;
          left: 0;
          width: calc(100% - 10px);
          border-top: 1px solid #999;
          z-index: -1;
        }
      }
      .el-select-dropdown__item{
        display: flex;
        justify-content: space-between;
        white-space: normal;
        min-height: 36px;
        height: auto;
        .name-title{
          max-width: calc(100% - 88px);
        }
        .name-size{
          white-space: nowrap;
        }
      }
    }
  }

  .my-frame {
    position: relative;
    display: flex;
    flex-direction: column;
    vertical-align: top;
    min-height: 300px;
    &_landscape{
      min-height: 0;
    }

    &_custom{
      .my-frame__frame-wrap{
        min-width: 321px;
        min-height: 321px;
      }
    }

    &_iphone-new{
      &.panel-is-float {
        .my-frame__frame-wrap {
          &:hover,
          &:focus {
            &::before {
              transform: translateY(-100%);
            }
          }
        }
      }

      &.my-frame_portrait {
        &:not(.panel-is-hidden) {
          .my-frame__frame-wrap {
            position: relative;

            &::before {
              //&::after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 90px;
              background-image: url('./assets/iphonex-border-top.png');
              background-position: top center;
              background-repeat: no-repeat;
              background-size: 392px - 2;
              pointer-events: none;
              z-index: 11;
              transition: transform .2s linear;
              transform-origin: 0 0;
            }

            /*&::after {*/
            /*top: auto;*/
            /*bottom: 0;*/
            /*background-image: url('./assets/iphonex-border-bottom.png');*/
            /*background-position: 0 bottom;*/
            /*}*/
          }
        }
      }

      /*&.my-frame_landscape{
        &:not(.panel-is-hidden) {
          .my-frame__frame-wrap {
            &::before {
              transform: rotate(-90deg) translateX(-100%);
            }
          }
        }
      }*/
    }

    .href-input{
      border: none;
      &__input-group{
        position: relative;
      }
      &__input-group-field{
        box-shadow: none;
        border: 1px solid #e7f1f1 !important;
        padding-right: 40px;
        font-size: 14px;
        &:focus{
          border-color: #b7d5d5 !important;
        }
      }
      &__history{
        position: absolute;
        top: 1px;
        right: 40px + 1;
        height: calc(100% - 2px);
        .el-dropdown > button{
          border-left: 1px solid #e7f1f1;
          background: #fff;
          width: 40px;
          padding: 0;
          > span{
            span, i{
              display: none;
            }
            svg{
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
      &__button-group{
        button{
          width: 40px;
          height: 100%;
          margin: 0;
          background: #e7f1f1;
          .svg-icon{
            color: #3f4444;
          }
          &:hover,
          &:focus{
            background: #7c8989;
            .svg-icon{
              color: #fff;
            }
          }
        }
      }
    }

    &__remove{
      position: absolute;
      top: -16px;
      right: 0;
      z-index: 5;
      color: #dd4c39;
      outline: none !important;
    }

    &__head {
      height: 42px;
      padding-bottom: rem-calc(22);
      flex: 0 0 42px;
      overflow: hidden;

      .my-frame_desktop &,
      .my-frame_laptop & {
        //height: 60px;
        //flex: 60px 0 0;
      }
    }

    &__title {
      display: flex;
      align-items: center; //flex-end;

      .my-frame_desktop &,
      .my-frame_laptop & {
        align-items: center;
      }

      .my-frame__custom-name {
        flex: 1 0 auto;
        max-width: calc(100% - 135px); //- 129px
        .el-select{
          width: 100%;
        }
        .el-input__inner {
          font-size: 16px;
          font-weight: 700;
          //min-width: 128px;
          max-width: 100%;
          height: 23px;
          border-bottom-color: currentColor;
          &:hover{
            border-bottom-color: transparent;
          }
          /*display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          border-bottom: 1px dashed currentColor;*/
        }
        .el-input__icon{
          color: #3f4444;
          //margin-right: -6px;
        }
      }
    }

    /*&__times{
      align-self: flex-end;
    }*/

    &__rotate {
      margin-right: rem-calc(7);

      button {
        display: block;
        @include size(rem-calc(18));
        outline: none;
      }

      .icon-rotate {
        @include size(rem-calc(15), rem-calc(18));

        .my-frame_landscape & {
          display: none;
        }
      }
      .icon-rotate2 {
        @include size(rem-calc(18), rem-calc(15));

        .my-frame_portrait & {
          display: none;
        }
      }
    }

    &__name,
    &__custom-name,
    &__sizes {
      display: flex;
      align-items: center;
    }
    &__custom-input-wrapper{
      position: relative;
    }
    &__custom-input{
      margin: 0 2px;
      padding: 0 0 0 9px;
      height: 25px;
      width: 58px;
      text-align: center;
      border: 1px solid #c9d9bf;
      box-shadow: none;
      font-size: 13px;
      &:hover,
      &:focus{
        border-color: #80d823;
      }
      /*+ .my-frame__times{
        align-self: center;
      }*/
    }
    &__label{
      position: absolute;
      top: 0;
      left: 7px;
      color: #c9d9bf;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 26px;
    }

    &__name,
    &__custom-name {
      font-size: 16px;
      white-space: nowrap;
      /*overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;*/
      color: #3f4444;
      font-weight: 700;
      line-height: 1.5;
      padding-right: rem-calc(12);

      .my-frame__head_sm & {
        font-size: 12px;
      }

      .my-frame_desktop &,
      .my-frame_laptop & {
        order: 2;
        font-size: 14px;
        font-weight: 400;
        margin-right: 0;
        margin-left: rem-calc(12);

        &:before {
          content: '(';
        }

        &:after {
          content: ')';
        }
      }
    }
    &__name{
      display: -webkit-box;
      //white-space: normal;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    &__sizes {
      flex: 0 0 auto;
      color: rgba(#262626, .67);
      font-size: 11px;
      font-weight: 300;
      line-height: 12px;

      .my-frame__head_sm & {
        font-size: 11px;
      }

      .my-frame_desktop &,
      .my-frame_laptop & {
        order: 1;
        margin-left: 0;
        font-weight: 700;
        font-size: 16px;
      }

      span.custom{
        color: #80d823;
      }
    }

    &__options {
      margin-bottom: 15px;
    }

    &__option {
      &-btn {
        margin-bottom: 0;
      }
    }

    &__frame-overflow {
      position: relative;
      overflow: hidden;
      &.loading{
        pointer-events: none;
        &::after{
          opacity: 1;
        }
      }
    }
    &__frame-wrap {
      position: relative;
      transform-origin: left top 0px;
      box-sizing: content-box;
      display: flex;
      flex-wrap: wrap;
      min-width: 300px;
      min-height: 300px;
      border: 1px solid #e7f1f1;
      background-color: #e7f1f1;

      &:hover,
      &:focus {
        .my-frame__frame-panel {
          &_top.is-float {
            transform: translateY(-100%);
          }
          &_bottom.is-float {
            transform: translateY(100%);
          }
        }

        .is-float {
          + .my-frame__iframe {
            padding: 0 !important;
          }
        }

      }
    }

    &__resizer{
      $resizer-width: 21px;
      $resizer-line-color: #80d823; //rgba(#262626, .35);
      $resizer-line-color-hover: #fff; //rgba(#262626, .67);

      background: #294665; //rgba(#eee, 1);
      position: relative;
      &::before,
      &::after{
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 30px;
        width: 2px;
        border-left: 2px solid $resizer-line-color;
        border-right: 2px solid $resizer-line-color;
        transition: border-color .25s ease-in-out;
      }
      &::after{
        height: 2px;
        width: 30px;
        border: none;
        border-top: 2px solid $resizer-line-color;
        border-bottom: 2px solid $resizer-line-color;
      }
      &:focus,
      &:hover{
        &::before,
        &::after{
          border-color: $resizer-line-color-hover;
        }
      }
      &_horizontal{
        cursor: ew-resize;
        min-width: $resizer-width;
        max-width: $resizer-width;
        height: calc(100% - #{$resizer-width});
        &::after{
          display: none;
        }
        .my-frame_landscape &{
          cursor: ns-resize;
          height: $resizer-width;
          min-width: calc(100% - #{$resizer-width});
          max-width: calc(100% - #{$resizer-width});
          order: 2;
          &::before{
            display: none;
          }
          &::after{
            display: block;
          }
        }
      }
      &_vertical{
        cursor: ns-resize;
        height: $resizer-width;
        min-width: calc(100% - #{$resizer-width});
        max-width: calc(100% - #{$resizer-width});
        &::before{
          display: none;
        }
        .my-frame_landscape &{
          cursor: ew-resize;
          min-width: $resizer-width;
          max-width: $resizer-width;
          height: calc(100% - #{$resizer-width});
          &::before{
            display: block;
          }
          &::after{
            display: none;
          }
        }
      }
      &_corner{
        cursor: nwse-resize;
        height: $resizer-width;
        min-width: $resizer-width;
        max-width: $resizer-width;
        order: 3;
        &::before{
          height: 18px;
          width: 4px;
          border-right: none;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after{
          width: 10px;
          height: 4px;
          border-top: none;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &__frame-panel {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      transition: transform .2s linear;
      background: #f8f8f8;
      z-index: 10;

      //&_top {
      //  box-shadow: 1px 0 1px 0 rgba(#000, .15);
      //}
      &_bottom {
        top: auto;
        bottom: 0;
        //box-shadow: -1px 0 1px 0 rgba(#000, .15);
        .ios-address-bar{
          padding: 8px 22px 4px;
          &__text{
            height: 44px;
            line-height: 44px;
          }
          &__icon{
            height: 44px;
            width: 44px;
          }
        }
      }

      svg {
        display: block;
        fill: currentColor;
      }
    }
    &__bar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      min-width: 100%;
      &_top{
        height: 20px;
        padding: 0 5px 0 7px;
        color: #000;
      }
      &_iphone-new{
        ~ .ios-address-bar{
          margin-top: -8px;
        }
      }
      &_iphone-new-top-top{
        height: 47px;
        background: #f8f8f8;
        padding: 6px 15px 0 34px;
        .my-frame__icon{
          color: #000;
        }
      }
      &_iphone-new-top-bottom{
        padding: 0 42px;
        .ios-address-bar{
          width: calc(100% - #{40px * 6});
          min-width: 340px;
          padding-left: 0;
          padding-right: 0;
        }
      }
      &_iphone-new-bottom-top{
        height: 44px;
        padding: 0 12px;
        .my-frame__icon{
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          &_share{
            margin-top: -2px;
          }
        }
      }
      &_iphone-new-bottom-bottom{
        position: relative;
        height: 33px; //5px + 9 * 2;
        &::after{
          content: '';
          display: block;
          position: absolute;
          background: #000; //rgba(#000, .75);
          width: 134px;
          height: 5px;
          border-radius: 5px;
          bottom: 9px;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
    &__icons{
      &_left,
      &_right{
        width: 100px;
      }
      &_right{
        display: flex;
        justify-content: flex-end;
      }
    }
    &__icon{
      color: #c4c4c4;
      display: block;
      &_arrow-right{
        transform: scaleX(-1);
      }
      &_share,
      &_history,
      &_tabs,
      &_add{
        color: #007aff;
      }
    }

    &__iframe {
      position: relative;
      border: none;
      box-sizing: border-box;
      transition: padding .2s linear;
      min-width: 300px;
      min-height: 300px;

      .my-frame__frame-panel:not(.is-hidden) + & {
        padding-top: 70px;
        &.phone {
          padding-bottom: 44px;
        }
      }
    }

    &_iphone-new{
      .my-frame__frame-panel:not(.is-hidden) + .my-frame__iframe {
        padding-top: 47px;
        padding-bottom: 133px;
      }
      &.my-frame_landscape{
        .my-frame__frame-panel:not(.is-hidden) + .my-frame__iframe {
          padding-bottom: 0;
        }
      }
    }

    &_landscape{
      .my-frame__frame-panel:not(.is-hidden) + .my-frame__iframe {
        padding-top: 50px;
      }
    }
  }
</style>

<style lang="scss">
  .my-frame {
    &__title {
      .el-select {
        width: 35px;

        .my-frame_desktop &,
        .my-frame_laptop & {
          width: 40px;
          text-align: center;
          margin-top: -1px;
        }
      }

      .el-input {
        &__inner {
          display: inline-block;
          height: 21px;
          margin: -3px 0 -4px;
          padding: 0 !important;
          border: none;
          border-bottom: 1px dashed #868686;
          font-size: 11px;
          background-color: transparent;
          color: inherit;
          box-shadow: none;

          .my-frame_desktop &,
          .my-frame_laptop & {
            font-size: 16px;
            font-weight: 700;
          }
        }

        &__suffix{
          display: none;
        }

        .el-icon-caret-top {
          display: none;
        }
      }
    }
  }
</style>
