import * as types from './mutation-types'
import axios from 'axios'
import router from '../router'
import Storage from '../lib/js/storage'
import { isLocalhost } from '../lib/js/helpers'
const store = new Storage('iloveadaptive')

const actions = {
  setTestHref({ dispatch, commit }, options) {
    let value = typeof options === 'object' ? options.value : options
    let page = typeof options === 'object' ? (options.page || 'url') : 'url'
    let num = typeof options === 'object' ? (options.num || 0) : 0

    if (value && !/^(http(s*):\/\/)/.test(value)) {
      value = `http://${value}`
    }

    if (!value) {
      if (page === 'compare-urls') {
        commit(types.SET_TEST_HREF, {
          value,
          num
        })
      }

      return router.push({
        path: `/${page}/`
      }).catch(e => console.log(e));
    }

    if (isLocalhost(value)) {
      commit(types.SET_TEST_HREF, {
        value,
        num
      })
      dispatch('updateHistoryStore', value)
    } else {
      // commit(types.SET_TEST_HREF, value)
      commit(types.SET_TEST_HREF_FETCHING, {
        value: true,
        num
      })
      axios.get(`/api/web/redirects?url=${value}&iloveadaptive-lang=${window.navigator.language}`)
        .then(response => {
          const isError = response.data.status === 'error' && response.data.code !== 404;
          let location = response.data.location && response.data.location.replace(/([a-zA-Z+.-]+):\/\/([^/]+):([0-9]+)\//, '$1://$2/')
          commit(types.SET_TEST_HREF, {
            value: location || value,
            num
          })

          if (page !== 'compare-urls') {
            router.push({
              path: `/${page}/` + encodeURIComponent(location || value)
            }).catch(e => console.log(e));
          }
          commit(types.SET_TEST_HREF_FETCHING, {
            value: false,
            num
          })

          if (isError) {
            commit(types.SET_ERRORS, response.data)
          } else if (response.data.code !== 404) {
            commit(types.SET_TEST_HREF_X_FRAME_OPTIONS, {
              value: response.data.headers['x-frame-options'],
              num
            })
            commit(types.SET_TEST_CONTENT_SECURITY_POLICY, {
              value: response.data.headers['content-security-policy'],
              num
            })
            dispatch('updateHistoryStore', location || value)
          }
        })
        .catch(error => {
          //console.log(error);
        });
    }
  },
  setCurrentOs({ dispatch, commit }, value) {
    // if (isArray(value)) {
    //   commit('SET_CURRENT_OS', value)
    // } else if (isString(value)) {
    //
    // }
  },
  setHistory({ commit }) {
    const history = store.fetch()
    commit(types.SET_URLS_HISTORY, history)
  },
  clearHistory({ commit }) {
    store.save([])
    commit(types.CLEAR_URLS_HISTORY)
  },
  removeFromHistory({ commit }, val) {
    let history = store.fetch()
    history = history.filter(item => item !== val)
    store.save(history)
    commit(types.UPDATE_HISTORY, history)
  },
  updateHistoryStore({ commit }, val) {
    let history = store.fetch()

    if (!history.includes(val)) {
      history.push(val)
    } else {
      const valIndex = history.indexOf(val);
      history.splice(valIndex, 1)
      history.push(val)
    }

    store.save(history)

    commit(types.UPDATE_HISTORY, store.fetch())
  },
  updatePanelMode({ commit }, value) {
    commit(types.SET_PANEL_MODE, value)
  },
  dropState({ commit, state }) {
    commit(types.SET_CURRENT_OS, state.defaults.currentOS)
    commit(types.SET_CURRENT_DEVICE_TYPE, state.defaults.currentDeviceType)
    commit(types.SET_ORIENTATION, state.defaults.orientation)
    commit(types.SET_ZOOM, state.defaults.zoom)
  }
}

export default actions
