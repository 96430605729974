<template lang="pug">
  include ../../../pug/_index.pug

  +b.w3c-validator(v-loading.lock="isLoading && href[num]", :class="isLoading && href[num] ? 'loading' : ''")
    +e.A.a(v-bind:href="`${validatorUrl}${href[num]}`", target="_blank")
      +e.logo
        w3c-logo.w3c-validator__logo-img
      +e.title Validator:
      +e.results
        template(v-if="!noErrors")
          +e.errors.__results-item {{errors.length ? ('Err ' + errors.length) : '&ndash;'}}
          +e.warnings.__results-item {{warnings.length ? ('Warr ' + warnings.length) : '&ndash;'}}
        +e.no-errors(v-else) Ошибок нет
</template>

<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  import w3cLogo from './assets/w3c.svg'

  export default {
    components: {
      w3cLogo
    },
    data() {
      return {
        validatorUrl: 'https://validator.w3.org/nu/?doc=',
        errors   : 0,
        warnings : 0,
        isLoading: false
      }
    },
    props: {
      num: {
        type: Number,
        default: 0
      }
    },
    computed  : {
      noErrors() {
        return this.href[this.num] && !this.errors && !this.warnings
      },
      ...mapState({
        href: 'testHref'
      })
    },
    watch     : {
      href(href) {
        this.getValidation(href)
      }
    },
    mounted() {
      this.getValidation(this.href[this.num])
    },
    methods   : {
      getValidation(href) {
        this.isLoading = true

        if (href && !this.$store.state.errors.length) {
          axios.get(`${this.validatorUrl}${href}&out=json`)
            .then(result => {
              const messages = result.data.messages
              const errors   = messages.filter(item => item.type === 'error')
              const warnings = messages.filter(item => item.type === 'info' && item.subType === 'warning')

              this.errors = errors
              this.warnings = warnings

              this.isLoading = false
            })
        } else {
          this.isLoading = false
        }
      }
    }
  }
</script>

<style lang="scss">
  .w3c-validator {
    position: relative;
    &::after{
      transition: opacity .3s ease-in-out;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: hsla(0,0%,100%,.9);
      opacity: 0;
      pointer-events: none;
    }

    &.loading{
      pointer-events: none;
      &::after{
        opacity: 1;
      }
    }
    .el-loading-mask {
      top: 50%;
      left: 50%;
      bottom: auto;
      right: auto;
      transform: translate(-50%);
      margin-left: -21px;
      background: none;
    }
  }
</style>

<style lang="scss" scoped="">
  .w3c-validator {
    white-space: nowrap;
    &__a {
      display: flex;
      align-items: center;
      text-decoration: none !important;
    }

    &__logo {
      margin-right: rem-calc(5);

      &-img {
        display: block;
        width: 32px;
      }
    }

    &__title {
      margin-right: rem-calc(10);
      color: #727575;
      font-size: 12px;
      font-weight: 700;
    }

    &__results {
      font-size: 12px;

      &-item {
        display: inline-block;
        vertical-align: middle;

        & + & {
          margin-left: rem-calc(10);
        }
      }
    }

    &__errors {
      color: $alert-color;
    }

    &__warnings {
      color: #dda33e;
    }

    &__no-errors {
      color: $color-primary;
    }
  }
</style>
