<i18n src="./locales.json"></i18n>

<template>
  <div class="logo" @click="clickHandler" :title="$t('logoTitle')">
    <logo-image :style="{width: width, height: height, color: color}"></logo-image>
  </div>
</template>

<script>
  import LogoImage from './assets/logo.svg'

  export default {
    name: 'logo',
    props: {
      isLink: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number,
        default: 115
      },
      height: {
        type: Number,
        default: 90
      },
      color: {
        type: String,
        default: '#000'
      }
    },
    components: {
      LogoImage
    },
    methods: {
      clickHandler() {
        this.$store.dispatch('setTestHref', this.$store.state.testHref[0]).catch(e => console.log(e))

        this.$emit('click')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    cursor: pointer;
    svg {
      width: 115px;
    }
  }
</style>
