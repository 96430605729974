const helpers = require('./helpers')

const ENV = process.env.NODE_ENV || 'development'

module.exports = {
  locales: [
    'en',
    'ru'
  ],
  description: {
    en: 'Service for testing adaptive HTML-site layout',
    ru: 'Сервис для тестирования адаптивной HTML-верстки сайтов'
  },
  env: ENV,
  port: process.env.PORT || 3010,
  host: process.env.HOST || 'localhost',
  globals: {
    'process.env': {
      'NODE_ENV': JSON.stringify(ENV)
    },
    NODE_ENV: ENV,
    __DEV__: ENV === 'development',
    __PROD__: ENV === 'production'
  },
  outDir: helpers('dist'),
  publicPath: ENV === 'production' ? '/' : '/'
}
