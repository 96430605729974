<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.compare-frames
    +e.container
      +e.wrap(v-loading.lock="isLoading")
        +e.top
          my-frame(
          v-if="!isLoading",
          :onlyHead="true",
          :testHref="frames[0].href",
          :size="frames[0].size",
          :title="frames[0].title",
          :zoom="zoom",
          :deviceType="frames[0].deviceType",
          :os="frames[0].os",
          :newIphoneTop="frames[0].newIphoneTop",
          :iphone6Top="frames[0].iphone6Top",
          :currentHeight="frames[0].currentHeight || 0",
          :currentWidth="frames[0].currentWidth || 0",
          :new="frames[0].new",
          :custom="frames[0].custom",
          :num="0")

        +e.body
          +e.frame(v-for="frame, index in frames", :key="index")
            my-frame(
            v-if="!isLoading",
            :testHref="frame.href",
            :size="frame.size",
            :title="frame.title",
            :zoom="zoom",
            :deviceType="frame.deviceType",
            :os="frame.os",
            :newIphoneTop="frame.newIphoneTop",
            :iphone6Top="frame.iphone6Top",
            :currentHeight="frame.currentHeight || 0",
            :currentWidth="frame.currentWidth || 0",
            :new="frame.new",
            :custom="frame.custom",
            :num="index")

          +e.add
            +e.BUTTON.add-button(type="button", :title="$t('addButtonTitle')", @click="addFrame") +
</template>

<script>
  import { mapState } from 'vuex'
  import MyFrame from '../VFrame'

  export default {
    components: {
      MyFrame
    },

    data() {
      return {
        frames: []
      }
    },

    computed: {
      isLoading() {
        const isLoading = this.$store.state.testHrefFetching.some(frame => {
          frame === true
        })
        return isLoading
      },

      ...mapState([
        'zoom'
      ])
    },

    created() {
      this.newFrame(this.$store.state.testHref[0])
    },

    mounted() {
      this.$store.watch(state => {
        return state.myframes[0].size
      }, state => {
        this.frames = this.frames.map(frame => {
          frame.size.width = state.width
          frame.size.height = state.height
          return frame
        })
      }, {
        deep: true
      })

      this.$store.subscribe(mutation => {
        if (mutation.type === 'SET_TEST_HREF') {
          this.newFrame(mutation.payload.value, mutation.payload.num)
        }
      });
    },

    methods: {
      newFrame(href, num = 0) {
        if (typeof href === 'undefined') return this.frames.splice(num, 1)

        const customFrame = Object.assign({}, this.$store.state.myframes[0])
        customFrame.href = href

        this.frames.splice(num, 1, customFrame)
      },

      addFrame() {
        const value = ''
        const num = this.$store.state.testHref.length
        this.$store.dispatch('setTestHref', {
          page: 'compare-urls',
          value,
          num
        }).catch(e => console.log(e))

        this.newFrame(value, num)
      }
    }
  };
</script>

<style lang="scss">
  .compare-frames{
    &__wrap{
      display: flex;
      flex-wrap: wrap;
    }

    &__top{
      min-width: 100%;
      padding-top: 25px;
      padding-bottom: 15px;
      .my-frame{
        min-height: 0;
        display: inline-flex;
      }
    }

    &__body{
      display: flex;
      margin: 0 -10px;
    }
    &__frame{
      margin-left: 10px;
      margin-right: 10px;
    }

    &__add{
      margin-left: 30px;
      display: flex;
      margin-top: -20px;
      &-button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border: none;
        padding: 0;
        border-radius: 50%;
        background: #e7f1f1;
        font-size: 64px;
        font-weight: bold;
        line-height: 80px;
        color: #3f4444;
        outline: none;
        transition: background .25s ease-in-out, color .25s ease-in-out;
        -webkit-appearance: none;
        &:hover,
        &:focus{
          background: #7c8989;
          color: #fff;
        }
      }
    }
  }
</style>
