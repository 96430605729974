<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  .index-screen(ref="indexScreen")
    +b.homescreen(ref="homescreen")
      +e.container
        +e.HEADER.header
          site-navigation(:logo="true")

        +e.MAIN.main
          //-+e.icons
            +e.icon
              iphoneIcon(class="svg-icon", style="width: 18px")
            +e.icon
              ipadIcon(class="svg-icon", style="width: 26px")
            +e.icon
              laptopIcon(class="svg-icon", style="width: 56px")
            +e.icon
              computerIcon(class="svg-icon", style="width: 63px")

          +e.H1.title
            | {{ $t('helloPre') }}
            strong {{ $t('hello') }}
            span(v-html="$t('helloPost')")

          +e.input(:class="{'state-loading': loadingState === 'start'}")
            +e.input-container
              hrefInput(ref="hrefInput", history-width="424px")
            +e.input-progress
              progress-bar(type="line", ref="line", :options="options")

        +e.FOOTER.footer
          +e.extension
            a(:href="$store.extensionLink" target="_blank")
              chrome-icon(class="svg-icon", style="width: 26px")
              +e.SPAN.extension-text {{ $t('downloadExt') }}

          +e.updates
            +e.A.updates-link(:href="$store.hrefPrefix + '/updates/'")
              +e.SPAN.updates-top {{ $store.date }}
              +e.SPAN.updates-bottom {{ $t('updates') }}

          //-+e.social
            socialShare(:simple="true")

    +b.index-content(ref="indexContent")
      - let i = 0
      +e.screen._grey(ref="greyBlock")
        +e.container
          +e.block._block1
            +e.row
              -
                const block2Items = [
                {
                icon: 'os',
                title: '$t(\'block1.title1\')',
                text: '$t(\'block1.text1\')'
                },
                {
                icon: 'devices',
                title: '$t(\'block1.title2\')',
                text: '$t(\'block1.text2\')'
                },
                {
                icon: 'custom',
                title: '$t(\'block1.title3\')',
                text: '$t(\'block1.text3\')'
                },
                {
                icon: 'zoom',
                title: '$t(\'block1.title4\')',
                text: '$t(\'block1.text4\')'
                }
                ]
              each val, n in block2Items
                +e.col
                  +e.col-inner(data-scroll="", data-scroll-delay=(n % 2 === 0 ? null : "0.25"))
                    +e.col-row
                      +e.left
                        +e.icon
                          #{'icon-' + val.icon}
                        +e.title(v-html=val.title)
                      +e.right
                        +e.number= `0${i + 1}.`
                        +e.text(v-html=val.text)
                - i++

          +e.block._block2
            +e.row
              +e.video
                video(autoplay="", loop="", playsinline="")
                  source(src="./assets/video/main.mp4", type="video/mp4")
                //-img(src="https://via.placeholder.com/1228x700.png?text=Video", alt="")

          +e.block._block3
            +e.row
              -
                const block3Items = [
                {
                icon: 'panel',
                title: '$t(\'block3.title1\')',
                subtitle: '$t(\'block3.subtitle1\')',
                text: '$t(\'block3.text1\')'
                },
                {
                icon: 'rotate',
                title: '$t(\'block3.title2\')',
                text: '$t(\'block3.text2\')'
                },
                {
                icon: 'height',
                title: '$t(\'block3.title3\')',
                text: '$t(\'block3.text3\')'
                },
                {
                icon: 'scroll',
                title: '$t(\'block3.title4\')',
                text: '$t(\'block3.text4\')'
                }
                ]
              each val, n in block3Items
                +e.col
                  +e.col-inner(data-scroll="", data-scroll-delay=(n % 2 === 0 ? null : "0.25"))
                    +e.col-row
                      +e.left
                        +e.icon
                          #{'icon-' + val.icon}
                        +e.title(v-html=val.title)
                        if val.subtitle
                          +e.subtitle(v-html=val.subtitle)
                      +e.right
                        +e.number= `0${i + 1}.`
                        +e.text(v-html=val.text)
                - i++

      +e.screen._white(ref="whiteBlock")
        +e.container
          +e.block._block4
            +e.row
              +b.index-compare
                +e.top
                  +e.left
                    +e.title(data-scroll="")
                      strong {{ $t('block4.titleBold') }}
                      | {{ $t('block4.title') }}
                    +e.text(data-scroll="", data-scroll-delay="0.15") {{ $t('block4.text') }}
                    +e.A.link(:href="$store.hrefPrefix + '/compare/'" target="_blank", data-scroll="", data-scroll-delay="0.3")
                      external-link
                      span {{ $t('block4.link') }}
                  +e.right
                    +e.items(data-scroll-items="", data-scroll-delay-items="0.4")
                      -
                        const block4Items = [
                        {
                        text: '{{ $t(\'block4.item1\') }}'
                        },
                        {
                        text: '{{ $t(\'block4.item2\') }}'
                        },
                        {
                        text: '{{ $t(\'block4.item3\') }}'
                        },
                        {
                        text: '{{ $t(\'block4.item4\') }}'
                        },
                        {
                        text: '{{ $t(\'block4.item5\') }}'
                        },
                        {
                        text: '{{ $t(\'block4.item6\') }}'
                        }
                        ]
                      each val, i in block4Items
                        +e.item
                          +e.item-inner
                            +e.item-number= `0${i + 1}.`
                            +e.item-text= val.text

          +e.block._block5
            +e.row
              +e.video
                video(autoplay="", loop="", playsinline="")
                  source(src="./assets/video/compare.mp4", type="video/mp4")
                //-img(src="https://via.placeholder.com/1228x700.png?text=Video", alt="")

          +e.block._block6
            +e.row
              +b.index-rating
                +e.top
                  +e.left
                    +b.index-stats
                      +e.lines(_data-scroll-items="", _data-scroll-hook="0")
                        +e.line(:_style="'height: 3.11%'", data-height="3.11")
                          +e.title
                            span UC Browser
                            strong 3.11%

                        +e.line(:_style="'height: 3.5%'", data-height="3.5")
                          +e.title
                            span Samsung Internet
                            strong 3.5%

                        +e.line(:_style="'height: 4.86%'", data-height="4.86")
                          +e.title
                            span Firefox
                            strong 4.86%

                        +e.line(:_style="'height: 15.83%'", data-height="15.83")
                          +e.title
                            span Safari
                            strong 15.83%

                        +e.line(:_style="'height: 62.8%'", data-height="62.8")
                          +e.title
                            span Chrome
                            strong 62.8%

                  +e.right
                    +e.title(data-scroll="", data-scroll-delay="0.1")
                      strong {{ $t('block6.titleBold') }}
                      | {{ $t('block6.title') }}
                    +e.text(data-scroll="", data-scroll-delay="0.2") {{ $t('block6.text') }}

                    +e.regions(data-scroll="", data-scroll-delay="0.3")
                      +e.region(v-for="item, index in Object.keys(regions)") {{ $t('regions.' + item) }}

                    +e.A.link(:href="$store.hrefPrefix + '/browser-rating/'" target="_blank", data-scroll="", data-scroll-delay="0.4")
                      external-link
                      span {{ $t('block6.link') }}

          +e.block._block7
            +e.row
              +b.index-displays
                +e.top
                  +e.left
                    +e.title(data-scroll="")
                      strong {{ $t('block7.titleBold') }}
                      | {{ $t('block7.title') }}
                    +e.text(data-scroll="", data-scroll-delay="0.1") {{ $t('block7.text') }}

                    +e.displays(data-scroll="", data-scroll-delay="0.2")
                      -
                        const displays = [
                          '11 Pro',
                          '11',
                          'Xs Max',
                          'Xr',
                          'X, Xs',
                          '6+',
                          '6s+',
                          '7+',
                          '5',
                          '5s',
                          'SE',
                          '8+',
                          '4',
                          '4s',
                          '2G',
                          '3G',
                          '3GS'
                        ]
                      each val in displays
                        +e.display= val

                    +e.A.link(:href="$store.hrefPrefix + '/iphone-displays/'" target="_blank", data-scroll="", data-scroll-delay="0.3")
                      external-link
                      span {{ $t('block7.link') }}

                  +e.right
                    +e.image(data-scroll="", data-scroll-delay="0.2")
                      iphone-xs

                      +e.comment._center(data-scroll="", data-scroll-delay="0.2")
                        strong Xs Max
                        span 414 × 896

                      +e.comment._comment1(data-scroll="", data-scroll-delay="0.3")
                        strong 6.5″
                        span {{ $t('block7.inch') }}

                      +e.comment._comment2(data-scroll="", data-scroll-delay="0.4")
                        strong 458
                        span {{ $t('block7.ppi') }}

                      +e.comment._comment3(data-scroll="", data-scroll-delay="0.5")
                        strong 1242 × 2688
                        span {{ $t('block7.render') }}

      site-footer
</template>

<script>
  import SiteLogo from '@/components/Vlogo'
  import HrefInput from '@/components/VHrefInput'
  import SiteNavigation from '@/components/VNavigation'
  import SiteFooter from '@/components/VFooter'

  import chromeIcon from './assets/chrome.svg'
  import externalLink from './assets/external-link.svg'

  import iconOs from './assets/block1/os.svg'
  import iconDevices from './assets/block1/devices.svg'
  import iconCustom from './assets/block1/custom.svg'
  import iconZoom from './assets/block1/zoom.svg'

  import iconPanel from './assets/block2/panel.svg'
  import iconRotate from './assets/block2/rotate.svg'
  import iconHeight from './assets/block2/height.svg'
  import iconScroll from './assets/block2/scroll.svg'

  import iphoneXs from './assets/block7/iphone-xs-max-physical.svg'

  import ScrollMagic from 'ScrollMagic'
  import 'debug.addIndicators'

  export default {
    name: 'home-screen',
    data() {
      return {
        loadingState: 'wait',
        options: {
          trailColor: '#a8a8a8',
          color: '#22D014',
          strokeWidth: 0.33
        },

        regions: {
          'worldwide': true,
          'russian-federation': true,
          'ukraine': true,
          'belarus': true,
          'united-states-of-america': true,
          'india': true,
          'kazakhstan': true,
          'germany': true,
          'china': true,
          'africa': true,
          'asia': true,
          'europe': true,
          'north-america': true,
          'oceania': true,
          'south-america': true
        }
      }
    },
    computed: {
      isLoading() {
        return this.$store.state.testHrefFetching[0]
      }
    },
    watch: {
      isLoading() {
        if (this.isLoading && this.loadingState === 'wait') {
          this.loadingState = 'start'
          this.$refs.line.animate(0.5)
        } else if (this.loadingState === 'start') {
          this.$refs.line.animate(1)
          setTimeout(() => {
            this.loadingState = 'wait'
          }, 800, this)
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.hrefInput.$el.querySelector('input[type="url"]').focus();

        const controller = new ScrollMagic.Controller();

        const duration = window.innerHeight;

        if (!this.$store.isTouch) {

          // this.$refs.indexScreen.style.paddingTop = `${duration}px`;
          //
          // this.$refs.whiteBlock.style.transform = `translateY(-${duration}px)`;
          // this.$refs.whiteBlock.style.marginBottom = `-${duration}px`;

          // new ScrollMagic.Scene({
          //   triggerElement: this.$refs.homescreen,
          //   triggerHook: 0,
          //   duration
          // })
          //   .setPin(this.$refs.homescreen)
          //   // .on('progress', event => {
          //   //   const progress = event.progress;
          //   //   console.log(progress);
          //   //   this.$refs.greyBlock.style.transform = `translateY(-${progress * duration}px)`;
          //   // })
          //   .addIndicators()
          //   .addTo(controller);

          // new ScrollMagic.Scene({
          //   triggerElement: this.$refs.whiteBlock,
          //   triggerHook: 0,
          //   duration,
          //   offset: -duration
          // })
          //   .setPin(this.$refs.greyBlock)
          //   //.addIndicators()
          //   .addTo(controller);

          //Video
          const minMaxValue = (value, min, max) => min + value * (max - min);

          const video = document.querySelectorAll('.index-content__video');
          Array.from(video).forEach(item => {
            new ScrollMagic.Scene({
              triggerElement: item,
              triggerHook: 1,
              duration: duration * 0.75,
              reverse: false
            })
              .on('progress', event => {
                const progress = event.progress;
                const newScale = minMaxValue(progress, .5, 1);
                const newTranslate = minMaxValue(progress, 0.33, 0);
                item.children[0].style.transform = `scale(${newScale}) translateY(-${newTranslate}%)`;
              })
              //.addIndicators()
              .addTo(controller);
          });
        }

        Array.from(document.querySelectorAll('[data-scroll-delay]')).forEach(item => {
          item.style.transitionDelay = `${item.dataset.scrollDelay}s`;
        });

        Array.from(document.querySelectorAll('[data-scroll]')).forEach(item => {
          new ScrollMagic.Scene({
            triggerElement: item,
            triggerHook: parseFloat(typeof item.dataset.scrollHook === 'undefined' ? 0.75 : item.dataset.scrollHook),
            offset: '-40px',
            reverse: false
          })
            .setClassToggle(item, 'scroll-active')
            //.addIndicators()
            .addTo(controller);
        });

        Array.from(document.querySelectorAll('[data-scroll-items]')).forEach(container => {
          let itemNum = 0;
          Array.from(container.children).forEach(item => {
            item.style.transitionDelay = `${(parseFloat(container.dataset.scrollDelayItems || 0) + (itemNum * 0.075)).toFixed(2)}s`;
            itemNum += 1;
          });

          new ScrollMagic.Scene({
            triggerElement: container,
            triggerHook: parseFloat(typeof container.dataset.scrollHook === 'undefined' ? 0.75 : container.dataset.scrollHook),
            reverse: false
          })
            .setClassToggle(container, 'scroll-active-items')
            //.addIndicators()
            .addTo(controller);
        });

        //Lines
        const lines = document.querySelector('.index-stats__lines');
        if (lines) {
          let itemNum = 0;
          Array.from(lines.children).forEach(line => {
            line.style.transitionDelay = `${(itemNum * 0.2).toFixed(2)}s`;
            itemNum += 1;
          });
          new ScrollMagic.Scene({
            triggerElement: lines,
            triggerHook: 1,
            offset: lines.offsetHeight + duration * .25,
            reverse: false
          })
            .on('enter', () => {
              Array.from(lines.children).forEach(line => {
                line.style.opacity = '1';
                line.style.height = `${line.dataset.height}%`;
              });
            })
            //.addIndicators()
            .addTo(controller);
        }
      });
    },
    components: {
      SiteLogo,
      HrefInput,
      SiteNavigation,
      SiteFooter,

      chromeIcon,
      externalLink,

      iconOs,
      iconDevices,
      iconCustom,
      iconZoom,

      iconPanel,
      iconRotate,
      iconHeight,
      iconScroll,

      iphoneXs
    }
  }
</script>

<style lang="scss">
  @import './styles.scss';
</style>

<style lang="scss" scoped>
  @import './styles-scoped.scss';
</style>
