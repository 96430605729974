<template>
  <home-screen></home-screen>
</template>

<script>
  import HomeScreen from '@/components/VHomeScreen'

  export default {
    data() {
      return {}
    },
    components: {
      HomeScreen
    },
    computed: {
      isLoading() {
        return this.$store.state.testHrefFetching[0]
      }
    },
    beforeRouteLeave(to, from, next) {
      if (this.isLoading) {
        this.$watch('isLoading', () => setTimeout(next, 800))
      } else {
        next()
      }
    }
  }
</script>
