<template lang="pug">
  include ../../../pug/_index.pug

  +b.ios-address-bar
    template
      +e.text
        +e.icon._font
          font-icon
        | {{ href | domain }}
        +e.icon._reload
          reload-icon
</template>

<script>
  import fontIcon from './assets/font.svg'
  import reloadIcon from './assets/reload.svg'

  export default {
    data() {
      return {}
    },
    props: {
      href: {
        type: String,
        'default': 'example.com'
      },
      deviceType: {
        type: String
      }
    },
    filters: {
      domain(url) {
        let a, domain

        if (!url) return ''

        a = document.createElement('a')
        a.href = url

        domain = a.hostname.replace(/^www\./, '')

        return domain
      }
    },
    components: {
      fontIcon,
      reloadIcon
    }
  }
</script>

<style lang="scss" scoped>
  .ios-address-bar {
    background-color: #f8f8f8;
    padding: 4px 10px 10px;

    &__text {
      position: relative;
      height: 36px;
      line-height: 36px;
      background-color: rgba(#8E8E93, .1); //#e6e6e7;
      border-radius: 12px;
      //font-weight: 600;
      text-align: center;
      overflow: hidden;
      color: #000;
    }

    &__icon{
      position: absolute;
      top: 0;
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      &_font{
        left: 0;
      }
      &_reload{
        right: 0;
      }
    }
  }
</style>
