<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.filter
    el-checkbox-group(v-model='checkedFrames', @change="handleFramesChange", size="small")
      el-checkbox(
      :key="frames[0].id",
      :label="frames[0].id",
      :title="$t('customSize')") {{ getFrameTitle(frames[0]) }}

      //-+e.custom(v-if="frames[0].deviceType === 'phone' && customSize")
        +e.INPUT.input._width(v-model="customWidth", ref="customWidth")
        | x
        +e.INPUT.input._height(v-model="customHeight")

      el-checkbox(
        v-for="frame, index in frames.slice(1).filter(function(frame){ return frame.active })",
        :key="frame.id",
        :label="frame.id",
        :title="frame.title",
        :class="frame.new ? 'new' : ''") {{ getFrameTitle(frame) | truncate(25) }}
          // star(v-if="frame.popular").svg-icon.svg-icon-star
          +e.badge._new(v-if="frame.new") new

      +e.btns
        +e.BUTTON.btn(type="primary", @click="checkAll") {{ $t('selectAll') }}
        +e.BUTTON.btn._popular(type="primary", @click="checkPopular") {{ $t('popular') }}
</template>

<script>
  import Star from './assets/star.svg'

  export default {
    name: 'ffilter',
    data() {
      return {
        checkedFrames: [],
        allChecked: false,
        customSize: false
      }
    },
    props: {
      frames: {
        type: Array,
        required: true
      },
      currentFrames: {
        type: Array,
        required: true
      }
    },
    components: {
      Star
    },
    computed: {
      customWidth: {
        get() {
          const customFrame = this.frames.find(frame => frame.custom);
          return customFrame && customFrame.size.width || this.$store.state.myframes[0].size.width;
        },
        set(value) {
          this.$store.commit('customWidth', value)
        }
      },
      customHeight: {
        get() {
          const customFrame = this.frames.find(frame => frame.custom);
          return customFrame && customFrame.size.height || this.$store.state.myframes[0].size.height;
        },
        set(value) {
          this.$store.commit('customHeight', value)
        }
      }
    },
    created() {
      this.checkedFrames = this.$props.currentFrames
    },
    watch: {
      checkedFrames(value) {
        const cutsomSizeChecked = value.includes(this.$store.customFrameId);
        const isCoustomSizeCheckTriggered = !this.currentFrames.includes(this.$store.customFrameId) && cutsomSizeChecked;
        this.$emit('updateFrames', value)
        this.toggleCustomSize(cutsomSizeChecked, isCoustomSizeCheckTriggered)
      },
      currentFrames(value) {
        this.checkedFrames = value
      },

      customWidth(value) {
        this.$store.commit('customWidth', value)
      },
      customHeight(value) {
        this.$store.commit('customHeight', value)
      }
    },
    methods: {
      checkAll() {
        this.allChecked = !this.allChecked
        this.$emit('checkAll', this.allChecked)
      },
      checkPopular() {
        this.allChecked = false
        this.$emit('checkPopular')
      },
      handleFramesChange(value) {
        let checkedCount = value.length
        this.allChecked = checkedCount === this.frames.length
      },
      getFrameTitle(frame) {
        const type = frame.deviceType

        if (type === 'phone' || type === 'tablet' || type === 'all') {
          return frame.title
        }

        const size = frame.size
        const width = Array.isArray(size.width) ? size.width[0] : size.width
        const height = Array.isArray(size.height) ? size.height[0] : size.height

        return `${width} x ${height}`
      },
      toggleCustomSize(value, focus) {
        this.customSize = value
        if (value && focus) {
          //this.$nextTick(() => this.$refs.customWidth.focus())
          this.$nextTick(() => document.getElementById('customWidth').focus())
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .filter {
    &__btns {
      display: inline-block;
      padding:  rem-calc(3);
      background-color: #eff5f5;
      margin-left: rem-calc(15);
    }

    &__btn {
      padding: rem-calc(4 12);
      font-size: rem-calc(12);
      font-weight: 400;
      background-color: #fff;
      color: #abb3b3;
      outline: none !important;
      transition: color .25s ease-in-out;
      &:focus,
      &:hover{
        color: #2b3d1f;
      }

      & + & {
        margin-left: rem-calc(3);
      }
    }
    &__badge{
      position: absolute;
      border-radius: 7px;
      font-size: 10px;
      top: -3px;
      left: 100%;
      margin-left: -4px;
      padding: 0 5px;
      line-height: 14px;
      text-transform: uppercase;
      &_new{
        //background: $alert-color;
        //color: #fff;
        color: $alert-color;
      }
    }

    &__custom{
      display: inline-flex;
      align-items: center;
      margin-left: -10px;
      margin-right: 15px;
      color: rgba(38,38,38,.67);
      font-size: 11px;
      font-weight: 300;
      line-height: 12px;
    }
    &__input{
      margin: 0 5px 0;
      padding: 0;
      height: 25px;
      width: 50px;
      text-align: center;
      border: 1px solid #c9d9bf;
      box-shadow: none;
      font-size: 13px;
      &:hover,
      &:focus{
        border-color: #80d823;
      }
    }
  }
</style>

<style lang="scss">
  .filter {
    .el-checkbox-group{
      margin-top: -5px;
    }
    .el-checkbox {
      margin-top: 5px;
      margin-right: rem-calc(15);
      &.new{
        padding-right: 20px;
      }
      .svg-icon {
        @include position(absolute, 2px null null 0);
        transform: translateX(-50%);
        color: $color-primary;
        z-index: 5;
      }

      + .el-checkbox {
        margin-left: 0;
      }

      &:focus,
      &:hover{
        .el-checkbox__inner{
          border-color: #80d823;
        }
      }

      &__label{
        position: relative;
        display: inline-flex;
        align-items: center;
      }
    }
  }
</style>
