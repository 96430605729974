import config from '../../config/project.config'

import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './Home'
import Frames from './Frames'
//import NotFoundComponent from './NotFoundComponent'

import isUrl from 'is-url'

import pages from '../../config/pages.js';
import i18n from '../i18n'

if (window.location.pathname !== '/') {
  let fullPath = '';
  window.location.pathname.split('/').forEach(path => {
    if (path === '') return;
    fullPath += `/${path}`
    document.cookie = `lang=;expires=-1;path=${fullPath}`
  });
}

//Настройка путей vue router
const routes = [];
const defaultLocale = config.locales[0];
Object.keys(pages).forEach(page => {
  config.locales.forEach(locale => {
    const currentPage = pages[page];
    const localePrefix = locale === defaultLocale ? '' : `/${locale}`;
    const namePostfix = locale === defaultLocale ? '' : `_${locale}`;
    const baseName = currentPage.name ? currentPage.name : page;
    const name = baseName + namePostfix;
    const basePath = currentPage.path ? currentPage.path : page;
    const pathPostfix = currentPage.pathPostfix ? currentPage.pathPostfix : '';
    const path = localePrefix + (page === 'index' ? '/' : `/${basePath}/`) + pathPostfix;
    const component = page === 'index' ? Home : Frames;
    const title = page;

    const pageOptions = {
      name,
      path,
      component,
      meta: {
        title
      }
    };
    if (locale !== defaultLocale) pageOptions.meta.lang = locale;

    routes.push(pageOptions);

    if (currentPage.alias) {
      const aliasPageOptions = JSON.parse(JSON.stringify(pageOptions));
      aliasPageOptions.component = component;
      aliasPageOptions.name = aliasPageOptions.name + '_redirect';
      aliasPageOptions.path = localePrefix + `/${currentPage.alias}` + pathPostfix;
      aliasPageOptions.redirect = path;

      routes.push(aliasPageOptions);
    }

    if (currentPage.items) {
      currentPage.items.forEach(currentItem => {
        const innerPageOptions = JSON.parse(JSON.stringify(pageOptions));
        innerPageOptions.component = component;
        innerPageOptions.name = innerPageOptions.name + `_${currentItem.path}`;
        innerPageOptions.path = innerPageOptions.path + `${currentItem.path}/`;
        innerPageOptions.meta.region = currentItem.path;

        routes.push(innerPageOptions);

        if (currentPage.alias) {
          const aliasPageOptions = JSON.parse(JSON.stringify(innerPageOptions));
          aliasPageOptions.redirect = aliasPageOptions.path;
          aliasPageOptions.component = component;
          aliasPageOptions.name = aliasPageOptions.name + '_redirect';
          aliasPageOptions.path = localePrefix + `/${currentPage.alias}` + pathPostfix + `/${currentItem.path}/`;

          routes.push(aliasPageOptions);
        }
      });
    }
  });
});

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history'
})

const urlPath = '/url/';

router.beforeEach((to, from, next) => {
  if (!to.params.url && to.path.startsWith('/url/')) { //Fix for opening non-slash ending sites with extension
    to.params.url = to.path.slice(5);
  }
  if (to.name !== 'notFound' && !to.path.endsWith('/') && !to.params.url) {
    return next({
      path: `${to.path}/`
    })
  }

  // if (to.params.errorpagePath && !to.meta.lang) {
  //   return next();
  // }

  const paramsLang = typeof to.meta.lang === 'undefined' ? config.locales.find(locale => {
    return to.path.startsWith(`/${locale}/`) && locale;
  }) : to.meta.lang;
  let cookieLang;
  document.cookie.split('; ').some(cookie => {
    const cookieArray = cookie.split('=');
    const cookieKey = cookieArray[0];
    if (cookieKey === 'lang' && cookieArray[1] !== '') {
      cookieLang = cookieArray[1];
      return true;
    }
  });
  let language = cookieLang || paramsLang;
  // if (to.params.lang && !config.locales.includes(to.meta.lang)) {
  //   return next({
  //     name: 'notFound',
  //     params: {
  //       errorpagePath: to.path
  //     }
  //   })
  // }
  if (!language || !config.locales.includes(language)) {
    language = defaultLocale;
  }

  i18n.locale = language;
  if (to.meta.title && to.meta.title !== 'stats-browsers' && pages[to.meta.title] && pages[to.meta.title][language]) {
    document.originalTitle = pages[to.meta.title][language].title;
    document.title = document.originalTitle + ' — iloveadaptive';
    document.querySelector('meta[name="description"]').setAttribute('content', pages[to.meta.title][language].description);
  }

  let newPath = to.fullPath;
  let pathChanged = false;

  if (to.name !== 'notFound') {
    if (paramsLang && language === defaultLocale) {
      pathChanged = true
      newPath = newPath.substring(newPath.indexOf(paramsLang) + to.meta.lang.length)
    } else if (language !== paramsLang && language !== defaultLocale) {
      pathChanged = true
      newPath = `/${language}${newPath}`
    }
    document.cookie = `lang=${language};path=/`
  }

  if (newPath.startsWith(urlPath) || newPath.startsWith(`/${paramsLang || language}${urlPath}`)) {
    const urlArray = newPath.split(urlPath);

    if (isUrl(urlArray[1])) {
      newPath = urlArray[0] + urlPath + encodeURIComponent(urlArray[1])

      return next({
        path: newPath
      })
    }
  }

  if (to.name === 'notFound') {
    return next();
  }

  if (pathChanged) {
    return next({
      path: newPath
    })
  }

  next();
})

router.afterEach((to, from) => {
  setTimeout(() => {
    if (to.params.errorpagePath) {
      window.history.replaceState({} , document.title, to.params.errorpagePath);
    }
  });

  const fromName = from.name ? from.name.substring(0, from.name.indexOf('_') === -1 ? from.name.length : from.name.indexOf('_')) : false;
  const toName = to.name.substring(0, to.name.indexOf('_') === -1 ? to.name.length : to.name.indexOf('_'))

  if (fromName) document.body.classList.remove(`page-${from.name}`)
  document.body.classList.add(`page-${toName}`)

  let myYaCounter = window.yaCounter45211818

  if (myYaCounter) {
    myYaCounter.hit(to.path, {
      title: document.title,
      referer: from.path
    })
  }
});

router.onError(err => {
  console.log(err);
})

export default router
