<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../../pug/_index.pug

  +b.page-speed(v-loading.lock="isLoading && href[num]", :class="(isInactive ? 'inactive' : '') + (isLoading && href[num] ? ' loading' : '')")
    +e.A.a(@click="getValidation(href[num])", :title="$t('buttonTitle')")
      +e.logo
        google-logo.page-speed__logo-img
      +e.title PageSpeed:
      +e.results.__results-button {{ $t('button') }}

    +e.A.a(:href="`https://developers.google.com/speed/pagespeed/insights/?hl=ru&url=${href[num]}`", target="_blank")
      +e.logo
        google-logo.page-speed__logo-img
      +e.title PageSpeed:
      +e.results
        +e.results-item.__mobile(v-show="!isLimitExceeded")
          el-tooltip(content="Mobile" placement="top")
            span
              span(:class="getScoreClass(scores.mobile)") {{ href[num] ? scores.mobile : '-' }}
              | /100
        +e.results-item.__desktop(v-show="!isLimitExceeded")
          el-tooltip(content="Desktop" placement="top")
            span
              span(:class="getScoreClass(scores.desktop)") {{ href[num] ? scores.desktop : '-' }}
              | /100
        +e.limit(v-show="isLimitExceeded") {{ $t('limitExceeded') }}
</template>

<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  import GoogleLogo from './assets/google-logo.svg'

  export default {
    components: {
      GoogleLogo
    },
    data() {
      return {
        scores: {
          mobile : 0,
          desktop: 0
        },
        isInactive: true,
        isLoading: false,
        isLimitExceeded: false
      }
    },
    props: {
      num: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapState({
        href: 'testHref'
      })
    },
    watch: {
      href(href) {
        //this.getValidation(href)
        this.isLoading = false
        this.isInactive = true
      }
    },
    // mounted() {
    //   this.getValidation(this.href[this.num])
    // },
    methods: {
      getValidation(href) {
        this.isLoading = true
        this.isInactive = false

        if (href) { //this.$store.state.errors.length === 0
          Promise.all([
            axios.get(`https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${href}&strategy=desktop`),
            axios.get(`https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${href}&strategy=mobile`)
          ]).then(results => {
            let [desktop, mobile] = results

            // this.scores.desktop = desktop.data.ruleGroups.SPEED.score
            // this.scores.mobile = mobile.data.ruleGroups.SPEED.score
            this.scores.desktop = parseInt(desktop.data.lighthouseResult.categories.performance.score * 100)
            this.scores.mobile = parseInt(mobile.data.lighthouseResult.categories.performance.score * 100)
            this.isLoading = false
          }).catch(error => {
            const response = error.response;
            console.log(response);
            if (response.status === 429) {
              this.isLoading = false
              this.isLimitExceeded = true
            }
          })
        } else {
          this.isLoading = false
        }
      },
      getScoreClass(scores) {
        const classes = {
          '0': 'danger',
          '65': 'warning',
          '85': 'success'
        }
        const result = Object.keys(classes).reduce((accum, val) => {
          return scores >= parseInt(val) ? classes[val] : accum
        }, 'success')
        return result;
      }
    }
  }
</script>

<style lang="scss">
  .page-speed {
    position: relative;
    &::after{
      transition: opacity .25s ease-in-out;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: hsla(0,0%,100%,.9);
      opacity: 0;
      pointer-events: none;
    }

    &.loading{
      pointer-events: none;
      &::after{
        opacity: 1;
      }
    }
    .el-loading-mask {
      top: 50%;
      left: 50%;
      bottom: auto;
      right: auto;
      transform: translate(-50%);
      margin-left: -21px;
      background: none;
      z-index: 100;
    }
  }
</style>

<style lang="scss" scoped="">
  .page-speed {
    white-space: nowrap;
    position: relative;
    &.inactive{
      .page-speed__a{
        &:first-child{
          position: static;
        }
        &:nth-child(2){
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
      }
    }

    &__a {
      display: flex;
      align-items: center;
      text-decoration: none !important;
      cursor: pointer;
      &:first-child{
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }

    &__logo {
      margin-right: rem-calc(5);

      &-img {
        display: block;
        width: rem-calc(20);
        //height: rem-calc(30);
        //background-image: url('./assets/pagespeed.png');
        //background-size: cover;
      }
    }

    &__title {
      margin-right: rem-calc(10);
      color: #727575;
      font-size: 12px;
      font-weight: 700;
    }
    &__results {
      font-size: 12px;
      &-button{
        font-size: 13px;
        font-weight: 700;
        &:hover,
        &:focus{
          text-decoration: underline;
        }
      }

      &-item {
        display: inline-block;
        color: #3f4444;
        vertical-align: middle;

        & + & {
          margin-left: rem-calc(10);
        }

        .danger {
          color: $alert-color;
        }
        .warning {
          color: $warning-color;
        }
        .success {
          color: $success-color;
        }
      }
    }
    &__limit{
      color: $alert-color;
    }
  }
</style>
