import config from '../config/project.config';

import Vue from 'vue';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';

import './style/theme/index.css';
import './style/style.scss';
import router from './router';
import MainLayout from './layouts/MainLayout';
import i18n from './i18n';
import store from './store';
// import { getCurrentScrollWidth } from './lib/js/helpers';

import ElementUI from 'element-ui';
import VueProgress from 'vue-progress';
import VueWait from 'vue-wait';

import truncate from './util/vue-filters/truncate';

const { __DEV__, __PROD__ } = config.globals;

Vue.use(ElementUI);
Vue.use(truncate);
Vue.use(VueProgress);

if (__PROD__) {
  Raven
    .config('https://f998d357ee13409ea6971bcf25355ccd@sentry.io/207793')
    .addPlugin(RavenVue, Vue)
    .install();
}

window.app = new Vue({
  i18n,
  data: {},
  router,
  store,
  wait: new VueWait(),
  template: '<main-layout/>',
  components: {
    MainLayout
  },
  mounted() {
    // this.scrollbarWidth = getCurrentScrollWidth();

    this.$store.currentLang = this.$i18n.locale;
    this.$store.hrefPrefix = this.$store.currentLang == config.locales[0] ? '' : `/${this.$store.currentLang}`;
    this.$store.dispatch('setHistory');

    const address = 'iloveadaptive.com';
    this.$store.siteHref = `http://${address}/`;
    this.$store.email = `hello@${address}`;
    this.$store.version = 'v1.27.0';
    this.$store.date = '22.11.2021';

    this.$store.customFrameId = 'custom';

    this.$store.extensionId = 'dpfgmabfakicdmcijnddgbelfhfkddnb';
    this.$store.extensionLink = `https://chrome.google.com/webstore/detail/iloveadaptive/${this.$store.extensionId}/`;

    this.checkExtension();

    this.$store.isTouch = !!('ontouchstart' in window || navigator.msMaxTouchPoints);
    document.body.classList.add(this.$store.isTouch ? 'touch' : 'no-touch');
  },
  methods: {
    checkExtension() {
      try {
        chrome.runtime.sendMessage(this.$store.extensionId, { message: 'check installation' }, (reply) => {
          if (reply) {
            if (reply.status) {
              if (reply.status === 'ok') {
                this.$store.commit('SET_HAS_EXTENSION', true);
              }
            }
          } else {
            this.$store.commit('SET_HAS_EXTENSION', false);
          }
        })
      } catch (e) {
        //console.error(e);
        this.$store.commit('SET_HAS_EXTENSION', window.location.host === 'localhost:3010');
      }
    }
  }
}).$mount('#app');

Vue.use(VueWait);

if (__DEV__) {
  // Remove productionTip
  Vue.config.productionTip = false;

  /**
   * See: https://github.com/glenjamin/webpack-hot-middleware/issues/43
   */
  if (module.hot) {
    // TODO: module hot replace need more
    module.hot.accept();
  }
}
