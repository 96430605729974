import * as viewportSize from './viewportSize'

/**
 *
 * @returns {number} - Width of browser scrollbar
 */
export const getCurrentScrollWidth = function getScrollWidth() {
  return (viewportSize.getWidth() - document.body.getBoundingClientRect().width)
}

// export const getScrollWidth = function getScrollWidth() {
//   document.body.style.minHeight = '101vh'
//   const scrollbarWidth = viewportSize.getWidth() - document.body.getBoundingClientRect().width
//   document.body.style.minHeight = ''
//   return scrollbarWidth
// }

/**
 * Create pseudo link and click to download file
 * @param downloadSrc - file name
 * @param href - url to download
 */
export const clickToDownload = function clickToDownload(downloadSrc, href) {
  let link = document.createElement('a')

  link.target = '_blank'
  link.download = downloadSrc
  link.href = href

  document.body.appendChild(link)
  link.setAttribute('type', 'hidden') // make it hidden if needed

  link.click()
  document.body.removeChild(link)
}

/**
 * Check if url is localhost or loopback
 * @param url {string} - url string
 * @returns {boolean}
 */
export const isLocalhost = function (url) {
  return /^(http(s*):\/\/)?localhost(:[0-9]+)?|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*\:)*?:?0*1/.test(url)
}
