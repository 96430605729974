<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  .layout(v-loading.fullscreen.lock="loading")
    .vue-views(v-if='!loading')
      transition(name='fade', mode='out-in')
        //-
          keep-alive(v-if='$route.meta.keepAlive')
            router-view
          router-view(v-else='')
        keep-alive
          router-view
</template>

<script>
  import VHeader from '../../components/VHeader'
  import VSpinner from '../../components/VSpinner'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapState(['errors'])
    },
    watch: {
      errors() {
        const error = this.errors[this.errors.length - 1]

        console.log(error);

        let errorTitle = '';
        if (error.code) errorTitle = `${this.$t('errorTitle')} ${error.code}`;

        let errorMessage = this.$t('errorMessage');
        if (!this.$route.name.startsWith('urls-compare')) errorMessage += `: ${this.$store.state.testHref[0]}`;

        this.$notify({
          customClass: 'error',
          title: errorTitle,
          message: errorMessage,
          duration: 0
        })
      }
    },
    components: {
      VHeader,
      VSpinner
    },
    mounted() {
      setTimeout(() => {
        this.loading = false
      }, 300)
    }
  }
</script>

<style src="./MainLayout.scss" lang="scss">
</style>
