const pages = {
  index: {
    name: 'home',
    en: {
      title: 'Online service for testing responsive web sites',
      description: 'The online service allows you to check the site for adaptability. Test responsive versions of the site on all popular mobile devices and operating systems'
    },
    ru: {
      title: 'Онлайн сервис проверки и тестирования сайта на адаптивность',
      description: 'Онлайн сервис позволяет проверить сайт на адаптивность. Протестируйте адаптивные версии сайта на всех популярных мобильных устройствах и операционных системах'
    }
  },
  url: {
    name: 'frames',
    en: {
      title: 'Online service for testing responsive web sites',
      description: 'The online service allows you to check the site for adaptability. Test responsive versions of the site on all popular mobile devices and operating systems'
    },
    ru: {
      title: 'Онлайн сервис проверки и тестирования сайта на адаптивность',
      description: 'Онлайн сервис позволяет проверить сайт на адаптивность. Протестируйте адаптивные версии сайта на всех популярных мобильных устройствах и операционных системах'
    },
    pathPostfix: ':url?',
    pathToRegexpOptions: { delimiter: '/' }
  },

  ///////////////////
  'compare-urls': {
    name: 'urls-compare',
    en: {
      title: 'Compare urls',
      description: 'Compare urls in parallel frames'
    },
    ru: {
      title: 'Сравнение сайтов',
      description: 'Сравнение сайтов в параллельных фреймах'
    }
  },
  ///////////////////

  compare: {
    en: {
      title: 'Compare with design',
      description: 'Comparison of site and your design'
    },
    ru: {
      title: 'Сравнение с дизайном',
      description: 'Сравнение вёрстки сайта и макета дизайна сайта'
    },
    pathPostfix: ':url?'
  },
  'iphone-displays': {
    name: 'devices',
    en: {
      title: 'iPhone displays',
      description: 'Comparison chart for iPhone screen sizes in different units (points, pixels and physical dimensions)'
    },
    ru: {
      title: 'Сравнение размеров экранов iPhone',
      description: 'Таблица сравнения размеров экранов iPhone в разных единицах измерения (точки, пиксели и физические размеры)'
    },
    pathToRegexpOptions: { strict: true }
  },
  'browser-rating': {
    name: 'stats-browsers',
    en: {
      title: 'Browser usage rating',
      titlePostfix: ' — Worldwide',
      description: 'Browser usage statistics by countries and regions'
    },
    ru: {
      title: 'Рейтинг браузеров',
      titlePostfix: ' — Весь мир',
      description: 'Статистика использования браузеров по странам и регионам'
    },
    alias: 'stats-browsers',
    items: [
      {
        path: 'russian-federation',
        en: 'Russia',
        ru: 'Россия'
      },
      {
        path: 'ukraine',
        en: 'Ukraine',
        ru: 'Украина'
      },
      {
        path: 'belarus',
        en: 'Belarus',
        ru: 'Беларусь'
      },
      {
        path: 'united-states-of-america',
        en: 'United States of America',
        ru: 'США'
      },
      {
        path: 'india',
        en: 'India',
        ru: 'Индия'
      },
      {
        path: 'kazakhstan',
        en: 'Kazakhstan',
        ru: 'Казахстан'
      },
      {
        path: 'germany',
        en: 'Germany',
        ru: 'Германия'
      },
      {
        path: 'china',
        en: 'China',
        ru: 'Китай'
      },
      {
        path: 'africa',
        en: 'Africa',
        ru: 'Африка'
      },
      {
        path: 'asia',
        en: 'Asia',
        ru: 'Азия'
      },
      {
        path: 'europe',
        en: 'Europe',
        ru: 'Европа'
      },
      {
        path: 'north-america',
        en: 'North America',
        ru: 'Северная Америка'
      },
      {
        path: 'oceania',
        en: 'Oceania',
        ru: 'Океания'
      },
      {
        path: 'south-america',
        en: 'South America',
        ru: 'Южная Америка'
      }
    ]
  },
  'stats-resolutions': {
    en: {
      title: 'Resolutions statistics',
      description: 'Browser screen resolution usage statistics by countries and regions'
    },
    ru: {
      title: 'Статистика разрешений экранов',
      description: 'Статистика использования разрешений экранов браузеров по странам и регионам'
    }
  },
  updates: {
    en: {
      title: 'What\'s new',
      description: 'List and description of site versions'
    },
    ru: {
      title: 'Обновления',
      description: 'Список и описание версий сайта'
    },
    pathToRegexpOptions: { strict: true }
  }
};

module.exports = pages;
