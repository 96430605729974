<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.href-input
    +e.FORM.form(action="/", @submit.prevent="submitForm", novalidate)
      +e.input-group
        +e.INPUT.input-group-field(
          name="testHref",
          type="url",
          :value="testHref",
          :placeholder="$t('typeUrl')",
          ref="hrefInput"
        )
        transition(name="fade")
          +e.history(v-if="useHistory && history.length", ref="history")
            el-dropdown(@command="handleCommand", :placement="frameInput ? 'bottom-end' : 'bottom-start'")
              el-button(type="primary")
                span {{ $t('history') }}
                i.el-icon-caret-bottom.el-icon--right
                history-icon.svg-icon
              el-dropdown-menu(slot="dropdown", ref="historyDropdown", :style="'width:' + (historyWidth || ((80 + 492) + 'px'))", class="history-dropdown")
                +e.history-clean
                  el-button(type="primary", icon="el-icon-delete", @click="clearHistory", :title="$t('historyClear')")
                    | {{ $t('historyClear') }}
                +e.dropdown-inner
                  transition-group(name="list", tag="div")
                    el-dropdown-item(v-for="(item, index) in history", :key="`dropdown-item${index}`", :command="item")
                      span {{ item }}
                      i.el-icon-close(@click="removeHistoryItem(item, $event)", :title="$t('historyItemClear')")
        +e.input-group-button
          +e.button-group
            button(type="submit")
              //-enter-key-old.svg-icon
              enter-key.svg-icon
</template>

<script>
  import { mapState } from 'vuex'
  //import enterKeyOld from './assets/enter-key-old.svg'
  import historyIcon from './assets/clock.svg'
  import enterKey from './assets/enter-key.svg'

  export default {
    props: {
      num: {
        type: Number,
        default: 0
      },
      frameInput: {
        type: Boolean,
        default: false
      },
      useHistory: {
        type: Boolean,
        default: true
      },
      historyWidth: {
        type: String
      }
    },
    components: {
      //enterKeyOld,
      historyIcon,
      enterKey
    },
    mounted() {},
    computed: {
      testHref: {
        get() {
          if (this.$route.name.startsWith('urls-compare') && !this.frameInput && this.num === 0) return '';

          return this.$store.state.testHref[this.num]
        },
        set(value) {
          this.dispatchSetTestHref(value)
        }
      },
      ...mapState([
        'errors',
        'history'
      ])
    },
    methods: {
      checkCompare(value) {
        return this.$route.name.startsWith('compare') ? {
          value,
          page: 'compare'
        } : {
          value
        };
      },
      dispatchSetTestHref(e) {
        if (e.target.value) {
          const options = this.checkCompare(e.target.value)
          options.num = this.num
          this.$store.dispatch('setTestHref', options).catch(e => console.log(e))
        }
      },
      submitForm(e) {
        const form = e.target
        if (form.elements['testHref'].value) {
          const options = this.checkCompare(form.elements['testHref'].value)
          options.num = this.num
          if (this.frameInput) {
            options.page = 'compare-urls'
          }
          this.$store.dispatch('setTestHref', options).catch(e => console.log(e))
        }
      },
      handleCommand(item) {
        const options = this.checkCompare(item)
        options.num = this.num
        if (this.frameInput) {
          options.page = 'compare-urls'
        }
        this.$store.dispatch('setTestHref', options).catch(e => console.log(e))
      },
      removeHistoryItem(item, event) {
        event.stopPropagation()
        this.$store.dispatch('removeFromHistory', item)
      },
      clearHistory() {
        this.$store.dispatch('clearHistory')
      },
      calcInputWidth() {
        const inputWidth = parseFloat(getComputedStyle(this.$refs.hrefInput).width)
        const historyWidth = parseFloat(getComputedStyle(this.$refs.history).width)
        return inputWidth + historyWidth
      },
      setDropdownWidth() {
        this.$refs.historyDropdown.$el.style.width = this.calcInputWidth() + 'px'
      }
    }
  }
</script>

<style lang="scss">
  .href-input {
    border: 3px solid $color-primary;

    &__input-group {
      display: flex;

      &-field {
        margin: 0 !important;
        border: none;
        &:focus{
          border: none;
          background: lighten(#eff5f5, 3%);
        }
      }
    }

    &__history {
      .el-dropdown {
        height: 100%;

        >button {
          height: 100%;
          background-color: #eff5f5;
          border: none;

          color: #7c8989;
          font-size: 12px;
          font-weight: 300;
          line-height: 12px;
          &:focus,
          &:hover{
            color: darken(#7c8989, 10%);
          }

          .el-icon-caret-bottom {
            &:before {
              content: '';
              display: block;
              width: 7px;
              height: 5px;
              background-image: url('./assets/arr.png');
            }
          }
          svg{
            display: none;
          }
          @media (max-width: 576px - 1) {
            padding-left: 7px;
            padding-right: 7px;
          }
        }
      }

      &-clean {
        text-align: right;
        padding-right: rem-calc(10);

        .el-button {
          padding: rem-calc(8);
        }
      }
    }

    &__button-group {
      height: 100%;

      button {
        position: relative;
        display: block;
        width: 50px;
        height: calc(100% + 6px);
        border: none;
        background-color: $color-primary;
        font-size: 0;
        cursor: pointer;
        transition: background .25s ease-in-out;
        margin: -3px -3px -3px 0;
        &:focus,
        &:hover{
          background: darken($color-primary, 5%);
        }

        .svg-icon {
          @include absolute-center();
          color: white;
          //width: rem-calc(18);
          transition: color .25s ease-in-out;
        }
      }
    }


    .input-group-button {
      .button,
      .button-group {
        height: 100%;
      }
    }
  }

  .history-dropdown{
    &.el-dropdown-menu {
      max-height: rem-calc(408);
      width: rem-calc(400);
      max-width: 100%;
      overflow-y: auto !important;

      > .el-button {
        margin-left: rem-calc(10);
      }
    }

    .el-dropdown-menu__item {
      position: relative;
      padding: rem-calc(8 40 8 10);
      line-height: 1.2;

      span {
        display: block;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }

      .el-icon-close {
        @include vertical-center();
        right: rem-calc(10);
        font-size: 10px;
        color: $alert-color;
      }
    }
  }
</style>
