<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.social-share(:class="{'is-init': init}")
    +e.icon
      like.svg-icon
    +e.title {{ $t('title') }}
    +e.message {{ $t('letsShare') }}
    +e.buttons
      div(id="my-share", data-services="vkontakte,facebook,gplus" data-counter="")
  //-div(id="my-share", data-services="vkontakte,facebook,gplus", data-counter="", v-else)
</template>

<script>
  import vkIcon from './assets/vk.svg'
  import fbIcon from './assets/fb.svg'
  import gpIcon from './assets/gplus.svg'
  import like from './assets/like.svg'

  export default {
    data() {
      return {
        posLeft: 0,
        init: false
      }
    },
    components: {
      vkIcon,
      fbIcon,
      gpIcon,
      like
    },
    mounted() {
      const myShare = document.getElementById('my-share')

      if (window.Ya && Ya.share2) {
        Ya.share2(myShare, {
          content: {
            url: 'http://iloveadaptive.com',
            title: document.title,
            image: 'http://iloveadaptive.com/opengraphv2.png'
          },
          hooks: {
            onready: () => {
              this.init = true
              this.$emit('init')
            }
          },
          theme: {
            counter: true
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  $bg-color: #f8fbfb;

  .social-share {
    visibility: hidden;
    display: flex;
    padding-bottom: rem-calc(36);
    flex-direction: column;
    align-items: center;
    background-color: $bg-color;
    margin: rem-calc(34) 0 0;
    user-select: none;

    &.is-init {
      visibility: visible;
    }

    &__icon {
      position: relative;
      @include square(rem-calc(68));
      margin-top: rem-calc(-34);
      background-color: $bg-color;
      border-radius: 50%;

      .svg-icon {
        @include absolute-center();
        @include square(rem-calc(32));
      }
    }

    &__title {
      margin-bottom: rem-calc(3);
      color: #3f4444;
      font-size: rem-calc(18);
      font-weight: 700;
    }

    &__message {
      color: #676e6e;
      font-size: rem-calc(14);
      font-weight: 300;
    }

    &__buttons {
      margin-top: rem-calc(22);
    }

    &__button {
      height: rem-calc(24);
      padding: rem-calc(2);
      cursor: pointer;
      background-color: #cfd1d1;
      line-height: rem-calc(20);
      color: $white;
      border-radius: 3px;

      & + & {
        margin-left: rem-calc(4);
      }
    }

    &__btn-icon {
      display: inline-block;
      padding-right: rem-calc(4);
      padding-left: rem-calc(2);
      vertical-align: middle;

      .svg-icon {
        display: block;
        fill: currentColor;
        vertical-align: middle;
      }
    }
    &__btn-count {
      display: inline-block;
      padding-left: rem-calc(8);
      padding-right: rem-calc(6);
      border-left: 1px solid #d7dada;
      font-size: 10px;
    }
  }
</style>

<style lang="scss">
  .ya-share2__link{
    &:focus,
    &:hover{
      .ya-share2__badge{
        background: darken(#cfd1d1, 10%) !important;
      }
    }
  }
  .ya-share2__badge {
    background-color: #cfd1d1 !important;
    transition: background .25s ease-in-out;
  }
</style>
