export default class Storage {
  constructor(key) {
    this.STORAGE_KEY = key
  }

  fetch(defaultData = '[]') {
    const data = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || defaultData)

    if (Array.isArray(data)) data.reverse()

    return data
  }

  save(val) {
    return localStorage.setItem(this.STORAGE_KEY, JSON.stringify(val))
  }
}
