<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../../../pug/_index.pug

  +b.recursive-dummy
    +e.inner
      +e.top
        +e.image
      +e.try(:title="$t('try')")
      +e.bottom
        +e.no {{ $t('no') }}
        +e.text {{ $t('text') }}
        +e.BUTTON.link(type="button", @click="changeToDefaultHref") {{ $t('link') }}
</template>

<script>
  export default {
    props: {
      num: {
        type: Number,
        default: 0
      }
    },
    methods: {
      changeToDefaultHref() {
        const value = 'https://www.whatsapp.com/';
        const options = {
          value,
          num: this.num
        };
        if (this.$route.name.startsWith('compare')) {
          options.page = 'compare';
        }
        this.$store.dispatch('setTestHref', options).catch(e => console.log(e))
      }
    }
  }
</script>

<style lang="scss">
  .recursive-dummy{
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    position: relative;
    &::before{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 2px;
      margin-left: -1px;
      height: 50%;
      background: #83d636;
    }

    &__inner{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      background: #fff;
      position: relative;
      z-index: 1;
      padding: 50px 0;
      width: 417px;
      max-width: 100%;
    }
    &__top{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &__image{
      width: 29%;
      padding-bottom: 29%;
      background: url('./assets/top-image.png') no-repeat;
      background-size: contain;
    }
    &__try{
      margin-top: 12%;
      padding-bottom: 70%;
      width: 70%;
      max-width: 417px;
      background: url('./assets/try.png') no-repeat;
      background-size: contain;
      position: relative;
    }
    &__bottom{
      width: 100%;
      max-width: 250px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
    }
    &__no{
      width: 100%;
      font-size: 36px;
      font-weight: 900;
    }
    &__text{
      width: 100%;
      font-size: 24px;
      margin-top: 12px;
    }
    &__link{
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      text-decoration: underline;
      margin-top: 22px;
      &:hover,
      &:focus{
        text-decoration: none;
      }
    }
  }
</style>
