<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  -
    var devices = [
      {type: 'phone', title: 'Phone'},
      {type: 'tablet', title: 'Tablet'},
      // {type: 'laptop', title: 'Laptop'},
      {type: 'desktop', title: 'Desktop'}
    ];

    var systems = [
      {type: 'iOS', title: 'iOS'},
      {type: 'Android', title: 'Android'},
      {type: 'Windows', title: 'Windows'}
    ]

  +b.ASIDE.toolbar
    +e.logo
      site-logo(:isLink="false", :color="'#fff'", @click="dropState")

    +b.toolbar-updates(:class="$route.name.startsWith('updates') ? 'current' : ''")
      +e.A.a(:href="$store.hrefPrefix + '/updates/'")
        +e.label {{ $store.date }}
        +e.text {{ $t('updates') }}

    +e.filter(v-if="$route.name.startsWith('frames') || $route.name.startsWith('compare') || $route.name.startsWith('urls-compare')")
      +e.SECTION.section._device(v-if="$route.name.startsWith('frames')")
        +e.section-title {{ $t('devices') }}
        +e.section-btns
          each device in devices
            button(
            @click="setCurrentDeviceType('" + device.type + "')",
            v-bind:class="{'is-active': currentDeviceType === '" + device.type + "'}",
            v-bind:title="$t('" + device.type + "')")
              case device.type
                when 'phone'
                  iphone-icon.icon-phone.svg-icon
                when 'tablet'
                  ipad-icon.icon-tablet.svg-icon
                when 'laptop'
                  laptop-icon.icon-laptop.svg-icon
                when 'desktop'
                  computer-icon.icon-desktop.svg-icon
            = ' '

      +e.SECTION.section._os(v-if="$route.name.startsWith('frames')")
        +e.section-title {{ $t('systems') }}
        +e.section-btns
          each os in systems
            button(
                  @click="setCurrentOs('" + os.type + "', $event)",
                  v-bind:class=`{'is-active': includes(currentOS, '${os.type}'), 'is-disabled': includes(['laptop', 'desktop'], currentDeviceType)}`,
                  title=os.title
                )
              el-tooltip(content="Недоступно для ПК и ноутбуков", placement="top", :disabled=`!includes(['laptop', 'desktop'], currentDeviceType)`)
                case os.type
                  when 'iOS'
                    iosicon.icon-ios
                  when 'Android'
                    androidicon.icon-android
                  when 'Windows'
                    windowsicon.icon-windows
            = ' '

      +e.SECTION.section._settings
        +e.section-title(v-if="$route.name.startsWith('frames')") {{ $t('settings') }}
        +e.section-list
          +e.UL.section-list-ul
            +e.section-list-li(v-if="$route.name.startsWith('frames') || $route.name.startsWith('urls-compare')")
              button(type="button", @click="changeOrientation") {{ $t('rotate') }}

            //-+e.section-list-li
              button Сменить тему

            +e.section-list-li(v-if="$route.name.startsWith('frames') && includes(currentOS, 'iOS')") {{ $t('iOSPanel') }}:
              el-select(v-model="panelMode")
                el-option(v-for="(item, index) in panelModes", value="index", :key="index", :label="$t('panel-' + item)", :value="index")

            +e.section-list-li._custom(v-if="$route.name.startsWith('frames')", :class="customHeightToggled ? 'active' : ''")
              button(type="button", @click="toggleCustomHeight")
                | {{ $t('customHeight') }}
                //-+e.badge._new new
              +e.INPUT.custom(v-if="customHeightToggled", type="text", ref="customHeight", v-model="customHeight", maxlength="5")

            +e.section-list-li
              button(type="button", @click="toggleScrolling")
                span(v-html="$store.state.scrolling === 'yes' ? $t('scrollingDisable') : $t('scrollingEnable')")

      //-.toolbar__section
        .toolbar__section-title Скриншоты всех устройств
        button.button.tiny(@click="makeShots") генерировать
        = ' '
        v-loading-heart(v-bind:width="'16'", v-bind:height="'16'", v-if="$isLoading('create shots'  + this._uid)")

      +e.SECTION.section._zoom
        +e.section-title
          | {{ $t('zoom') }}
          +e.INPUT.text-input(@input="zoomInput", @focus="zoomInputFocus", @blur="zoomInputBlur", :ref="'zoomInputEl'", maxlength="3")
          | %
        el-slider(v-model="zoom", :min="0.3", :max="1", :step="0.01", :show-tooltip="false", show-stops, @change="zoomSliderChange")

    +e.SECTION.section._links
      +e.link
        +e.link-icon
          chrome-icon.icon-mail
        +e.link-text
          a(:href="$store.extensionLink", target="_blank") {{ $t('extensionText') }}
      +e.link
        +e.link-icon
          email-icon.icon-mail
        +e.link-text
          a(:href="'mailto:' + $store.email") {{ $store.email }}

    +e.SECTION.section._contacts
      +e.contacts
        //-+e.contacts-slider(@mouseenter="stopSlider", @mouseleave="startSlider")
        +e.contacts-slider
          //-+e.contacts-slider-slide(v-for="(locale, index) in ['en', 'ru']", :class="{ current: index === 0 }")
            +e.contacts-slider-top
              +e.contacts-slider-image
                img(src="./assets/wand.png", alt="")
              +e.contacts-slider-title {{ $t('contactTitle', locale) }}
              +e.contacts-slider-text {{ $t('contactText', locale) }}
            +e.contacts-slider-bottom
              +e.A.contacts-slider-button.btn.btn-outline-primary(@click="showPopup") {{ $t('contactButtonText', locale) }}

          +e.contacts-slider-slide.current
            +e.contacts-slider-top
              +e.contacts-slider-image
                wandImage
                //-img(src="./assets/wand.svg", alt="")
              +e.contacts-slider-title {{ $t('contactTitle') }}
              +e.contacts-slider-text {{ $t('contactText') }}
            +e.contacts-slider-bottom
              +e.A.contacts-slider-button.btn.btn-outline-primary(@click="showPopup") {{ $t('contactButtonText') }}

        .popup-wrap(v-if="visible")
          .popup-bg(@click="hidePopup")
          .popup-container
            .popup-content
              +b.popup(v-if="!contactsFormSent")
                +e.inner
                  +e.title {{ $t('contactButtonText') }}

                  +e.content
                    +b.FORM.form(action="#", method="post", @submit="contactsFormSubmit", :class="{ disabled: contactsFormSending }")
                      +e.form-group.form-group
                        +e.label.sr-only {{ $t('contactsInputName') }}
                        +e.INPUT.input.form-control(
                        type='text',
                        :placeholder="$t('contactsInputName')",
                        v-model="contactsName",
                        @input="contactsInputValidate('name')",
                        :class="{ 'is-invalid': contactsFormValidation.name }")
                      +e.form-group.form-group
                        +e.label.sr-only {{ $t('contactsInputEmail') }}
                        +e.INPUT.input.form-control(
                        type='text',
                        :placeholder="$t('contactsInputEmail')",
                        v-model="contactsEmail",
                        @input="contactsInputValidate('email')",
                        :class="{ 'is-invalid': contactsFormValidation.email }")
                        +e.label.is-invalid(v-if="contactsFormValidation.email === 'email'") {{ $t('contactsInputEmailError') }}
                      +e.form-group.form-group
                        +e.label.sr-only {{ $t('contactsInputServices') }}
                        +e.INPUT.input.form-control(
                        type='text',
                        :placeholder="$t('contactsInputServices')",
                        v-model="contactsServices",
                        @input="contactsInputValidate('services')",
                        :class="{ 'is-invalid': contactsFormValidation.services }")
                      +e.form-group.form-group
                        +e.label.sr-only {{ $t('contactsInputMessage') }}
                        +e.TEXTAREA.input.form-control(
                        :placeholder="$t('contactsInputMessage')",
                        v-model="contactsMessage",
                        @input="contactsInputValidate('message')",
                        :class="{ 'is-invalid': contactsFormValidation.message }")
                      +e.form-group.form-group
                        +e.BUTTON.button.btn.btn-primary(type="submit") {{ $t('contactsFormSubmit') }}

              +b.popup.center(v-if="contactsFormSent", @click="hidePopup")
                +e.content
                  +e.icon
                    check-circle
                  +e.title {{ $t('contactsFormThank') }}
                  +e.text {{ $t('contactsFormThankText') }}
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import SiteLogo from '@/components/Vlogo'

  // icons
  import iosicon from './assets/003-apple.svg'
  import androidicon from './assets/004-android-logo.svg'
  import windowsicon from './assets/005-windows-logo-silhouette.svg'
  import ipadIcon from './assets/tablet.svg'
  import iphoneIcon from './assets/mobile.svg'
  import laptopIcon from './assets/laptop.svg'
  import computerIcon from './assets/desktop.svg'
  import EmailIcon from './assets/email.svg'
  import chromeIcon from './assets/chrome.svg'
  import checkCircle from './assets/check-circle.svg'
  import wandImage from './assets/wand.svg'

  export default {
    data() {
      return {
        customHeightToggled: false,
        customHeight: 3000,

        sliderTimeout: null,
        currentSlide: 0,
        contactsFormValidation: {
          name: false,
          email: false,
          services: false,
          message: false
        },
        contactsName: '',
        contactsEmail: '',
        contactsServices: '',
        contactsMessage: '',
        contactsFormSent: false,
        contactsFormSending: false,
        visible: false,
        shotsStatus: null,
        zoomInputFocused: false
      }
    },
    components: {
      iosicon,
      androidicon,
      windowsicon,
      ipadIcon,
      iphoneIcon,
      laptopIcon,
      computerIcon,
      EmailIcon,
      chromeIcon,
      checkCircle,
      SiteLogo,
      wandImage
    },
    watch: {
      customHeight() {
        this.$store.commit('customFramesHeight', this.customHeight);
      },

      $route(to) {
        if (to.name === 'compare') {
          this.$store.commit('TOGGLE_SCROLLING', 'yes')
        }
      }
    },
    computed: {
      // zoomValue: {
      //   get() {
      //     return parseInt(this.zoom * 100)
      //   },
      //   set(value) {
      //     this.$store.commit('SET_ZOOM', value / 100)
      //   }
      // },
      currentOS: {
        get() {
          return this.$store.state.currentOS
        }
      },
      currentDeviceType: {
        get() {
          return this.$store.state.currentDeviceType
        }
      },
      zoom: {
        get() {
          return this.$store.state.zoom
        },
        set(value) {
          this.$refs.zoomInputEl.style.width = value >= 1 ? '18px' : '12px';
          if (!this.zoomInputFocused) this.$refs.zoomInputEl.value = parseInt(value * 100);
          this.$store.commit('SET_ZOOM', value)
        }
      },
      panelMode: {
        get() {
          const panelTranslated = this.$t('panel-' + this.$store.state.panelModes[this.$store.state.panelMode])
          return panelTranslated
        },
        set(value) {
          this.$store.dispatch('updatePanelMode', value)
        }
      },
      ...mapState([
        'panelModes'
      ])
    },
    methods: {
      zoomInput(event) {
        this.$store.commit('SET_ZOOM', parseInt(event.target.value) / 100)
      },
      zoomInputFocus() {
        this.zoomInputFocused = true;
      },
      zoomInputBlur(event) {
        this.zoomInputFocused = false;
        const value = Number.isNaN(event.target.value) ? 0 : event.target.value;
        event.target.value = Math.min(Math.max(value, 30), 100);
        this.$store.commit('SET_ZOOM', parseInt(value) / 100)
      },
      zoomSliderChange(event) {
        //this.$refs.zoomInputEl.value = parseInt(event * 100);
      },

      includes(collection, value) {
        const isIncluded = collection.includes(value)
        return isIncluded
      },
      showPopup() {
        document.documentElement.classList.add('overflow-hidden')
        this.visible = true
        setTimeout(() => {
          document.querySelector('.popup-wrap').classList.add('popup-show')
        }, 10)
      },
      hidePopup() {
        document.querySelector('.popup-wrap').classList.add('popup-hide')
        setTimeout(() => {
          document.documentElement.classList.remove('overflow-hidden')
          this.visible = false
          this.contactsFormSent = false
        }, 250)
      },
      contactsInputValidate(type) {
        let isValid = true

        switch (type) {
          case 'name':
            if (this.contactsName === '') {
              this.contactsFormValidation[type] = true
              isValid = false
            } else {
              this.contactsFormValidation[type] = false
            }
            break

          case 'email':
            if (this.contactsEmail !== '') {
              const emailRegExp = new RegExp('^([a-z0-9]+[._-]?)+([a-z0-9]+[_]?)*@([a-z0-9]+[-]?)+(\\.[a-z0-9]+)*(\\.[a-z]{2,3})$', 'i')
              if (!emailRegExp.test(this.contactsEmail)) {
                this.contactsFormValidation[type] = 'email'
                isValid = false
              } else {
                this.contactsFormValidation[type] = false
              }
            } else {
              this.contactsFormValidation[type] = true
              isValid = false
            }
            break

          case 'services':
            if (this.contactsServices === '') {
              this.contactsFormValidation[type] = true
              isValid = false
            } else {
              this.contactsFormValidation[type] = false
            }
            break

          case 'message':
            if (this.contactsMessage === '') {
              this.contactsFormValidation[type] = true
              isValid = false
            } else {
              this.contactsFormValidation[type] = false
            }
            break
        }

        return isValid
      },
      contactsFormValidate() {
        let isValid = true

        for (let prop in this.contactsFormValidation) {
          if (!this.contactsInputValidate(prop)) isValid = false
        }

        return isValid
      },
      contactsFormSubmit(event) {
        event.preventDefault()

        if (!this.contactsFormValidate()) {
          setTimeout(() => {
            this.$el.querySelector('input.is-invalid').focus()
          })
          return
        }

        this.contactsFormSending = true
        axios.post(`/api/web/contacts-form`, {
          name: this.contactsName,
          email: this.contactsEmail,
          services: this.contactsServices,
          message: this.contactsMessage
        })
          .then(response => {
            this.contactsFormSending = false
            this.contactsName = ''
            this.contactsEmail = ''
            this.contactsServices = ''
            this.contactsMessage = ''

            if (response.data.status === 'error') {
              console.log('error')
            } else if (response.data.status === 'success') {
              console.log('success')
              this.contactsFormSent = true
            }
          })
      },
      changeOrientation() {
        this.$store.commit('CHANGE_ORIENTATION');
      },
      toggleScrolling() {
        this.$store.commit('TOGGLE_SCROLLING');
      },
      setCurrentOs(os, event) {
        /*
        if (os === 'Android' || os === 'iOS') {
          this.$store.commit('SET_CURRENT_DEVICE_TYPE', 'all')
        }
        */

        if (event) {
          let button = event.currentTarget.closest('button')

          if (button && button.classList.contains('is-disabled')) {
            return
          }
        }
        this.$store.commit('SET_CURRENT_OS', os)
      },
      setCurrentDeviceType(type) {
        if (type === 'desktop' || type === 'laptop') {
          this.$store.commit('DISABLE_CURRENT_OS', 'Android');
          this.$store.commit('DISABLE_CURRENT_OS', 'iOS');
          this.$store.commit('SET_CURRENT_OS', ['Windows']);
        } else if (type === 'tablet' || type === 'phone') {
          if (this.$store.state.currentOS.includes('Windows')) {
            this.$store.commit('SET_CURRENT_OS', ['iOS']);
          }
        }
        this.$store.commit('SET_CURRENT_DEVICE_TYPE', type);
        this.toggleCustomHeight(null, false);
      },
      // makeShots() {
      //   this.$startLoading('create shots' + this._uid)
      //
      //   const zipId = new Date().getTime() + '_' + new Date().toISOString()
      //     .replace('T', '_') // replace T with a space
      //     .replace(/:/g, '-')
      //     .replace(/\..+/, '')
      //
      //   this.loadScreenshots(zipId)
      // },
      // loadScreenshots(zipId) {
      //   axios.get(`/api/phantom/shot/all?url=${this.$store.state.testHref[0]}&id=${zipId}&status=${this.$data.shotsStatus}`)
      //     .then(response => {
      //
      //       this.$data.shotsStatus = response.data.status
      //
      //       if (response.data.status === 'done') {
      //         let link = document.createElement('a')
      //
      //         link.target = '_blank'
      //         link.download = response.data.name
      //         link.href = response.data.url
      //
      //         document.body.appendChild(link)
      //         link.setAttribute('type', 'hidden') // make it hidden if needed
      //
      //         link.click()
      //
      //         this.$data.shotsStatus = null
      //
      //         this.$endLoading('create shots' + this._uid)
      //       } else {
      //         setTimeout(function () {
      //           this.loadScreenshots(zipId)
      //         }.bind(this), 1000)
      //       }
      //     })
      //     .catch(e => {
      //       console.warn(e.message)
      //     })
      // },
      dropState() {
        this.$store.dispatch('dropState')
        this.$emit('dropState')
      },
      // startSlider() {
      //   const slides = document.querySelectorAll('.toolbar__contacts-slider-slide')
      //   this.sliderTimeout = setTimeout(() => {
      //     const currentSlide = slides[this.currentSlide]
      //     this.currentSlide += 1
      //     if (this.currentSlide === slides.length) this.currentSlide = 0
      //     const nextSlide = slides[this.currentSlide]
      //
      //     currentSlide.classList.add('removing')
      //     currentSlide.classList.remove('current')
      //     nextSlide.classList.add('active')
      //     setTimeout(() => {
      //       currentSlide.classList.remove('removing')
      //       nextSlide.classList.add('current')
      //       nextSlide.classList.remove('active')
      //       this.startSlider()
      //     }, 1400 + 125)
      //   }, 5500)
      // },
      // stopSlider() {
      //   clearTimeout(this.sliderTimeout)
      // }

      toggleCustomHeight(event, toggle) {
        this.customHeightToggled = typeof toggle === 'undefined' ? !this.customHeightToggled : toggle;
        if (this.customHeightToggled) {
          this.$store.commit('customFramesHeight', this.customHeight);
          this.$nextTick(() => this.$refs.customHeight.focus());
        }
        this.$store.commit('customFramesHeightToggled', this.customHeightToggled);
      }
    },
    mounted() {
      // this.startSlider()
      if (this.$refs.zoomInputEl) {
        this.$refs.zoomInputEl.value = parseInt(this.zoom * 100);
      }
      const zoomSlider = document.querySelector('.toolbar__filter .el-slider');
      if (zoomSlider) {
        zoomSlider.addEventListener('mousedown', () => {
          this.$refs.zoomInputEl.blur();
        })
      }
    }
  }
</script>

<style lang="scss">
  $separate-color: rgba(#393e3e, .5);
  $padding-x: rem-calc(22);

  .toolbar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #294665;
    color: #fff;

    &__logo {
      padding-top: rem-calc(22);
      //padding-bottom: rem-calc(24);
      //border-bottom: 1px solid $separate-color;
      text-align: center;

      .logo-img {
        width: 90px;
        height: 70px;
      }
      .cls-1 {
        fill: $color-primary;
      }
    }

    &-updates{
      padding: 0 $padding-x rem-calc(20);
      border-bottom: 1px solid $separate-color;
      &.current{
        pointer-events: none;
        .toolbar-updates__a{
          color: $color-primary;
        }
      }
      &__a{
        color: #fff;
        //text-transform: uppercase;
        font-size: 11px;
        display: inline-block;
        margin-left: 7px; //20px;
        &:hover,
        &:focus{
          .toolbar-updates__text {
            text-decoration: underline;
          }
        }
      }
      &__label{
        color: #80d823;
        margin-right: 5px;
      }
    }

    [type="range"] {
      width: 100%;
    }

    &__section {
      $section: &;

      padding: rem-calc(20) $padding-x;
      border-bottom: 1px solid $separate-color;

      &-title {
        margin-bottom: rem-calc(14);
        font-size: rem-calc(10);
        font-weight: 700;
        text-transform: uppercase;
      }

      [class*="icon-"] {
        color: rgba(#cddede, .24);
        fill: currentColor;
        transition: color .25s ease-in-out;
      }

      &_device {
        button  {
          [class*="icon-"] {
            color: #fff;
          }
        }
      }
      &_os {
        button{
          &:focus,
          &:hover{
            [class*="icon-"]{
              color: #fff;
            }
          }
        }
        button.is-active  {
          [class*="icon-"] {
            color: #fff;
          }
        }

        .icon-ios {
          width: 16px;
        }
        .icon-android {
          width: 20px;
        }
        .icon-windows {
          width: 23px;
        }
      }

      &_device {
        button{
          &:focus,
          &:hover{
            [class*="icon-"]{
              color: rgba(#fff, .6);
            }
          }
        }

        button.is-active  {
          [class*="icon-"] {
            color: $color-primary;
          }
        }

        .icon-phone {
          width: 11px;
        }

        .icon-tablet {
          width: 16px;
        }

        .icon-laptop {
          width: 32px;
        }

        .icon-desktop {
          width: 32px;
        }
      }
      &-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        #{$section}_device & {
          align-items: flex-end;
        }
      }

      &_links{
        padding: 0;
        margin-top: 10px;
        border-bottom: none;
      }

      &_contacts{
        padding: 0;
        border-bottom: none;
      }

      button {
        display: inline-block;
        cursor: pointer;
        outline: none;
        transition: opacity .2s linear;
        vertical-align: middle;
        svg{
          outline: none !important;
        }

        &.is-disabled {
          opacity: .2;
          [class*="icon-"] {
            color: rgba(#cddede, .24);
            fill: currentColor;
          }
        }
        &[title='Windows']{
          visibility: hidden;
          pointer-events: none;
        }
      }
    }

    &__section-list {
      &-ul {
        @include list-unstyled();
      }

      &-li {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: rem-calc(20);
        margin-bottom: rem-calc(5);
        line-height: rem-calc(15);
        font-size: rem-calc(10);
        text-transform: uppercase;
        color: rgba(#e6ecec, .67);
        height: 22px;

        &.active{
          color: #fff;
          &::before{
            opacity: 1;
          }
          .toolbar__badge{
            display: none;
          }
        }

        @include before() {
          left: 0;
          width: 15px;
          height: 15px;
          background-image: url('./assets/settings.png');
          opacity: 0.2;
          transition: opacity .25s ease-in-out;
        }

        button {
          position: relative;
          font-size: inherit;
          color: inherit;
          text-transform: uppercase;
          transition: color .25s ease-in-out;
          &:hover,
          &:focus{
            color: #fff;
          }
        }
      }
    }

    &__badge{
      position: absolute;
      top: -6px;
      margin-left: 3px;
      left: 100%;
      color: $alert-color;
      font-size: 8px;
      letter-spacing: .1em;
    }

    &__custom{
      margin: 0 0 0 10px;
      padding: 0;
      height: 22px;
      width: 50px;
      text-align: center;
      border: 1px solid #c9d9bf;
      box-shadow: none;
      font-size: 13px;
      &:hover,
      &:focus{
        border-color: #80d823;
      }
    }

    &__link {
      margin-top: 10px;
      padding-left: rem-calc(20);

      &-icon,
      &-text {
        display: inline-block;
        vertical-align: middle;
      }
      &-icon {
        @include square(rem-calc(16));
        margin-right: 5px;

        .icon-mail {
          display: inline-block;
          vertical-align: top;
          fill: rgba(#e6ecec, .67);
        }
      }

      &-text {
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;

        a {
          color: rgba(#e6ecec, .67);

          &:hover {
            color: $color-primary;
          }
        }
      }
    }

    .btn-outline-primary{
      &:focus,
      &:hover{
        color: #294665;
      }
    }

    .el-input{
      .el-input__inner{
        transition: color .25s ease-in-out;
      }
      &:hover{
        .el-input__inner{
          color: #fff;
        }
      }
    }

    &__text-input{
      background: none;
      color: #fff;
      border: none;
      border-bottom: 1px solid transparent;
      padding: 0;
      margin: 0 0 0 4px;
      text-transform: uppercase;
      font-size: .625rem;
      font-weight: 700;
      display: inline-block;
      height: 15px;
      width: 12px;
      &:focus{
        border: none;
        border-bottom: 1px solid #fff;
        background: none;
        box-shadow: none;
      }
    }
  }
</style>

<style lang="scss">
  .toolbar {
    &__section-list {
      &-li {
        white-space: nowrap;
        .el-select {
          display: inline-block;
          width: 80px; //55px;
          margin-left: 5px;
          top: -1px;
        }
        .el-input {
          display: inline-block;
          width: 100%;

          .el-input__icon {
            width: 7px;
            height: 5px;
            background-image: url('./assets/arr.png');
            transform: translateY(-50%) rotate(0);

            &.is-reverse {
              transform: translateY(-50%) rotate(180deg);
            }

            &:before,
            &:after {
              display: none;
            }
          }
          .el-input__inner {
            display: inline-block;
            width: 100%;
            height: auto;
            padding: 0 15px 0 0;
            background-color: transparent;
            border: none;
            color: inherit;
            line-height: rem-calc(15);
            font-size: rem-calc(10);
            text-transform: uppercase;
            box-shadow: none;
          }
        }
      }
    }
    .el-slider__runway {
      margin: 5px 0;
    }

    &__contacts{
      margin-top: 1.25rem;
      border-top: 1px solid rgba(57,62,62,.5);
      padding: 1.25rem 0;
      text-align: center;
      &-slider{
        position: relative;
        overflow: hidden;
        min-height: 215px;
        &-slide{
          padding-top: 16px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          pointer-events: none;
          &.active,
          &.current{
            pointer-events: auto;
            .toolbar__contacts-slider{
              &-top{
                transform: translateX(0) rotate(0);
              }
              &-bottom{
                transform: translateX(0);
              }
            }
          }
          &.removing{
            .toolbar__contacts-slider{
              &-top{
                transform: translateX(-100%) rotate(-7deg);
              }
              &-bottom{
                transform: translateX(-100%);
              }
            }
          }
          &.active,
          &.current{
            .toolbar__contacts-slider{
              &-top{
                opacity: 1;
              }
              &-bottom{
                opacity: 1;
              }
            }
          }
        }
        &-title{
          color: #d3dee8;
          text-transform: uppercase;
          font-size: 12px;
          margin-top: 10px;
        }
        &-text{
          font-size: 12px;
          color: #8395a4;
          margin-top: 8px;
        }
        &-top{
          transform-origin: top center;
          transform: translateX(100%) rotate(7deg);
        }
        &-top,
        &-bottom{
          padding: 0 22px;
          opacity: 0;
          transition-property: opacity, transform;
          transition-duration: 1.4s, .5s;
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        &-bottom{
          transition-delay: .125s;
          transition-duration: 1.4s, .55s;
          transform: translateX(100%);
        }
        &-image{
          color: #697e92;
          svg{
            fill: currentColor;
          }
        }
        &-button{
          margin-top: 1rem;
          width: 116px;
        }
      }
    }
  }

  .popup{
    opacity: 0;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    max-width: 376px;
    background: #fff;
    margin: 0 auto;
    position: relative;
    color: #3f4444;
    box-shadow: 55px 55px 38px 0 rgba(#3a4246, .1);
    transform: translateY(20px) perspective(600px) rotateX(10deg);
    transition-property: transform, opacity;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;
    &.center{
      height: 100vh;
      max-height: 536px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-wrap{
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1210;
      overflow: hidden auto;
      position: fixed;
      &.popup-show{
        .popup{
          opacity: 1;
          transform: translateY(0) perspective(600px) rotateX(0deg);
        }
      }
      &.popup-hide{
        .popup{
          opacity: 0;
          transform: translateY(20px) perspective(600px) rotateX(10deg);
        }
      }
    }
    &-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1199;
      opacity: .85;
      background: #242424;
      transition: opacity .25s ease-in-out;
    }
    &-container{
      text-align: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      &:before{
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }
    &-content{
      width: 100%;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin: 0 auto;
      z-index: 1201;
      pointer-events: none;
      > *{
        pointer-events: auto;
      }
    }

    &__inner{
      overflow: auto;
      max-height: 100vh;
      text-align: left;
      padding: 2.5rem 24px 3.25rem;
    }
    &__title{
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 24px;
    }
    &__icon{
      svg{
        fill: #e3eded;
      }
    }
    &__text{
      opacity: .6;
    }

    .form{
      &.disabled{
        opacity: .65;
        pointer-events: none;
      }
      &__label{
        margin-top: -1rem;
        padding-top: 3px;
        margin-bottom: 1rem;
        &.is-invalid{
          color: $alert-color;
        }
      }
      &__input{
        border-radius: 0;
        background: #fff;
        border: 1px solid #e7f1f1;
        padding: 15px;
        min-height: 50px;
        box-shadow: none;
        &.is-invalid{
          border-color: $alert-color;
        }
        &:focus{
          border-color: darken(#e7f1f1, 15%);
        }
      }
      &__button{
        width: 100%;
        height: 50px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
      }
    }
    textarea.form__input{
      min-height: 102px;
    }
  }

  .overflow-hidden{
    overflow: hidden;
    body{
      overflow: auto;
      height: 100vh;
    }
  }

  @media (min-width: 576px){
    .popup{
      min-height: 0;
      &-container{
        padding: 0 6px;
      }
      &__inner{
        padding-left: 56px;
        padding-right: 56px;
      }
    }
  }
</style>
