import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=0233c08b&lang=pug&"
import script from "./Stats.vue?vue&type=script&lang=js&"
export * from "./Stats.vue?vue&type=script&lang=js&"
import style0 from "./Stats.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Filoveadaptive%2Fdata%2Fwww%2Filoveadaptive.ru%2Fsource%2Fsrc%2Fcomponents%2FVStats%2FStats.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports