<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.footer
    +e.container
      +e.row
        +e.left
          +e.extension
            +b.footer-extension
              +e.left
                site-logo
                +e.title
                  chrome-icon(class="svg-icon", style="width: 36px")
                  strong {{ $t('extensionTitleBold') }}
                  = ' {{ $t(\'extensionTitle\') }}'

                +e.A.link(:href="$store.extensionLink" target="_blank")
                  external-link
                  span {{ $t('extensionLink') }}
              +e.right
                +e.text {{ $t('extensionText') }}
                +e.bottom(v-html="$t('extensionBottom')")

        +e.right
          site-navigation(:lang="false")

          +e.email
            a(:href="'mailto:' + $store.email") {{ $store.email }}

          +e.updates
            +e.A.updates-link(:href="$store.hrefPrefix + '/updates/'")
              +e.SPAN.updates-top {{ $store.date }}
              +e.SPAN.updates-bottom {{ $t('updates') }}
</template>

<script>
  import SiteLogo from '@/components/Vlogo'
  import SiteNavigation from '@/components/VNavigation'

  import chromeIcon from './assets/chrome.svg'
  import externalLink from './assets/external-link.svg'

  export default {
    components: {
      SiteLogo,
      SiteNavigation,

      chromeIcon,
      externalLink
    }
  }
</script>

<style lang="scss">
  @import './styles.scss';
</style>
