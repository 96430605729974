<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.compare
    +e.upload(v-show="!previewImage")
      +e.INPUT.input(type="file", ref="fileInput", @change="uploadImage", accept='image/png,image/jpeg', :data-upload-placeholder="$t('dragHere')")
      +e.center
        +e.text {{ $t('dragHere') }}
        +e.BUTTON.button.btn.btn-primary(type="button", @click="inputClick") {{ $t('uploadImage') }}
        +e.message.error(v-if="errorFormat") {{ $t('errorFormat') }}

    +e.preview(v-show="previewImage", :style="{width: imageWidth * zoom + 'px', minWidth: 201 * zoom + 'px'}")
      +e.preview-top
        +e.preview-left
          +e.preview-path(@click="inputClick", :title="fileName") {{ $t('imageTitle') }}
          +e.preview-sizes(:title="$t('imageSizes')") {{ imageWidth }} &times; {{ imageHeight }}
        +e.preview-right
          +e.BUTTON.preview-clear(@click="clearImage", :title="$t('clearButton')")
            .el-icon-close

      +e.preview-image-wrapper(@click="resetImagePosition", :title="$t('resetImagePosition')")
        +e.preview-image-dragger(:title="$t('imageDragTitle')", v-show="previewImage")
          img.compare__preview-image(
          :src="previewImage",
          alt="",
          ref="previewImage",
          :title="$t('uploadNewImage')",
          :style="{height: imageHeight * zoom + 'px'}")
          //-@click="inputClick",
          //-:style="{transform: 'scale(' + zoom + ')'}"
</template>

<script>
  export default {
    data() {
      return {
        fileName: '',
        previewImage: '',
        imageWidth: 576,
        imageHeight: 0,
        errorFormat: false,
        previewImageDrag: false,
        previewImageDragStartX: 0,
        previewImageDragStartY: 0,
        previewImageDragX: 0,
        previewImageDragY: 0,
        minImageSize: 300
      }
    },

    props: {
      zoom: {
        type: Number,
        'default': 1
      }
    },

    mounted() {
      this.initialScrolling = true;
      this.$store.commit('CHANGE_ORIENTATION', 'portrait');
      this.$store.commit('SET_ZOOM', 0.7);

      const layout = document.querySelector('.layout');
      const dragHere =  this.$t('dragHere');

      const compareImage = document.querySelector('.compare__preview-image-dragger');

      document.body.addEventListener('dragenter', event => {
        event.preventDefault();
        event.stopPropagation();
        if (this.$route.name.startsWith('compare')) {
          // if (this.previewImageDrag || event.target === compareImage) {
          //   this.imageStartDrag(event);
          //   return;
          // }
          document.body.classList.add('dragenter');
          layout.dataset.uploadPlaceholder = dragHere;
        }
      });
      document.body.addEventListener('dragleave', event => {
        event.preventDefault();
        event.stopPropagation();
        if (this.$route.name.startsWith('compare')) {
          // if (this.previewImageDrag) {
          //   this.imageDrag(event);
          //   return;
          // }
          document.body.classList.remove('dragenter');
        }
      });
      document.body.addEventListener('dragover', event => {
        event.preventDefault();
        event.stopPropagation();
        if (this.$route.name.startsWith('compare')) {
          // if (this.previewImageDrag) {
          //   this.imageDrag(event);
          //   return;
          // }
          document.body.classList.add('dragenter');
          layout.dataset.uploadPlaceholder = dragHere;
        }
      });
      document.body.addEventListener('drop', event => {
        event.preventDefault();
        event.stopPropagation();

        if (this.$route.name.startsWith('compare')) {
          // if (this.previewImageDrag) {
          //   this.imageStopDrag(event);
          //   return;
          // }
          document.body.classList.remove('dragenter');

          const dt = event.dataTransfer;
          const files = dt.files;

          this.uploadImage(event, files);
        }
      });

      document.body.addEventListener('mousedown', event => {
        if (this.previewImageDrag || event.target === compareImage) {
          this.imageStartDrag(event);
          return;
        }
      });
      document.body.addEventListener('mousemove', event => {
        if (this.previewImageDrag) {
          this.imageDrag(event);
          return;
        }
      });
      document.body.addEventListener('mouseup', event => {
        if (this.previewImageDrag) {
          this.imageStopDrag(event);
          return;
        }
      });

      this.$store.watch(state => {
        return state.myframes[0].size.width
      }, state => {
        this.imageWidth = state;
        this.getDeviceName();
      }, {
        deep: true
      })
    },

    methods: {
      inputClick() {
        this.$refs.fileInput.click();
      },

      getDeviceName() {
        const nameEl = document.querySelector('.my-frame__name');
        if (!nameEl) return;

        nameEl.style.display = 'block';
        const frameId = parseFloat(this.$store.state.framesMap.find(frame => {
          const frameWidth = parseInt(frame.width);
          return frameWidth === this.imageWidth;
        }));

        const frameTitle = frameId ? this.$store.state.myframes.find(frame => {
          return frameId.id === frame.id;
        }).title : ''; //this && this.$t('customSize')

        if (frameTitle) {
          nameEl.setAttribute('title', frameTitle);
          //nameEl.textContent = frameTitle;
        }
      },

      uploadImage(event, files) {
        this.errorFormat = false;
        this.$store.commit('compareUploaded', false);

        const input = this.$refs.fileInput;
        files = files || input.files;

        if (files && files[0]) {
          const reader = new FileReader();

          reader.onload = readerEvent => {
            if (files[0].type !== 'image/png' && files[0].type !== 'image/jpeg') {
              this.errorFormat = true;
            } else {
              this.fileName = files[0].name;
              this.previewImage = readerEvent.target.result;
              document.querySelector('.compare__preview-image-dragger').style.top = '';

              setTimeout(() => {
                this.getImgSize(() => {
                  this.$store.commit('customWidth', this.imageWidth);
                  this.$store.commit('customHeight', this.imageHeight);

                  //this.getDeviceName();

                  setTimeout(() => {
                    this.$store.commit('compareUploaded', true);

                    setTimeout(() => {
                      if (this.initialScrolling) {
                        this.initialScrolling = false;
                        this.$store.commit('TOGGLE_SCROLLING', 'yes');
                        const frameWrap = document.querySelector('.my-frame__frame-wrap');
                        const frame = document.querySelector('.my-frame__iframe');
                        frameWrap.insertBefore(frame, document.querySelector('.my-frame__resizer_vertical'));
                      }

                      const input = document.querySelector('.my-frame__custom-name .el-input__inner');
                      const testDiv = document.createElement('div');
                      testDiv.style.fontSize = '16px';
                      testDiv.style.fontWeight = '700';
                      testDiv.style.position = 'absolute';
                      testDiv.style.top = '-9999px';
                      testDiv.textContent = input.value;
                      document.body.appendChild(testDiv);
                      const inputWidth = testDiv.offsetWidth;
                      testDiv.remove();
                      input.style.width = `${inputWidth}px`;

                      const maxWidth =
                        (document.querySelector('.my-frames__header').offsetWidth
                          - 52 //left padding
                          - (10 * 4) //frames margin
                          - (10 * 4)) //compare frame padding
                        / 2;
                      const zoom = parseFloat(Math.max(Math.min(maxWidth / this.imageWidth, 1), 0.3).toFixed(2));
                      this.$store.commit('SET_ZOOM', zoom);
                    });
                  });
                });
              });
            }
          };

          reader.readAsDataURL(files[0]);
        }
      },

      getImgSize(callback) {
        const newImg = new Image();

        newImg.onload = () => {
          this.imageWidth = Math.max(newImg.width, this.minImageSize);
          this.imageHeight = Math.max(newImg.height, this.minImageSize);
          callback();
        }

        newImg.src = this.previewImage;
      },

      clearImage() {
        this.previewImage = '';
        this.$store.commit('compareUploaded', false)
      },

      imageStartDrag(event) {
        const image = document.querySelector('.compare__preview-image-dragger');

        this.previewImageDrag = true;
        this.previewImageDragStartY = event.clientY - image.getBoundingClientRect().top;
        //this.previewImageDragStartX = event.clientX - image.getBoundingClientRect().left;
      },
      imageDrag(event) {
        const image = document.querySelector('.compare__preview-image-dragger');
        const imageWrapper = document.querySelector('.compare__preview-image-wrapper');

        image.style.top = `${event.clientY - imageWrapper.getBoundingClientRect().top - this.previewImageDragStartY}px`;
        //image.style.left = `${event.clientX - imageWrapper.getBoundingClientRect().left - this.previewImageDragStartX}px`;
      },
      imageStopDrag() {
        this.previewImageDrag = false;

        const image = document.querySelector('.compare__preview-image-dragger');
        const top = parseInt(image.style.top);
        if (Math.abs(top) <= 10) {
          image.style.top = '0px';
        }
      },
      resetImagePosition(event) {
        const imageWrapper = document.querySelector('.compare__preview-image-wrapper');
        if (event.target === imageWrapper) {
          const image = document.querySelector('.compare__preview-image-dragger');
          image.style.top = '0px';
        }
      }
    }
  }
</script>

<style lang="scss">
  .page-compare{
    .toolbar__section-list-ul,
    .toolbar__section-list-li{
      margin: 0;
    }
    .my-frames__frame{
      margin-bottom: 0;
    }
    .my-frame__resizer_vertical{
      min-width: 100%;
      max-width: 100%;
    }
    /*#customWidth{
      pointer-events: none;
      padding: 0;
      border: none;
      width: 30px;
    }*/
  }
  body.dragenter{
    .layout {
      &::before {
        content: ''; //attr(data-upload-placeholder);
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: rgba(#000, .8);
        background: rgba(#fff, .6);
        border: 4px dashed rgba(#000, .2);
        z-index: 1200;
        pointer-events: none;
      }
    }
  }

  .compare{
    padding-left: 10px;
    min-height: calc(100vh - #{113px + 167 + 15 + 34});
    height: 100%;
    display: flex;
    &__upload{
      height: 594px; //calc(100vh - #{113px + 15 + 25});
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 4px dashed rgba(#000, .1);
    }
    &__center{
      text-align: center;
      padding: 2rem 0;
    }
    &__text{
      font-size: 32px;
      z-index: 1201;
      position: relative;
    }
    &__button{
      margin-top: 1rem;
    }
    &__message{
      margin-top: 20px;
      &.error{
        color: $alert-color;
      }
    }
    &__input{
      display: none;
    }

    &__preview{
      margin: rem-calc(0 10 25 + 21);
    }
    &__preview-top{
      display: flex;
      justify-content: space-between;
      height: 42px;
      padding-bottom: rem-calc(22);
    }
    &__preview-left{
      max-width: calc(100% - 30px);
      display: flex;
      align-items: baseline;
    }
    &__preview-path{
      cursor: pointer;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      max-height: 25px;
      font-size: 16px;
      line-height: 25px;
      color: #3f4444;
      font-weight: 700;
      border-bottom: 1px dashed currentColor;
      margin-right: .75rem;
      transition: border-color .25s ease-in-out;
      &:hover,
      &:focus{
        border-bottom: 1px dashed transparent;
      }
    }
    &__preview-sizes{
      color: rgba(38,38,38,.67);
      font-size: 11px;
      font-weight: 300;
      line-height: 12px;
      white-space: nowrap;
    }
    &__preview-clear{
      transition: color .25s;
      &:hover,
      &:focus{
        color: $alert-color;
      }
    }
    &__preview-image-wrapper{
      position: relative;
      overflow: hidden;
      height: calc(100% - 42px);
      width: 100%;
      cursor: pointer;
      border: 1px solid #e7f1f1;
    }
    &__preview-image-dragger{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      cursor: move;
      user-select: none;
      box-shadow: 0 3px 8px 0 rgba(#000, .2);
      &::after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__preview-image{
      user-select: none;
      max-width: none;
    }
  }
</style>
