import { render, staticRenderFns } from "./PageSpeed.vue?vue&type=template&id=22bb1c16&scoped=true&lang=pug&"
import script from "./PageSpeed.vue?vue&type=script&lang=js&"
export * from "./PageSpeed.vue?vue&type=script&lang=js&"
import style0 from "./PageSpeed.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./PageSpeed.vue?vue&type=style&index=1&id=22bb1c16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22bb1c16",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Filoveadaptive%2Fdata%2Fwww%2Filoveadaptive.ru%2Fsource%2Fsrc%2Fcomponents%2Fvalidators%2FVPageSpeed%2FPageSpeed.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports