<i18n src="./locales.json"></i18n>

<template>
  <div class="dummy">
    <div class="dummy__icon"></div>
    <div class="dummy__title">{{ $t('accessBlocked') }}</div>
    <div class="dummy__message" v-html="$t('invitation')"></div>
    <div class="dummy__extension-link">
      <a :href="$store.extensionLink" target="_blank">
        <chrome-icon class="svg-icon" style="width: 16px"></chrome-icon>
        {{ $t('download') }}
      </a>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import chromeIcon from '@/assets/svg/chrome.svg'

  export default {
    name: 'dummy',
    components: {
      chromeIcon
    }
  }
</script>

<style lang="scss" scoped>
  .dummy {
    $text-color: #8f9c9c;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //height: 100%;
    color: $text-color;
    text-align: center;
    flex: 1 0 auto;
    user-select: none;

    &__icon {
      @include size(rem-calc(48));
      background-image: url('./assets/blocked.png');
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    &__title {
      margin-top: rem-calc(13);
      margin-bottom: rem-calc(8);
      font-size: rem-calc(18);
      font-weight: 500;
      line-height: 1.1;
    }

    &__message {
      margin-bottom: rem-calc(13);
      font-size: rem-calc(12);
    }


    &__extension-link {
      a {
        display: inline-block;
        padding: rem-calc(6 10);
        border: 1px solid #cad5d5;
        border-radius: 5px;
        color: #879595;
        transition: border-color .2s linear;

        .svg-icon {
          margin-top: -2px;
          vertical-align: middle;
        }

        @include hover-focus() {
          border-color: $color-primary;
        }
      }
    }
  }
</style>
