<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  -
    const devices = [
      {
        title: '<span class="new">iPhone 12 Pro Max</span>',
        class: 'iphone-12-pro-max',
        size: '6.7',
        points: ['428', '926'],
        pixels: ['1284', '2778'],
        image: 'iphone12Max',
        render: '3',
        show: '458',
        new: false
      },
      {
        title: '<span class="new">iPhone 12, 12 Pro</span>',
        class: 'iphone-12',
        size: '6.1',
        points: ['390', '844'],
        pixels: ['1170', '2532'],
        image: 'iphone12',
        render: '3',
        show: '460',
        new: false
      },
      {
        title: '<span class="new">iPhone 12 Mini</span>',
        class: 'iphone-12-mini',
        size: '5.4',
        points: ['360', '780'],
        pixels: ['1080', '2340'],
        image: 'iphone12Mini',
        render: '3',
        show: '476',
        new: false
      },
      {
        title: 'iPhone Xs Max, 11 Pro Max',
        class: 'iphone-xs-max',
        size: '6.5',
        points: ['414', '896'],
        pixels: ['1242', '2688'],
        image: 'iphoneXsMax',
        render: '3',
        show: '458',
        new: false
      },
      {
        title: 'iPhone Xr, 11',
        class: 'iphone-xr',
        size: '6.1',
        points: ['414', '896'],
        pixels: ['828', '1792'],
        image: 'iphoneXr',
        render: '2',
        show: '326',
        new: false
      },
      {
        title: 'iPhone 11 Pro, X, Xs',
        class: 'iphone-x',
        size: '5.8',
        points: ['375', '812'],
        pixels: ['1125', '2436'],
        image: 'iphoneX',
        render: '3',
        show: '458'
      },
      {
        title: 'iPhone 8+, 7+, 6+, 6s+',
        class: 'iphone-6-plus',
        size: '5.5',
        points: ['414', '736'],
        pixels: ['1242', '2208'],
        image: 'iphone6Plus',
        render: '3',
        show: '401'
      },
      {
        title: '<span class="new">iPhone SE 2</span>, 8, 7, 6, 6s',
        class: 'iphone-6',
        size: '4.7',
        points: ['375', '667'],
        pixels: ['750', '1334'],
        image: 'iphone6',
        render: '2',
        show: '326'
      },
      {
        title: 'iPhone SE, 5, 5s, 5c',
        class: 'iphone-5',
        size: '4',
        points: ['320', '568'],
        pixels: ['640', '1136'],
        image: 'iphone5',
        render: '2',
        show: '326'
      },
      {
        title: 'iPhone 4, 4s',
        class: 'iphone-4',
        size: '3.5',
        points: ['320', '480'],
        pixels: ['640', '960'],
        image: 'iphone4',
        render: '2',
        show: '326'
      },
      {
        title: 'iPhone 3GS, 3G, 2G',
        class: 'iphone-2',
        size: '3.5',
        points: ['320', '480'],
        pixels: ['320', '480'],
        image: 'iphone2',
        render: '1',
        show: '163'
      }
    ]

  .my-frames
    .my-frames__container
      .my-frames__frames-wrap
        +b.devices
          //-+e.header
            h1 {{ $t('title') }}

          //-+e.TABLE.table
            +e.THEAD.thead
              +e.TR.row
                +e.TH.header-cell.desc &nbsp;
                each val in devices
                  +e.TH.header-cell(class=val.class)= val.title

            +e.TBODY.tbody
              each val in [1]
                +e.TR.row
                  +e.TD.cell.desc points
                  each val in devices
                    +e.TD.cell(class=val.class)= val.title

          +e.table.-new
            +e.cell.-desc
              +e.item.-header
              +e.item.-points
                +e.inner
                  +e.title {{ $t('pointsTitle') }}
                  +e.text(v-html="$t('pointsText')")
              +e.item.-pixels
                +e.inner
                  +e.title {{ $t('pixelsTitle') }}
                  +e.text(v-html="$t('pixelsText')")
              +e.item.-physical
                +e.inner
                  +e.title {{ $t('physicalTitle') }}
                  +e.text(v-html="$t('physicalText')")

            each val in devices.slice(0, 8)
              +e.cell(class=`-${val.class}`)
                +e.item.-header
                  +e.image-wrapper.-header
                    +e.image
                      #{val.image}
                    +e.size.-header= `${val.size}″`
                  +e.header-title(class=(val.new ? '-new' : ''))
                    != val.title
                    if val.new
                      +e.badge new

                +e.item.-points
                  +e.image-wrapper.-points
                    +e.image
                      #{`${val.image}Points`}
                    +e.size.-points
                      +e.size-text= `${val.points[0]} × ${val.points[1]}`
                      +e.comment ({{ $t('points') }})

                +e.item.-pixels
                  +e.image-wrapper.-pixels
                    +e.image
                      #{`${val.image}Pixels`}
                    +e.size.-pixels
                      +e.size-text= `${val.pixels[0]} × ${val.pixels[1]}`
                      +e.comment ({{ $t('pixels') }})
                  +e.arrow
                    +e.arrow-text
                      | {{ $t('renderAt') }}
                      = ` ${val.render}×`
                    +e.arrow-pointer

                +e.item.-physical
                  +e.image-wrapper.-physical
                    +e.image
                      #{`${val.image}Physical`}
                    +e.size.-physical= `${val.size}″`
                  +e.arrow
                    +e.arrow-text
                      | {{ $t('showAt') }}
                      = ` ${val.show} `
                      +e.SPAN.ppi(:title="$t('ppi')") PPI
                    +e.arrow-pointer

          +e.table.-old
            +e.cell.-desc
              +e.item.-header
              +e.item.-points
                +e.inner
                  +e.title {{ $t('pointsTitle') }}
                  +e.text(v-html="$t('pointsText')")
              +e.item.-pixels
                +e.inner
                  +e.title {{ $t('pixelsTitle') }}
                  +e.text(v-html="$t('pixelsText')")
              +e.item.-physical
                +e.inner
                  +e.title {{ $t('physicalTitle') }}
                  +e.text(v-html="$t('physicalText')")

            each val in devices.slice(8)
              +e.cell(class=`-${val.class}`)
                +e.item.-header
                  +e.image-wrapper.-header
                    +e.image
                      #{val.image}
                    +e.size.-header= `${val.size}″`
                  +e.header-title(class=(val.new ? '-new' : ''))
                    != val.title
                    if val.new
                      +e.badge new

                +e.item.-points
                  +e.image-wrapper.-points
                    +e.image
                      #{`${val.image}Points`}
                    +e.size.-points
                      +e.size-text= `${val.points[0]} × ${val.points[1]}`
                      +e.comment ({{ $t('points') }})

                +e.item.-pixels
                  +e.image-wrapper.-pixels
                    +e.image
                      #{`${val.image}Pixels`}
                    +e.size.-pixels
                      +e.size-text= `${val.pixels[0]} × ${val.pixels[1]}`
                      +e.comment ({{ $t('pixels') }})
                  +e.arrow
                    +e.arrow-text
                      | {{ $t('renderAt') }}
                      = ` ${val.render}×`
                    +e.arrow-pointer

                +e.item.-physical
                  +e.image-wrapper.-physical
                    +e.image
                      #{`${val.image}Physical`}
                    +e.size.-physical= `${val.size}″`
                  +e.arrow
                    +e.arrow-text
                      | {{ $t('showAt') }}
                      = ` ${val.show} `
                      +e.SPAN.ppi(:title="$t('ppi')") PPI
                    +e.arrow-pointer
</template>

<script>
  import iphone2 from './assets/iphone-2.svg'
  import iphone2Points from './assets/iphone-2-points.svg'
  import iphone2Pixels from './assets/iphone-2-pixels.svg'
  import iphone2Physical from './assets/iphone-2-physical.svg'

  import iphone4 from './assets/iphone-4.svg'
  import iphone4Points from './assets/iphone-4-points.svg'
  import iphone4Pixels from './assets/iphone-4-pixels.svg'
  import iphone4Physical from './assets/iphone-4-physical.svg'

  import iphone5 from './assets/iphone-5.svg'
  import iphone5Points from './assets/iphone-5-points.svg'
  import iphone5Pixels from './assets/iphone-5-pixels.svg'
  import iphone5Physical from './assets/iphone-5-physical.svg'

  import iphone6 from './assets/iphone-6.svg'
  import iphone6Points from './assets/iphone-6-points.svg'
  import iphone6Pixels from './assets/iphone-6-pixels.svg'
  import iphone6Physical from './assets/iphone-6-physical.svg'

  import iphone6Plus from './assets/iphone-6-plus.svg'
  import iphone6PlusPoints from './assets/iphone-6-plus-points.svg'
  import iphone6PlusPixels from './assets/iphone-6-plus-pixels.svg'
  import iphone6PlusPhysical from './assets/iphone-6-plus-physical.svg'

  import iphoneX from './assets/iphone-x.svg'
  import iphoneXPoints from './assets/iphone-x-points.svg'
  import iphoneXPixels from './assets/iphone-x-pixels.svg'
  import iphoneXPhysical from './assets/iphone-x-physical.svg'

  import iphoneXr from './assets/iphone-xr.svg'
  import iphoneXrPoints from './assets/iphone-xr-points.svg'
  import iphoneXrPixels from './assets/iphone-xr-pixels.svg'
  import iphoneXrPhysical from './assets/iphone-xr-physical.svg'

  import iphoneXsMax from './assets/iphone-xs-max.svg'
  import iphoneXsMaxPoints from './assets/iphone-xs-max-points.svg'
  import iphoneXsMaxPixels from './assets/iphone-xs-max-pixels.svg'
  import iphoneXsMaxPhysical from './assets/iphone-xs-max-physical.svg'

  import iphone12Mini from './assets/iphone-12-mini.svg'
  import iphone12MiniPoints from './assets/iphone-12-mini-points.svg'
  import iphone12MiniPixels from './assets/iphone-12-mini-pixels.svg'
  import iphone12MiniPhysical from './assets/iphone-12-mini-physical.svg'

  import iphone12 from './assets/iphone-12.svg'
  import iphone12Points from './assets/iphone-12-points.svg'
  import iphone12Pixels from './assets/iphone-12-pixels.svg'
  import iphone12Physical from './assets/iphone-12-physical.svg'

  import iphone12Max from './assets/iphone-12-max.svg'
  import iphone12MaxPoints from './assets/iphone-12-max-points.svg'
  import iphone12MaxPixels from './assets/iphone-12-max-pixels.svg'
  import iphone12MaxPhysical from './assets/iphone-12-max-physical.svg'

  export default {
    components: {
      iphone2,
      iphone2Points,
      iphone2Pixels,
      iphone2Physical,
      iphone4,
      iphone4Points,
      iphone4Pixels,
      iphone4Physical,
      iphone5,
      iphone5Points,
      iphone5Pixels,
      iphone5Physical,
      iphone6,
      iphone6Points,
      iphone6Pixels,
      iphone6Physical,
      iphone6Plus,
      iphone6PlusPoints,
      iphone6PlusPixels,
      iphone6PlusPhysical,
      iphoneX,
      iphoneXPoints,
      iphoneXPixels,
      iphoneXPhysical,
      iphoneXr,
      iphoneXrPoints,
      iphoneXrPixels,
      iphoneXrPhysical,
      iphoneXsMax,
      iphoneXsMaxPoints,
      iphoneXsMaxPixels,
      iphoneXsMaxPhysical,
      iphone12Mini,
      iphone12MiniPoints,
      iphone12MiniPixels,
      iphone12MiniPhysical,
      iphone12,
      iphone12Points,
      iphone12Pixels,
      iphone12Physical,
      iphone12Max,
      iphone12MaxPoints,
      iphone12MaxPixels,
      iphone12MaxPhysical
    }
  }
</script>

<style lang="scss">
  .devices{
    width: 100%;
    padding: 0 0.625rem;

    &__header{
      display: inline-block;
      vertical-align: middle;
      h1{
        margin-top: 0;
      }
    }

    &__table{
      display: flex;
      //border-top: 1px solid #eee;
      position: relative;
      min-width: 1400px; //1583px;
    }
    &__cell{
      flex-grow: 1;
      flex-basis: 0;
      max-width: 174px;
      &.-desc{
        flex-basis: 12%;
        min-width: 190px;
        max-width: 12%;
        //border-right: 2px solid #eee;
        text-align: right;
        .devices{
          &__item{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 20px 20px 20px 0;
            /*&.-header{*/
              /*border-bottom-color: transparent;*/
            /*}*/
          }
        }
      }

      &.-iphone-xr,
      &.-iphone-6,
      &.-iphone-5,
      &.-iphone-4,
      &.-iphone-2{
        .devices__size.-pixels {
          font-size: 12px;
          .devices__comment{
            font-size: 11px;
          }
        }
      }
      &.-iphone-2{
        .devices__size.-pixels {
          text-align: left;
          margin-left: 52px;
        }
      }
      &.-iphone-xs-max,
      &.-iphone-xr,
      &.-iphone-x{
        .devices__item.-pixels {
          .devices {
            &__arrow {
              &::after {
                height: 47px;
              }
            }
            &__arrow-pointer{
              margin-top: -13px;
            }
          }
        }
      }
      &.-iphone-xr{
        .devices__item.-pixels {
          .devices {
            &__arrow {
              &::after {
                height: 44px;
              }
            }
            &__arrow-pointer {
              margin-top: -16px;
            }
          }
        }
      }

      &.-iphone-xs-max{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 64px;
          }
          &.-physical .devices__arrow::before{
            height: 64px;
          }
        }
      }
      &.-iphone-xr{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 64px;
          }
          &.-physical .devices__arrow::before{
            height: 162px;
          }
        }
      }
      &.-iphone-x{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 92px;
          }
          &.-physical .devices__arrow::before{
            height: 92px;
          }
        }
      }
      &.-iphone-6-plus{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 116px;
          }
          &.-physical .devices__arrow::before{
            height: 116px;
          }
        }
      }
      &.-iphone-6{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 138px;
          }
          &.-physical .devices__arrow::before{
            height: 212px;
          }
        }
      }
      &.-iphone-5{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 171px;
          }
          &.-physical .devices__arrow::before{
            height: 232px;
          }
        }
      }
      &.-iphone-4{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 200px;
          }
          &.-physical .devices__arrow::before{
            height: 252px;
          }
        }
      }
      &.-iphone-2{
        .devices__item {
          &.-pixels .devices__arrow::before{
            height: 200px;
          }
          &.-physical .devices__arrow::before{
            height: 304px;
          }
        }
      }
    }

    &__item{
      padding: 20px 10px;
      position: relative;
      &:not(:last-child){
        border-bottom: 1px solid #eee;
      }
      &.-header{
        height: 150px;
        //border-bottom: 1px solid #eee;
      }
      &.-points{
        height: 335px;
      }
      &.-pixels{
        height: 335px + 40;
      }
      &.-physical{
        height: 335px + 40;
      }
    }
    &__title{
      font-size: 17px;
    }
    &__text{
      font-size: 13px;
      color: #8395a4;
      margin-top: 7px;
      p{
        margin: 0;
        &:not(:last-child) {
          margin: 0 0 1rem;
        }
      }
    }
    &__image-wrapper{
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
      pointer-events: none;
      &.-header{
        top: auto;
        bottom: 40px + 12;
        .devices__image{
          transform: scale(.5);
          transform-origin: bottom center;
        }
      }
      &.-pixels,
      &.-physical{
        top: 20px + 40;
      }
    }
    &__image{
      transform: scale(.65);
      transform-origin: top center;
      color: #3f4444;
      svg{
        fill: currentColor;
        path[opacity]{
          fill: #eff5f5;
          opacity: 1 !important;
        }
      }
    }
    &__size{
      position: absolute;
      top: 32%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: rgba(38,38,38,.67);
      pointer-events: auto;
      &.-header{
        top: 75%;
        font-size: 10px;
      }
    }
    &__size-text{
      white-space: nowrap;
    }
    &__comment{
      color: rgba(#000, .85);
      font-size: 13px;
    }
    &__header-title{
      position: absolute;
      bottom: 12px + 12;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      max-width: 100%;
      font-size: 14px;
      white-space: nowrap;
      letter-spacing: -0.04em;
      &.-new{
        color: $alert-color;
      }
      span.new{
        color: $alert-color;
      }
    }
    &__badge{
      position: absolute;
      border-radius: 7px;
      font-size: 10px;
      top: -3px;
      left: 100%;
      margin-left: -2px;
      padding: 0 5px;
      line-height: 14px;
      text-transform: uppercase;
    }
    &__arrow{
      position: absolute;
      top: 20px + 1;
      left: 50%;
      transform: translateX(-50%);
      &::before,
      &::after{
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        left: 50%;
        transform: translateX(-50%);
        background: #80d823;
      }
      &::before{
        bottom: 0;
      }
      &::after{
        height: 38px;
        top: 0;
      }
    }
    &__arrow-text{
      background: #f8fbfb;
      color: #3f4444;
      border: 1px solid #80d823;
      border-radius: 5px;
      white-space: nowrap;
      text-align: center;
      font-size: 11px;
      padding: 0 12px 1px;
      position: relative;
      z-index: 1;
      line-height: 18px - 1;
    }
    &__arrow-pointer{
      position: absolute;
      top: calc(100% + 40px);
      left: 50%;
      height: 7px;
      width: 7px;
      margin-top: -22px;
      transform: rotate(-135deg);
      border-top: 2px solid #80d823;
      border-left: 2px solid #80d823;
      transform-origin: top left;
    }
  }
</style>
