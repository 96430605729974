import * as types from './mutation-types'

const mutations = {
  [types.SET_HAS_EXTENSION](state, value) {
    state.hasExtension = value
  },
  [types.SET_TEST_HREF](state, options) {
    if (typeof options.value !== 'undefined') {
      state.testHref.splice(options.num, 1, options.value)
    } else {
      state.testHref.splice(options.num, 1)
    }
  },
  [types.SET_TEST_HREF_X_FRAME_OPTIONS](state, options) {
    state.testHrefXFrameOptions.splice(options.num, 1, options.value)
  },
  [types.SET_TEST_CONTENT_SECURITY_POLICY](state, options) {
    state.testContentSecurityPolicy.splice(options.num, 1, options.value)
  },
  [types.SET_TEST_HREF_FETCHING](state, options) {
    state.testHrefFetching.splice(options.num, 1, options.value)
  },
  [types.SET_ERRORS](state, error) {
    const errors = state.errors.slice(0, state.errors.length)
    state.errors = errors.push(error) && errors
  },
  [types.SET_ZOOM](state, value) {
    state.zoom = value
  },
  [types.SET_ORIENTATION](state, value) {
    state.orientation = value
  },
  [types.CHANGE_ORIENTATION](state, value) {
    state.orientation = value || (state.orientation === 'portrait' ? 'landscape' : 'portrait')
  },
  [types.TOGGLE_SCROLLING](state, value) {
    state.scrolling = value || (state.scrolling === 'yes' ? 'no' : 'yes')
  },
  [types.SET_CURRENT_OS](state, os) {
    if (Array.isArray(os)) {
      state.currentOS = os
    } else if (typeof os === 'string') {
      const currState = state.currentOS.slice(0, state.currentOS.length)
      state.currentOS = currState.includes(os) ? currState.filter(item => item !== os) : currState.push(os) && currState
    }
  },
  [types.DISABLE_CURRENT_OS](state, os) {
    const currState = state.currentOS.slice(0, state.currentOS.length)
    state.currentOS = currState.filter(item => item === os)
  },
  [types.SET_CURRENT_DEVICE_TYPE](state, type) {
    state.currentDeviceType = type
  },
  [types.SET_PANEL_MODE](state, value) {
    state.panelMode = value
  },
  [types.SET_URLS_HISTORY](state, value) {
    state.history = value
  },
  [types.CLEAR_URLS_HISTORY](state) {
    state.history = []
  },
  [types.UPDATE_HISTORY](state, value) {
    state.history = value
  },

  customWidth(state, value) {
    const customFrame = state.myframes[state.myframes.findIndex(frame => frame.custom)]
    const parsedValue = parseInt(value)
    customFrame.size.width = Number.isNaN(parsedValue) ? 0 : parsedValue
  },
  customHeight(state, value) {
    const customFrame = state.myframes[state.myframes.findIndex(frame => frame.custom)]
    const parsedValue = parseInt(value)
    customFrame.size.height = Number.isNaN(parsedValue) ? 0 : parsedValue
  },

  customFramesHeightToggled(state, value) {
    state.customHeightToggled = value
  },
  customFramesHeight(state, value) {
    state.customHeight = parseInt(value)
    // state.myframes.forEach(frame => {
    //   frame.customHeight = parseInt(value)
    // })
  },

  compareUploaded(state, value) {
    state.compareUploaded = value
  }
}

export default mutations
