<i18n src="./locales.json"></i18n>

<template lang="pug">
  include ../../pug/_index.pug

  +b.my-frames
    +e.toolbar
      toolbar(@dropState="showPopular")
    +e.container
      +e.header
        +e.nav
          site-navigation

        +e.bottom
          +e.href-input
            href-input(ref="hrefInput")

          +e.validations(v-if="($route.name.startsWith('frames') || $route.name.startsWith('compare')) && testHref.indexOf($store.siteHref) !== 0")
            +e.validation
              w3c
            //-+e.validation
              google-lighthouse
            +e.validation
              page-speed

        +e.H1.title(v-if="!$route.name.startsWith('frames') && !$route.name.startsWith('stats-')") {{ pageTitle }}

      +e.comapre-frames(v-if="$route.name.startsWith('urls-compare')")
        compare-frames

      +e.stats(v-if="$route.name.startsWith('stats-')")
        stats

      +e.updates(v-if="$route.name.startsWith('updates')")
        updates

      +e.devices(v-if="$route.name.startsWith('devices')")
        devices

      +e.errors(v-if="errors.length && $route.name.startsWith('frames')")
        +e.error(v-for="err in errors")

      +e.filter(v-if="$route.name.startsWith('frames') || $route.name.startsWith('compare')", v-show="$route.name.startsWith('frames')")
        ffilter(
          :frames="currentFrames",
          :currentFrames="checkedFrames",
          @checkAll="showAll",
          @checkPopular="showPopular",
          @updateFrames="updateFramesFunc"
        )
      +e.frames-wrap(v-loading.lock="isLoading", v-if="$route.name.startsWith('frames') || $route.name.startsWith('compare')", :class="compareUploaded ? 'compare-uploaded' : ''")
        +e.rulers(v-if="$route.name.startsWith('compare') && compareUploaded", :class="rulersHover ? 'hover' : ''")
          +e.ruler._vertical(@mouseenter="rulersEnter", @mouseleave="rulersLeave", @mousedown="rulerVerticalStartDrag", :title="$t('rulerTitle')")
            +e.ruler-button._vertical
              +e.ruler-text {{ $t('rulerText') }}
              +e.ruler-icon
          +e.ruler._horizontal(@mouseenter="rulersEnter", @mouseleave="rulersLeave", @mousedown="rulerHorizontalStartDrag", :title="$t('rulerTitle')")
            +e.ruler-button._horizontal
              +e.ruler-text {{ $t('rulerText') }}
              +e.ruler-icon

        +e.frame(v-for="frame, index in filteredFrames", :key="index")
          my-frame(
            v-if="!isLoading",
            :testHref="testHref",
            :size="frame.size",
            :title="frame.title",
            :zoom="zoom",
            :deviceType="frame.deviceType",
            :os="frame.os",
            :newIphoneTop="frame.newIphoneTop",
            :iphone6Top="frame.iphone6Top",
            :currentHeight="frame.currentHeight || 0",
            :currentWidth="frame.currentWidth || 0",
            :new="frame.new",
            :custom="frame.custom",
            :num="0"
            :index="index"
          )
        +e.compare(v-if="$route.name.startsWith('compare')")
          compare(:zoom="zoom")
      +e.share
        social-share()
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import Ffilter from '../VFfilter'
  import MyFrame from '../VFrame'
  import Toolbar from '../VToolbar'
  import HrefInput from '../VHrefInput'
  import SiteNavigation from '../VNavigation'
  import W3c from '../validators/VW3c'
  import PageSpeed from '../validators/VPageSpeed'
  // import GoogleLighthouse from '../validators/VLighthouse'
  import SocialShare from '../VSocialShare'
  import Stats from '../VStats'
  import CompareFrames from '../VCompareFrames'
  import Compare from '../VCompare'
  import Updates from '../VUpdates'
  import Devices from '../VDevices'
  // import axios from 'axios'

  export default {
    components: {
      Ffilter,
      MyFrame,
      Toolbar,
      HrefInput,
      SiteNavigation,
      W3c,
      PageSpeed,
      // GoogleLighthouse,
      SocialShare,
      Stats,
      Updates,
      CompareFrames,
      Compare,
      Devices
    },
    data() {
      return {
        pageTitle: '',
        checkedFrames: [],
        rulersHover: false,
        rulerVerticalDrag: false,
        rulerVerticalStartPos: 0,
        rulerVerticalPos: 0,
        rulerHorizontalDrag: false,
        rulerHorizontalStartPos: 0,
        rulerHorizontalPos: 0,
        currentVerticalRuler: null,
        currentHorizontalRuler: null,
        rulerSize: 21,
        // zoomMap: {
        //   0.3: 12,
        //   0.4: 9,
        //   0.5: 5,
        //   0.6: 2,
        //   0.7: 3,
        //   0.8: 8,
        //   0.9: 12,
        //   1: 16
        // }
      }
    },
    computed: {
      compareUploaded() {
        return this.$store.state.compareUploaded;
      },
      isLoading() {
        return this.$store.state.testHrefFetching[0];
      },
      testHref: {
        get() {
          return this.$store.state.testHref[0];
        },
        set(value) {
          this.dispatchSetTestHref(value);
        }
      },
      filteredFrames() {
        var filteredFrames = this.currentFrames.filter(frame => {
          if (this.$route.name.startsWith('compare')) {
            return frame.id === this.$store.customFrameId;
          }
          return this.checkedFrames.includes(frame.id);
        })
        return filteredFrames;
      },
      ...mapGetters({
        currentFrames: 'getCurrentFrames'
      }),
      ...mapState([
        'orientation',
        'errors',
        'myframes',
        'zoom',
        'history'
      ])
    },
    created() {
      const checkedFrames = this.$route.name.startsWith('compare') ? [this.$store.customFrameId] : this.getPopularDevices();
      this.checkedFrames = checkedFrames;

      this.pageTitle = document.originalTitle;
    },
    mounted() {
      const value = this.$route.params.url;
      if (value && !this.$store.state.testHref[0]) {
        const options = {
          value,
          num: 0
        };
        if (this.$route.name.startsWith('compare')) {
          options.page = 'compare';
        }
        this.$store.dispatch('setTestHref', options).catch(e => console.log(e))
      }

      document.addEventListener('mousemove', event => {
        this.rulerVerticalMoveDrag(event);
        this.rulerHorizontalMoveDrag(event);
      });
      document.addEventListener('mouseup', event => {
        this.rulerVerticalStopDrag(event);
        this.rulerHorizontalStopDrag(event);
      });

      this.$store.watch(state => {
        return state.zoom
      }, state => {
        setTimeout(() => {
          this.staticTop = document.querySelector('.my-frame__frame-wrap').getBoundingClientRect().top - this.rulerVerticalTop;
          this.staticLeft = document.querySelector('.my-frame__frame-wrap').getBoundingClientRect().left - this.rulerHorizontalLeft;

          Array.from(document.querySelectorAll('.my-frames__ruler-line.vertical'), line => {
            this.redrawZoomVertical(line);
          });
          Array.from(document.querySelectorAll('.my-frames__ruler-line.horizontal'), line => {
            this.redrawZoomHorizontal(line);
          });
        });
      });
    },
    watch: {
      compareUploaded(newCount, oldCount) {
        if (this.$route.name.startsWith('compare')) {
          this.checkedFrames = [this.$store.customFrameId];

          if (this.compareUploaded) {
            setTimeout(() => {
              this.rulerVerticalTop = document.querySelector('.my-frames__ruler_vertical').getBoundingClientRect().top;
              this.rulerHorizontalLeft = document.querySelector('.my-frames__ruler_horizontal').getBoundingClientRect().left;
              this.staticTop = document.querySelector('.my-frame__frame-wrap').getBoundingClientRect().top - this.rulerVerticalTop;
              this.staticLeft = document.querySelector('.my-frame__frame-wrap').getBoundingClientRect().left - this.rulerHorizontalLeft;
            }, 100)
          }
        }
      },
      $route(to, from) {
        this.pageTitle = document.originalTitle;

        if (to.name === 'compare') {
          //this.$store.commit('TOGGLE_SCROLLING', 'no');
          this.$store.commit('CHANGE_ORIENTATION', 'portrait');
          this.checkedFrames = [this.$store.customFrameId];
        } else {
          this.showPopular();
        }

        if (to.name !== 'compare') {
          this.$store.commit('compareUploaded', false);
        }

        if (from.name !== 'compare') {
          this.$store.commit('compareUploaded', false);
        }
      }
    },
    methods: {
      updateFramesFunc(frames) {
        this.checkedFrames = frames;
      },

      getPopularDevices() {
        const isCustomChecked = this.checkedFrames.includes(this.$store.customFrameId);
        return this.$store.state.myframes
          .filter(frame => {
            return frame.popular || (frame.custom && isCustomChecked);
          })
          .map(frame => frame.id);
      },
      showAll(checkedAll) {
        if (checkedAll) {
          const isCustomChecked = this.checkedFrames.includes(this.$store.customFrameId);
          this.checkedFrames = this.$store.state.myframes.map(frame => {
            if (!frame.active || (frame.custom && !isCustomChecked)) return;
            return frame.id;
          });
        } else {
          this.checkedFrames = [];
        }
      },
      showPopular() {
        this.checkedFrames = this.getPopularDevices();
      },

      rulersEnter() {
        this.rulersHover = true;
      },
      rulersLeave() {
        this.rulersHover = false;
      },
      rulerVerticalStartDrag(event) {
        this.rulerVerticalDrag = true;
        this.rulerVerticalStartPos = 0;
        this.rulerVerticalPos = 0;
        document.body.classList.add('drag');

        if (event.target === document.querySelector('.my-frames__ruler_vertical')) {
          const newRuler = document.createElement('div');
          newRuler.classList.add('my-frames__ruler-line', 'vertical');
          document.querySelector('.my-frames__rulers').appendChild(newRuler);
          newRuler.addEventListener('mousedown', event => {
            this.rulerVerticalStartDrag(event);
          });

          this.currentVerticalRuler = newRuler;
        } else {
          this.currentVerticalRuler = event.target;
        }
      },
      createHorizontalRuler() {
        const newRuler = document.createElement('div');
        newRuler.classList.add('my-frames__ruler-line', 'horizontal');
        document.querySelector('.my-frames__rulers').appendChild(newRuler);
        newRuler.addEventListener('mousedown', event => {
          this.rulerHorizontalStartDrag(event);
        });
        newRuler.addEventListener('mouseover', event => {
          this.rulerHorizontalMouseover(event);
        });
        newRuler.addEventListener('mouseleave', event => {
          this.rulerHorizontalMouseleave(event);
        });
        return newRuler;
      },
      rulerHorizontalStartDrag(event) {
        this.rulerHorizontalDrag = true;
        this.rulerHorizontalStartPos = 0;
        this.rulerHorizontalPos = 0;
        document.body.classList.add('drag');

        if (event.target === document.querySelector('.my-frames__ruler_horizontal')) {
          const newRuler = this.createHorizontalRuler();

          this.currentHorizontalRuler = newRuler;
        } else {
          this.currentHorizontalRuler = event.target;
        }
      },
      rulerVerticalMoveDrag(event) {
        if (!this.rulerVerticalDrag) return;
        this.rulerVerticalPos = Math.max(this.rulerVerticalStartPos + event.clientY - this.rulerVerticalTop, 0);
        this.currentVerticalRuler.style.top = `${this.rulerVerticalPos}px`;
      },
      rulerHorizontalMoveDrag(event) {
        if (!this.rulerHorizontalDrag) return;
        this.rulerHorizontalPos = Math.max(this.rulerHorizontalStartPos + event.clientX - this.rulerHorizontalLeft, 0);
        this.currentHorizontalRuler.style.left = `${this.rulerHorizontalPos}px`;

        //Mirror line position
        const left = parseInt(this.currentHorizontalRuler.style.left);
        const zoomedLeft = left - this.staticLeft;

        const mainRuler = this.currentHorizontalRuler.mainRuler;

        if (mainRuler) {
          const mirrorLeft = left - document.querySelector('.compare__preview').getBoundingClientRect().left + document.querySelector('.my-frame__frame-wrap').getBoundingClientRect().left;
          mainRuler.style.left = `${mirrorLeft}px`;
        } else {
          const mirrorLeft = document.querySelector('.compare__preview').getBoundingClientRect().left - this.rulerHorizontalLeft + zoomedLeft;

          if (this.currentHorizontalRuler.mirrorRuler) {
            this.currentHorizontalRuler.mirrorRuler.style.left = `${mirrorLeft}px`;
          } else if (zoomedLeft < document.querySelector('.my-frame__frame-wrap').offsetWidth) {
            const mirrorRuler = this.createHorizontalRuler();
            mirrorRuler.style.left = `${mirrorLeft}px`;
            this.currentHorizontalRuler.mirrorRuler = mirrorRuler;
            mirrorRuler.mainRuler = this.currentHorizontalRuler;
          }
        }
      },
      rulerVerticalStopDrag() {
        if (!this.rulerVerticalDrag) return;
        this.rulerVerticalDrag = false;
        document.body.classList.remove('drag');
        if (!this.currentVerticalRuler) return;

        const currentZoom = parseInt(this.$store.state.zoom * 100);
        const top = parseInt(this.currentVerticalRuler.style.top);
        const zoomedTop = top - this.staticTop;
        this.currentVerticalRuler.maxLineTop = (zoomedTop * 100) / currentZoom;

        if (this.currentVerticalRuler.getBoundingClientRect().top - this.rulerVerticalTop <= this.rulerSize) {
          this.currentVerticalRuler.remove();
        }
      },
      rulerHorizontalStopDrag() {
        if (!this.rulerHorizontalDrag) return;
        this.rulerHorizontalDrag = false;
        document.body.classList.remove('drag');
        if (!this.currentHorizontalRuler) return;

        const currentZoom = parseInt(this.$store.state.zoom * 100);
        const left = parseInt(this.currentHorizontalRuler.style.left);
        const zoomedLeft = left - this.staticLeft;
        this.currentHorizontalRuler.maxLineLeft = (zoomedLeft * 100) / currentZoom;

        const mainRuler = this.currentHorizontalRuler.mainRuler;

        if (mainRuler) {
          const mirrorLeft = left - document.querySelector('.compare__preview').getBoundingClientRect().left + document.querySelector('.my-frame__frame-wrap').getBoundingClientRect().left;
          mainRuler.style.left = `${mirrorLeft}px`;
        } else {
          const mirrorLeft = document.querySelector('.compare__preview').getBoundingClientRect().left - this.rulerHorizontalLeft + zoomedLeft;

          if (this.currentHorizontalRuler.mirrorRuler) {
            this.currentHorizontalRuler.mirrorRuler.style.left = `${mirrorLeft}px`;
          } else if (zoomedLeft < document.querySelector('.my-frame__frame-wrap').offsetWidth) {
            const mirrorRuler = this.createHorizontalRuler();
            mirrorRuler.style.left = `${mirrorLeft}px`;
            this.currentHorizontalRuler.mirrorRuler = mirrorRuler;
            mirrorRuler.mainRuler = this.currentHorizontalRuler;
          }
        }

        if (this.currentHorizontalRuler.getBoundingClientRect().left - this.rulerHorizontalLeft <= this.rulerSize) {
          if (this.currentHorizontalRuler.mirrorRuler) this.currentHorizontalRuler.mirrorRuler.remove();
          else if (this.currentHorizontalRuler.mainRuler) this.currentHorizontalRuler.mainRuler.remove();
          this.currentHorizontalRuler.remove();
        }
      },
      rulerHorizontalMouseover(event) {
        if (document.body.classList.contains('drag')) return;

        setTimeout(() => {
          event.target.classList.add('hover');

          const mainRuler = event.target.mainRuler;
          if (mainRuler) {
            mainRuler.classList.add('hover');
          } else if (event.target.mirrorRuler) {
            event.target.mirrorRuler.classList.add('hover');
          }
        });
      },
      rulerHorizontalMouseleave(event) {
        if (document.body.classList.contains('drag')) return;

        // Array.from(document.querySelectorAll('.my-frames__ruler-line.horizontal'), line => {
        //   line.classList.remove('hover');
        // });
        setTimeout(() => {
          event.target.classList.remove('hover');

          const mainRuler = event.target.mainRuler;
          if (mainRuler) {
            mainRuler.classList.remove('hover');
          } else if (event.target.mirrorRuler) {
            event.target.mirrorRuler.classList.remove('hover');
          }
        });
      },

      redrawZoomVertical(line) {
        const currentZoom = parseInt(this.$store.state.zoom * 100);
        const top = parseInt(line.style.top);
        const zoomedTop = top - this.staticTop;
        if (zoomedTop > 0) {
          const newZoomedTop = (currentZoom * line.maxLineTop) / 100;
          const newZoom = this.staticTop + newZoomedTop;
          line.style.top = `${newZoom}px`;
        }
      },
      redrawZoomHorizontal(line) {
        const currentZoom = parseInt(this.$store.state.zoom * 100);
        const left = parseInt(line.style.left);
        const zoomedLeft = left - this.staticLeft;
        if (zoomedLeft > 0) {
          const newZoomedLeft = (currentZoom * line.maxLineLeft) / 100;
          const newZoom = this.staticLeft + newZoomedLeft;
          line.style.left = `${newZoom}px`;
        }
      }
    }
  }
</script>
<style lang="scss">
  .el-loading-mask{
    z-index: 110;
  }

  body.drag{
    user-select: none;
    -webkit-user-select: none;
    .my-frame__frame-wrap{
      &::after{
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .el-dropdown-menu {
    overflow: hidden;

    > .el-button {
      margin-left: rem-calc(10);
    }
  }
  .el-dropdown-menu__item {
    position: relative;
    padding-right: rem-calc(40);

    .el-icon-close {
      @include vertical-center();
      right: rem-calc(10);
    }
  }

  .input-group-button {
    .button,
    .button-group {
      height: 100%;
    }
  }

  .my-frames {
    display: flex;
    width: 100%;

    &__title{
      margin: 20px 0 0;
      min-width: 100%;
    }

    /*.navigation__ul{
      order: 1;
    }
    .navigation__ul_lang{
      order: 0;
      margin-left: 0;
    }*/

    .navigation__li{
      .el-dropdown{
        top: -1px;
      }
    }

    &__toolbar {
      max-width: 200px;
      min-width: 200px;
    }

    &__container {
      position: relative;
      padding: rem-calc(15 15 0);
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      min-width: 1100px;
    }

    &__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-width: 100%;
      //max-height: 113px;
      padding-bottom: rem-calc(30);
      border-bottom: 1px solid #e7f1f1;
    }

    &__filter {
      padding-top: rem-calc(30);
      min-width: 100%;
      //max-height: 57px;

      .el-checkbox-group {
        display: inline-block;
      }

      .el-button {
        margin-left: rem-calc(15);
      }
    }

    &__nav {
      flex: 1 0 100%;
      width: 100%;
      .navigation{
        max-width: 700px; //585px;
        //&__ul_lang{
        //  margin-left: rem-calc(45);
        //}
      }
      + .my-frames__bottom{
        margin-top: 1rem;
      }
    }

    &__bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 100%;
    }

    &__href-input {
      max-width: 700px; //585px;
      flex: 1 1 645px; //585px;
      margin-right: rem-calc(30);
    }

    &__frames-wrap {
      padding-top: rem-calc(30);
      margin: rem-calc(0 -10);
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      min-width: 100%;
      min-height: calc(100vh - 329px);
      overflow: hidden;
      // justify-content: space-between;
      &.compare-uploaded{
        flex-grow: 0;
        flex-wrap: nowrap;
        margin: 0;
        padding-top: 30px + 22;
        padding-left: 15 + 22px;
        border-left: 1px solid #e7f1f1;
        .my-frame{
          min-width: 225px; //(300px / 100 * 30);
        }
      }
    }

    &__rulers{
      &.hover{
        .my-frames__ruler {
          background: rgba(#000, .05);
        }
      }
    }
    &__ruler{
      position: absolute;
      top: 0;
      left: 0;
      transition: background .25s ease-in-out;
      &_vertical{
        width: 100%;
        height: 22px;
        cursor: ns-resize;
      }
      &_horizontal{
        top: 22px;
        height: calc(100% - 22px);
        width: 22px;
        cursor: ew-resize;
      }
      &-line{
        position: absolute;
        z-index: 1;
        transition: border-color .25s ease-in-out;
        &::before{
          content: '';
          display: block;
          position: absolute;
        }
        &.vertical{
          top: 0;
          left: 0;
          width: 100%;
          border-top: 1px solid #4affff;
          cursor: ns-resize;
          &::before{
            top: -10px;
            left: 0;
            height: 21px;
            width: 100%;
          }
          @at-root body{
            &:not(.drag) {
              .my-frames__ruler-line.vertical {
                &:hover {
                  border-top-color: $color-primary;
                }
              }
            }
          }
        }
        &.horizontal{
          top: 0;
          left: 0;
          height: 100%;
          border-left: 1px solid #4affff;
          cursor: ew-resize;
          &::before{
            top: 0;
            left: -10px;
            height: 100%;
            width: 21px;
          }
          @at-root body{
            &:not(.drag){
              .my-frames__ruler-line.horizontal {
                &:hover {
                  border-left-color: $color-primary;
                }
              }
            }
          }
          &.hover{
            border-left-color: $color-primary;
          }
        }
      }
      &-button{
        position: absolute;
        border: 1px solid #e7f1f1;
        border-top: none;
        color: rgba(#7c8989, .7);
        text-align: center;
        width: 176px;
        z-index: 1;
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        &_vertical{
          top: 0;
          left: 250px;
          //transform: translateX(-50%);
        }
        &_horizontal{
          top: 250px;
          left: -(176px / 2) + 8;
          transform: translateY(-50%) rotate(-90deg);
        }
      }
      &-text{
        text-transform: lowercase;
        white-space: nowrap;
        font-size: 11px;
      }
      &-icon{
        margin-left: 5px;
        &::after{
          content: '';
          position: relative;
          display: block;
          border-width: 4px 4px 0 4px;
          border-style: solid;
          border-color: transparent;
          border-top-color: currentColor;
        }
      }
    }

    &__frame {
      margin: rem-calc(0 10 25);
      flex: 0 1 0;
    }

    &__share {
      margin: auto -15px 0;
      min-width: 100%;
    }

    &__validations {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      //margin-top: 1rem;
    }

    &__validation {
      &:not(:first-child) {
        margin-left: rem-calc(50);
      }
    }

    &__compare{
      flex: 1 0 auto;
      min-height: 100%;
      padding-right: .625rem * 2;
    }

    @include breakpoint(xxlarge) {
      &__container {
        padding-left: rem-calc(30);
        padding-right: rem-calc(30);
      }
      &__share {
        margin: auto -30px 0;
      }
    }
  }
</style>
